import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
//import Homepage from '../Components/Homepage'
import DefaultLayout from '../Layout/DefaultLayout/DefaultLayout'
import PageNotFound from '../Components/Errors/PageNotFound'
import NavigationGuard from './NavigationGuard'
import AccountStatement from '../Components/MyAccount/AccountStatement'
import Payment from '../Components/Payment/Payment'
import SearchResultMulticity from '../Components/SearchResultMulticity'
import Aa from '../Components/Aa'
import SearchResultOnewayTrip from '../Components/SearchResults/SearchResultOnewayTrip'
import SearchResultIndividualRoundTrip from '../Components/SearchResults/SearchResultIndividualRoundTrip'
import SearchResultCOmbainedRoundTrip from '../Components/SearchResults/SearchResultCombainedRoundTrip'

import Cc from '../Components/Cc'
import UserDetails from '../Components/Booking/UserDetails'
import MyTrips from '../Components/MyAccount/MyTrips'
import TravelCalender from '../Components/MyAccount/TravelCalender'
import  Profile  from '../Components/MyAccount/Profile'
import HomePageNew from '../Components/HomePage/HomePageNew'
import BookingSuccess from '../Components/Booking/BookingSuccess'
import SomethingWrong from '../Components/Errors/SomethingWrong'
import Delete from '../Components/MyAccount/Delete'
import InitiateBooking from '../Components/Payment/InitiateBooking'
import ComingSoon from '../Components/HomePage/ComingSoon'
import Hotel from '../Components/HomePage/Hotel'
import Visa from '../Components/HomePage/Visa'
import VisaDetails from '../Components/HomePage/VisaDetails'
import Forex from '../Components/HomePage/Forex'
import Insurance from '../Components/HomePage/Insurance'
import Umrah from '../Components/HomePage/Umrah'
import UmrahItinerary from '../Components/HomePage/UmrahItinerary'
import PrivacyPolicy from '../Components/PrivacyPolicy'
import Disclaimer from '../Components/Disclaimer'
import TermsofUse from '../Components/TermsofUse'
function Routers() {

  return (
    <div>
      <Routes>
    
        <Route path="/" element={<DefaultLayout />}>
     
          {/* <Route path='/' Component={Login} /> */}
          {/* <Route path='/' Component={HomePage} /> */}
          <Route path='/' Component={HomePageNew} />



          {/* <Route element={<NavigationGuard />}> */}

            <Route path='/Aa' Component={Aa} />

            <Route path='/Cc' Component={Cc} />
            <Route path='/review' Component={UserDetails} />
            <Route path='/issue' Component={SomethingWrong} />

            {/* <Route path='/Oneway' Component={SearchResultOneway} /> */}
            <Route path='/Oneway' Component={SearchResultOnewayTrip} />
            
            {/* <Route path='/Roundtrip' Component={SearchResultRoundtrip} /> */}
            <Route path='/Roundtrip' Component={SearchResultIndividualRoundTrip} />
            <Route path='/Multicity' Component={SearchResultMulticity} />
            {/* <Route path='/InternationalRoundTrip' Component={SearchResultRoundtripInternational} /> */}
            <Route path='/InternationalRoundTrip' Component={SearchResultCOmbainedRoundTrip} />
            <Route path='/ComingSoon' Component={ComingSoon} />
            <Route path='/Privacy_Policy' Component={PrivacyPolicy} />
            <Route path='/Disclaimer' Component={Disclaimer} />
            <Route path='/TermsofUse' Component={TermsofUse} />
         {/* </Route> */}
          {/* <Route path='/AccountStatement' Component={AccountStatement}/> */}
          {/* <Route path='/MyTrips' Component={MyTrips}/> */}
          {/* <Route path='/TravelCalender' Component={TravelCalender}/> */}
          {/* <Route path='/Profile' Component={Profile}/> */}
          {/* <Route path='/Payment' Component={Payment}/> */}
          <Route path='/checkout' Component={UserDetails} />
          {/* <Route path='/BookingConfirm' Component={BookingSuccess} /> */}
          {/* <Route path='/newHOme' Component={HomePage} /> */}
          <Route path='/InitiateBooking' Component={InitiateBooking} />
          <Route path='/Delete' Component={Delete}/>
          <Route path='/Hotel' Component={Hotel} />
          <Route path='/Visa' Component={Visa} />
          <Route path='/VisaDetails/:id' Component={VisaDetails} />
          <Route path='/Forex' Component={Forex} />
          <Route path='/Insurance' Component={Insurance} />
          <Route path='/Umrah' Component={Umrah} />
          {/* <Route path='/UmrahItinerary' component={UmrahItinerary} /> */}
          <Route path='/UmrahItinerary/:id/:deptId' Component={UmrahItinerary} />
          {/* Navigation Gaurd */}
          <Route element={<NavigationGuard />}>
                  <Route path='/AccountStatement' Component={AccountStatement}/>
                  <Route path='/MyTrips' Component={MyTrips}/>
                  <Route path='/TravelCalender' Component={TravelCalender}/>
                  <Route path='/Profile' Component={Profile}/>
                  <Route path='/Payment' Component={Payment}/>
                  <Route path='/BookingConfirm' Component={BookingSuccess} />
                  {/* <Route path='/Delete' Component={Delete}/> */}
                  
          </Route>

          {/* End Navigation Gaurd */}

          </Route>
        <Route path='*' Component={PageNotFound} />
      </Routes>

    </div>
  )
}

export default Routers

