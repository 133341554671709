import React, { Component } from 'react'
import { useNavigate, Link, json } from "react-router-dom";
class TermsofUse extends Component {
  render() {
    return (
      <div>
       <div className="container" style={{ paddingTop: '25px' }}>
        <h2>Terms of Use</h2>
        <div class="privacy-inner">
                                    <p>Web site usage terms and conditions for alhind.com. Agreement between customer and alhind.com </p>

                                    <p>
                                        Welcome to www.ALHIND.com. This Website is provided solely to assist customers in gathering travel information, determining the availability of travel-related goods and services, making legitimate reservations or otherwise transacting business with travel suppliers, and for no other purposes. The terms we use our and ALHIND refer ALHIND.com, a travel portal promoted by Alhind Tours and Travels, Markas Complex, Mavoor Road, Calicut. Pin: 673 004 and our subsidiaries. The term you refers to the customer visiting the Website and/or booking a reservation through us on this Website, or through our customer service agents. This Website is offered to you conditioned upon your acceptance without modification of all the terms, conditions, and notices set forth below (collectively, the Agreement). By accessing or using this Website in any manner, you agree to be bound by the Agreement. Please read the Agreement carefully. If you do not accept all of these terms and conditions, please do not use this Website. It is your responsibility to monitor terms and conditions page of ALHIND.COM regularly to determine whether the terms and conditions have been modified. We reserve the right at any time, at our sole discretion, to change or otherwise modify the Agreement without prior notice, and your continued access or use of this Website signifies your acceptance of the updated or modified Agreement.
                                    </p>

                                    <div className="privacy-inner-title"> <h4> USE OF THE WEBSITE </h4> </div>

                                    <p>As a condition of your use of this Website, you warrant that </p>
                                        <ul>
                                       <li> You are at least 18 years of age and are of sound mind </li>
                                    <li>You possess the legal authority to create a binding legal obligation</li>
                                    <li>You will use this Website in accordance with this Agreement</li>
                                  <li> You will only use this Website to make legitimate reservations for you or for another person for whom you are legally authorized to act</li>
                                   <li>You will inform such other persons about the terms and conditions that apply to the reservations you have made on their behalf, including all rules and restrictions applicable thereto</li> 
                                    <li>If you have an ALHIND.COM account, you will safeguard your account information and will supervise and be completely responsible for any use of your account by you and anyone other than you. We retain the right at our sole discretion to deny access to anyone to this Website and the services we offer, at any time without notice and for any reason, including, but not limited to, for violation of this Agreement. </li>
                                        </ul>
                                   

                                    <div className="privacy-inner-title"> <h4> PROHIBITED ACTIVITIES </h4> </div>

                                    <p>
                                        The content and information on this Website (including, but not limited to, price and availability of travel services), as well as the infrastructure used to provide such content and information, is proprietary to us or our suppliers and providers. While you may make limited copies of your travel itinerary (and related documents) for travel or services booked through this Website, you agree not to otherwise modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information, software, products, or services obtained from or through this Website. Additionally, you agree not to
                                    </p>
                                    <ul>
                                        <li>Use this Website or its contents for any commercial purpose </li>
                                        <li>Make any speculative, false, or fraudulent reservation or any reservation in anticipation of demand </li>
                                   <li>Violate the restrictions in any robot exclusion headers on this Website or bypass or circumvent other measures employed to prevent or limit access to this Website</li>
                                   <li>Take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure </li>
                                   <li>Deep-link to any portion of this Website (including, without limitation, the purchase path for any travel services) for any purpose without our express written permission; or frame, mirror or otherwise incorporate any part of this Website into any other website without our prior written authorization.</li>
                                    <li>If you have an ALHIND.COM account, you will safeguard your account information and will supervise and be completely responsible for any use of your account by you and anyone other than you. We retain the right at our sole discretion to deny access to anyone to this Website and the services we offer, at any time without notice and for any reason, including, but not limited to, for violation of this Agreement.</li>
                                    </ul>
                                    
                                    
                                    
                                    
                                    
                                 
                               

                                    <div className="privacy-inner-title"> <h4> SUPPLIER RULES AND RESTRICTIONS </h4> </div>

                                    <p>
                                        Additional terms and conditions will apply to your reservation and purchase of travel-related goods and services that you select. Please read these additional terms and conditions carefully. You agree to abide by the terms and conditions of purchase imposed by any supplier with whom you elect to deal, including, but not limited to, payment of all amounts when due and compliance with the suppliers rules and restrictions regarding availability and use of fares, products, or services. You acknowledge that some third-party providers offering certain services and/or activities may require you to sign their liability waiver prior to participating in the service and/or activity they offer. You understand that any violation of any such suppliers rules and restrictions may result in cancellation of your reservation(s), in your being denied access to the applicable travel product or services, in your forfeiting any monies paid for such reservation(s), and/or in our debiting your account for any costs we incur as a result of such violation.
                                    </p>

                                    <div className="privacy-inner-title"> <h4> REVIEWS, COMMENTS AND OTHER SUBMISSIONS </h4> </div>

                                    <p>
                                        We appreciate hearing from you. Please be aware that by submitting content to this Website by electronic mail, postings on this Website or otherwise, including any hotel reviews, questions, comments, suggestions, ideas or the like contained in any submissions (collectively, Submissions), you grant ALHIND.COM, its subsidiaries and corporate affiliates (collectively, the ALHIND.COM Companies) and the affiliated, co-branded and/or linked website partners through whom we provide service ALHIND.COM Affiliates a nonexclusive, royalty-free, perpetual, transferable, irrevocable and fully sublicensable right to (a) use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and publicly display and perform such Submissions throughout the world in any media, now known or hereafter devised; and (b) use the name that you submit in connection with such Submission. You acknowledge that ALHIND.COM may choose to provide attribution of your comments or reviews (for example, listing your name and hometown on a hotel review that you submit) at our discretion, and that such submissions may be shared with our supplier partners. You further grant ALHIND.COM the right to pursue at law any person or entity that violates your or ALHIND.COMs rights in the Submissions by a breach of this Agreement. You acknowledge and agree that Submissions are non-confidential and non-proprietary. We take no responsibility and assume no liability for any Submissions posted or submitted by you. We have no obligation to post your comments; we reserve the right in our absolute discretion to determine which comments are published. If you do not agree to these terms and conditions, please do not provide us with any Submissions.
                                    </p>

                                    <p>
                                        You are fully responsible for the content of your Submissions, (specifically including, but not limited to, reviews posted to this Website). You are prohibited from posting or transmitting to or from this Website:
                                    </p>
<ul>
    <li>Any commercial material or content (including, but not limited to, solicitation of funds, advertising, or marketing of any good or services</li>





                                   

                                <li>Any material or content that may bring disrepute to the Website and harm its business and/or credibility and goodwill; and </li>

                                <li>Any material or content that infringes, misappropriates or violates any copyright, trademark, patent right or other proprietary or contractual right of any third party; and </li>

                                <li>That may hinder services on the Website in any way. You shall be solely liable for any damages resulting from any violation of the foregoing restrictions, or any other harm resulting from your posting of content to this Website. You acknowledge that ALHIND.COM, the ALHIND.COM Companies and the ALHIND.COM Affiliates may exercise their rights (e.g. use, publish, delete) to any content you submit without notice to you.</li>

</ul>





                                    <p>
                                        ALHIND.COMs policies with respect to claims by third parties that the content of the Website, including the content of any Submissions, infringes the copyrights owned by said third party can be found at Copyright Complaint Policy
                                    </p>

                                    <div className="privacy-inner-title"> <h4> PREPAID HOTEL RESERVATIONS </h4> </div>

                                    <p>
                                        You agree that your credit card will be charged by ALHIND.COM for the total reservation price, which includes the room rate displayed on the Website, plus tax recovery charges, and service fees. You may incur additional transaction fees levied by your bank as our payment processor is considered a foreign entity. Please contact your bank for more information on the cross-border fee.
                                    </p>

                                    <p>
                                        ou acknowledge that ALHIND.COM does not collect taxes for remittance to applicable taxing authorities. The tax recovery charge on hotel accommodations is a recovery of the estimated transaction taxes (e.g. sales and use, occupancy, room tax, excise tax, value added tax, etc) that ALHIND.COM pays to the hotel supplier in connection with your hotel reservations. The hotel suppliers invoice ALHIND.COM for tax amounts. The Hotel suppliers remit applicable taxes to the applicable taxing jurisdictions. ALHIND.COM does not act as a co-vendor with the supplier with whom we book or reserve our customers travel arrangements. Taxability and the appropriate tax rate vary greatly by location. The actual tax amounts paid by ALHIND.COM to the hotel suppliers may vary from the tax recovery charge amounts, depending upon the rates, taxability, etc. in effect at the time of the actual use of the hotel by our customers. In certain circumstances, you may be required to self-access service tax on the services you received from ALHIND.COM.
                                    </p>

                                    <p>
                                        The service fees compensate ALHIND.COM for its costs in servicing your travel reservation. Our service fees vary based on the amount and type of hotel reservation.
                                    </p>

                                    <p>
                                        You may cancel or change your prepaid hotel reservation, but you will be charged the cancellation or change fee indicated in the rules and restrictions for the hotel reservation. In addition, if you do not cancel or change your reservation before the cancellation policy period applicable to the hotel you reserved, which varies by hotel (usually 24 to 72 hours) prior to your date of arrival), you will be subject to a charge of applicable nightly rates, tax recovery charges and service fees. In the event you do not show for the first night of the reservation and plan to check-in for subsequent nights in your reservation, you must confirm the reservation changes with ALHIND.COM or its subsidiary, as appropriate, no later than the date of the first night of the reservation to prevent cancellation of your reservation. You must make refund requests for no-shows or early checkouts within 60 days after checkout.
                                    </p>

                                    <p>
                                        You agree to pay any cancellation or change fees that you incur. In limited cases, some hotels do not permit changes to or cancellations of reservations after they are made, as indicated in the rules and restrictions for the hotel reservation. You agree to abide by the terms and conditions imposed with respect to such hotel accommodations.
                                    </p>

                                    <div className="privacy-inner-title"> <h4> TRAVEL DESTINATIONS </h4> </div>

                                    <p>
                                        Although most travel, including travel to international destinations, is completed without incident, travel to certain destinations may involve greater risk than others. ALHIND.COM urges passengers to review travel prohibitions, warnings, announcements and advisories issued by the appropriate authorities in India prior to booking travel to international destinations. Information on conditions in various countries and the level of risk associated with travel to particular international destinations can be found at http://meaindia.nic.in/.
                                    </p>

                                    <p>
                                        You are responsible for checking all the items relevant to passport, visa and Health Information ensuring your travel documents are in order.
                                    </p>

                                    <p>
                                        Passport and Visa: You must consult the relevant Embassy or Consulate for this information. Requirements may change and you should check for up-to-date position in good time before booking/departure. We accept no liability if you are refused entry onto the flight or into any country due to failure on your part to carry the correct passport, visa or other documents required by any airline, authority or country. You must have a passport which is valid for six (6) months after your intended date of return. You must ensure you have correct visa and health entry requirements for all countries visited including countries you may just be transiting through. This includes all stops made by the aircraft even if you do not leave the aircraft or airport.
                                    </p>

                                    <p>
                                        Health: Recommended inoculations for travel may change at any time and you should consult your doctor on current recommendations before you depart. It is your responsibility to ensure that you obtain the recommended inoculations, take all recommended medication and follow all medical advice in relation to your trip.
                                    </p>

                                    <p>
                                        By offering for sale travel to particular international destinations, alhind.com does not represent or warrant that travel to such points is advisable or without risk, and is not liable for damages or losses that may result from travel to such destinations.
                                    </p>

                                    <div className="privacy-inner-title"> <h4> LIMITATION OF LIABILITY AND DISCLAIMER </h4> </div>

                                    <p>
                                        The information, software, products, and services published on this website may include inaccuracies or errors, including pricing errors. In particular, the alhind.com companies and the alhind.com affiliates do not guarantee the accuracy of, and disclaim all liability for any errors or other inaccuracies relating to the information and description of the hotel, air, cruise, car and other travel products displayed on this website (including, without limitation, the pricing, photographs, list of hotel amenities, general product descriptions, etc.), much of which information is provided by the respective suppliers. In addition, alhind.com expressly reserves the right to correct any pricing errors on our website and/or on pending reservations made under an incorrect price. In such event, if available, we will offer you the opportunity to keep your pending reservation at the correct price or we will cancel your reservation without penalty.
                                    </p>

                                </div>
      </div>
      
      </div>
    )
  }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <TermsofUse {...props} navigate={navigate} />;
  }
  
  export default WithNavigate;
