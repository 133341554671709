// import AES from 'crypto-js/aes';
// import encUtf8 from 'crypto-js/enc-utf8';
// import encBase64 from 'crypto-js/enc-base64';

export function URL_EncodeKey()
{ 
    return "00112233AABBCCDD";
}

// export function API_Encryption(data)
// { 
//   const secretKey = URL_EncodeKey(); // Secret key used for encryption
//   console.log(secretKey);
//   const jsonData = JSON.stringify(data);
//   console.log(jsonData);

//   // Encrypt the data using the secret key and IV
//   const encryptedData = AES.encrypt(jsonData, secretKey).toString();
//   console.log(encryptedData);

//   // Return an object containing the encrypted data and IV
//   return { encryptedData};
// }