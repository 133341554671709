import AxiosConfig from '../Configuration/AxiosConfig'
import HeaderConfig from '../Configuration/HeaderConfig';
import { CheckInternetConnection } from '../Services/UtilitiesServices';
import { API_Encryption } from './EncryptionService';

export function GetAirlineTicketHistory(data)
{ 
    return AxiosConfig.post("Airline/GetAirlineTicketHistory",data, HeaderConfig())

}

export function GetAirlineBanners(data)
{ 
    return AxiosConfig.post("Airline/GetAirlineBanners",data, HeaderConfig())
}

export function GetFlightDeals(data)
{ 
    return AxiosConfig.post("Airline/GetFlightDeals",data, HeaderConfig())
}

export function EmailTicket(data)
{ 
    return AxiosConfig.post("Update/mailticket",data, HeaderConfig())
    //return AxiosConfig.post("Update/mailticket",API_Encryption(data), HeaderConfig())

}









