import React, { useState } from 'react'
import { useNavigate, Link, json } from 'react-router-dom'
import dateFormat, { masks } from "dateformat";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import AirportJson from '../appData/airportlist.json'

const options = {

  nav: false,

};

function Cc() {
  

  const searchSectorFields = { origin: "", destination: "", departureDate: "", origincountry: "", destinationcountry: "", Tripmode: "" }
  const requestBodyFields = { adult: 1, child: 0, infant: 0, airlineClass: "IN", prefferedCarriers: "AE", prefferedProviders: "AE", fareType: "AE", Isdirect: false, traveltype: "AE", objsectorlist: [] }

  const navigate = useNavigate();
  const [requestBody, setRequestBody] = useState(requestBodyFields);
  const [searchSector, setsearchSector] = useState(searchSectorFields);


  /////Origin Destination To searchSector Obj///////
  const inputSearchSectorChangeHandler = (event) => {
    const { name, value } = event.target
    setsearchSector({ ...searchSector, [name]: value })
  }


  /////Date to searchSector Obj///////
  const inputDateChangeHandler = (event) => {
    const { name, value } = event.target;
    if (event.target.value == "Choose Date")
      return;

    setsearchSector({ ...searchSector, [name]: dateFormat(value, "yyyy-mm-dd") });
  }

  /////SearchResult Request body///////
  const inputOnewaySearchRequestChangeHandler = (event) => {
    const { name, value } = event.target;
    if (event.target.name == "adult" || event.target.name == "child" || event.target.name == "infant") {
      setRequestBody({ ...requestBody, [name]: Number(value) });
      return;
    }
    setRequestBody({ ...requestBody, [name]: value });

  }

  const inputSearchSectorChangeFocusOut=(event, type) => {
    if (type=="source") {
      Object.keys(AirportJson).forEach(function(key) {
        if(AirportJson[key].Code == event.target.value)
        {
          console.log(AirportJson[key]);
          setsearchSector({ ...searchSector, origin: AirportJson[key].CityCode,  origincountry:AirportJson[key].CountryCode})
         
        }        
      });
    }
    else
    {
      Object.keys(AirportJson).forEach(function(key) {
        if(AirportJson[key].Code == event.target.value)
        {
          console.log(AirportJson[key]);
          setsearchSector({ ...searchSector, destination: AirportJson[key].Code,  destinationcountry:AirportJson[key].CountryCode})
          
        }        
      });
      
    }
    console.log(searchSector);
  }


  function SearchFlights(tripMode) {
    searchSector.Tripmode = tripMode
    setsearchSector({ ...searchSector, Tripmode: tripMode })

    requestBody.objsectorlist.push(searchSector);
    localStorage.setItem("onewaySearchResult", JSON.stringify(requestBody));
    console.log(JSON.parse(localStorage.getItem("onewaySearchResult")));
    setRequestBody(JSON.parse(localStorage.getItem("onewaySearchResult")));
    setsearchSector(requestBody.objsectorlist);
    console.log(requestBody);
    
 

    if (tripMode == "O")
      //navigate('/Aa');
      navigate('/Oneway');
    else if (tripMode == "R")
    {
      if(searchSector.origincountry=="IN" && searchSector.destinationcountry=="IN")
        navigate('/Roundtrip'); //IndividualRoundTrip
      else
        navigate('/InternationalRoundTrip');  //Combained RoundTrip
    }      
    else if (tripMode == "S")
      navigate('/International');
    else if (tripMode == "M")
      navigate('/Multicity');
  }

  function Roundtrip() {
    navigate('/Roundtrip')
  }
  return (
    <div>


      <section className="main-banner map-area" id="map-area" style={{background: 'url(https://wallpapers.com/images/hd/flight-zgveis6o1hmvkhcy.jpg)'}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="container pt-4">
                <div className="col-lg-6 col-md-6">  <h1>It’s Time to Fly again!</h1></div>
                <div className="col-lg-6 col-md-6"><p>Hundreds of airlines. Millions of routes.<br />
                  The best deals anywhere!</p></div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <form className="booking-form" id="booking-form" action>
                      {/* Pills navs */}
                      <ul className="nav nav-pills mb-3" id="ex1" role="tablist">
                        <li className="nav-item" role="presentation">

                          <a className="nav-link active" id="ex1-tab-1" data-bs-toggle="pill" href="#ex1-pills-1" role="tab" aria-controls="ex1-pills-1" aria-selected="true" >One Way</a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a className="nav-link" id="ex1-tab-2" data-bs-toggle="pill" role="tab" href="#ex1-pills-2" aria-controls="ex1-pills-2" aria-selected="false"  >Round Trip</a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a className="nav-link" id="ex1-tab-3" data-bs-toggle="pill" href="#ex1-pills-3" role="tab" aria-controls="ex1-pills-3" aria-selected="false">Multi City</a>
                        </li>
                      </ul>
                      {/* Pills navs */}
                      {/* Pills content */}
                      <div className="tab-content" id="ex1-content">
                        <div className="tab-pane fade show active" id="ex1-pills-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                          <div className="booking-content">
                            <div className="row">
                              <div className="col-md-3 col-6">
                                <p className="b-sub-txt">Depart from</p>
                      
                                <div className="origin-form"><div className="origin"><input type="text" defaultValue="Dubai" className="form-control" name='origin' onBlur={(e) => inputSearchSectorChangeFocusOut(e, "source")}/></div></div>
                               
                              
                              </div>
                              <div className="col-md-3 col-6">
    <p className="b-sub-txt">Going to</p>
   
    <div className="origin-form"><div className="origin"><input type="text" defaultValue="CCJ" className="form-control" name='destination' onBlur={(e) => inputSearchSectorChangeFocusOut(e, "destination")} /></div></div>
   
</div>
                            
                              <div className="col-md-5 col-12">
                                <div className="row">
                                  
                                  <div className="col-md-5 col-lg-5 col-6">
                                    <p className="b-sub-txt">Departure Date</p>
                                   
                                    <div className="origin-form"><input type="text" defaultValue="Choose Date" className="form-control" id="input_from" name='departureDate' onSelect={(e) => inputDateChangeHandler(e)} /></div>
                                  </div>
                                  <div className="col-md-5 col-lg-5 col-6">
                                    <p className="b-sub-txt">Return Date</p>
                                   
                                    <div className="origin-form"><input type="text" defaultValue="Choose Date" className="form-control" id="input_to" name='arrivalDate' onSelect={(e) => inputDateChangeHandler(e)} /></div>
                                  </div>
                                  <div><p className="b-sub-txt-2">Traveller(s)</p></div>
                                  
                                  <div className="col-lg-4 col-md-4 col-4">
            <label className="small-label">Adult (12+)</label>
            <select className="form-control t-select" id="Adult" name="adult" onChange={(e) => inputOnewaySearchRequestChangeHandler(e)} fdprocessedid="jtwsm"><option value={1}>01</option>
                <option value={2}>02</option>
                <option value={3}>03</option>
                <option value={4}>04</option>
                <option value={5}>05</option>
                <option value={6}>06</option>
                <option value={7}>07</option>
                <option value={8}>08</option>
                <option value={9}>09</option>
            </select>
        </div>
        <div className="col-lg-4 col-md-4 col-4">
            <label className="small-label">Child (2-11)</label>
            <select className="form-control t-select" id="Child" name="child" onChange={(e) => inputOnewaySearchRequestChangeHandler(e)} fdprocessedid="pxdj69"><option selected="selected" value={0}>00</option>
                <option value={1}>01</option>
                <option value={2}>02</option>
                <option value={3}>03</option>
                <option value={4}>04</option>
                <option value={5}>05</option>
                <option value={6}>06</option>
                <option value={7}>07</option>
                <option value={8}>08</option>
            </select>
        </div>
        <div className="col-lg-4 col-md-4 col-4 mb-3">
            <label className="small-label" style={{ width: '100%' }}>Infant (0-2)</label>
            <select className="form-control t-select" id="Infant" name="infant" onChange={(e) => inputOnewaySearchRequestChangeHandler(e)} fdprocessedid="pxdj69"><option selected="selected" value={0}>00</option>
                <option value={1}>01</option>
                <option value={2}>02</option>
                <option value={3}>03</option>
                <option value={4}>04</option>
                <option value={5}>05</option>
                <option value={6}>06</option>
                <option value={7}>07</option>
                <option value={8}>08</option>
            </select>
        </div>

        <div className="col-lg-12 col-md-12 col-12 mb-3">
        <p className="b-sub-txt-2">Select Class</p>
       
        <select className="form-control t-select" name="airlineClass" id="airline_class" onChange={(e) => inputOnewaySearchRequestChangeHandler(e)} fdprocessedid="92g40h">
            <option value="Economy" selected>Economy</option>
            <option value="StandardEconomy">Standard Economy</option>
            <option value="PremiumEconomy">Premium Economy</option>
            <option value="Business">Business</option>
            <option value="PremiumBusiness">Premium Business</option>
            <option value="First">First</option>
            <option value="PremiumFirst">Premium First</option>
        </select>
    </div>

                                </div>
                                <div className="row">
                               
                                  <div className="col-lg-10 col-md-10">
                                  <p className="b-sub-txt">Fare Type</p>
                                    <div className="fare-form mb-3">
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="defence" id="defence" />
                                        <label className="form-check-label" htmlFor="defence">
                                          Defence Forces
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="students" id="students" />
                                        <label className="form-check-label" htmlFor="students">
                                          Students
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="senior" id="senior" />
                                        <label className="form-check-label" htmlFor="senior">
                                          Students
                                        </label>
                                      </div>
                                    </div>
                                    {/*Search Button*/}
                                    <button type="button" className="btn btn-primary f-search-btn" onClick={() => SearchFlights('O')}>Search Flights</button>
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="ex1-pills-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                          <div className="booking-content">
                            <div className="row">
                              <div className="col-md-3">
                                <p className="b-sub-txt">Depart from</p>
                                <small>Origin</small>
                                <div className="origin-form"><input type="text" defaultValue="Dubai" className="form-control" name='origin'  onBlur={(e) => inputSearchSectorChangeFocusOut(e, "source")} /></div>
                                <p className="b-sub-txt-2">Traveller(s)</p>
                                <div className="row">
                                  <div className="col-lg-4 col-md-4 col-4">
                                    <label className="small-label">Adult (12+)</label>
                                    <select className="form-control t-select" id="Adult" name="adult" onChange={(e) => inputOnewaySearchRequestChangeHandler(e)} fdprocessedid="jtwsm"><option value={1}>01</option>
                                      <option value={2}>02</option>
                                      <option value={3}>03</option>
                                      <option value={4}>04</option>
                                      <option value={5}>05</option>
                                      <option value={6}>06</option>
                                      <option value={7}>07</option>
                                      <option value={8}>08</option>
                                      <option value={9}>09</option>
                                    </select>
                                  </div>
                                  <div className="col-lg-4 col-md-4 col-4">
                                    <label className="small-label">Child (2-11)</label>
                                    <select className="form-control t-select" id="Child" name="child" onChange={(e) => inputOnewaySearchRequestChangeHandler(e)} fdprocessedid="pxdj69"><option selected="selected" value={0}>00</option>
                                      <option value={1}>01</option>
                                      <option value={2}>02</option>
                                      <option value={3}>03</option>
                                      <option value={4}>04</option>
                                      <option value={5}>05</option>
                                      <option value={6}>06</option>
                                      <option value={7}>07</option>
                                      <option value={8}>08</option>
                                    </select>
                                  </div>
                                  <div className="col-lg-4 col-md-4 col-4">
                                    <label className="small-label" style={{ width: '100%' }}>Infant (0-2)</label>
                                    <select className="form-control t-select" id="Child" name="infant" onChange={(e) => inputOnewaySearchRequestChangeHandler(e)} fdprocessedid="pxdj69"><option selected="selected" value={0}>00</option>
                                      <option value={1}>01</option>
                                      <option value={2}>02</option>
                                      <option value={3}>03</option>
                                      <option value={4}>04</option>
                                      <option value={5}>05</option>
                                      <option value={6}>06</option>
                                      <option value={7}>07</option>
                                      <option value={8}>08</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1 pt-5 text-center">
                                <div className="swap-image">
                                  <img src="assets/img/swap.webp" width={35} alt="" />
                                </div>
                              </div> <div className="col-md-3">
                                <p className="b-sub-txt">Going to</p>
                                <small>Destination</small>
                                <div className="origin-form"><input type="text" defaultValue="CCJ" className="form-control" name='destination'  onBlur={(e) => inputSearchSectorChangeFocusOut(e, "destination")} /></div>
                                <div className="col-lg-12 col-md-12 col-12">
                                  <p className="b-sub-txt-2">Class</p>
                                  <label className="small-label" style={{ width: '100%' }}>Select Class</label>
                                  <select className="form-control t-select" name="AirlineClass" id="airline_class" fdprocessedid="92g40h" onChange={(e) => inputOnewaySearchRequestChangeHandler(e)} >
                                    <option value="Economy" selected>Economy</option>
                                    <option value="StandardEconomy">Standard Economy</option>
                                    <option value="PremiumEconomy">Premium Economy</option>
                                    <option value="Business">Business</option>
                                    <option value="PremiumBusiness">Premium Business</option>
                                    <option value="First">First</option>
                                    <option value="PremiumFirst">Premium First</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="row">
                                  <div className="col-md-2">&nbsp;</div>
                                  <div className="col-md-5 col-lg-5">
                                    <p className="b-sub-txt">Departure Date</p>
                                    <small>&nbsp;</small>
                                    <div className="origin-form"><input type="text" defaultValue="Choose Date" id='input_from_round' className="form-control" name='departureDate' onSelect={(e) => inputDateChangeHandler(e)} /></div>
                                  </div>
                                  <div className="col-md-5 col-lg-5">
                                    <p className="b-sub-txt">Return Date</p>
                                    <small>&nbsp;</small>
                                    <div className="origin-form"><input type="text" defaultValue="Choose Date" id='input_to_round' className="form-control" name='arrivalDate' onSelect={(e) => inputDateChangeHandler(e)} /></div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-2 col-md-2" />
                                  <div className="col-lg-10 col-md-10">
                                    <p className="b-sub-txt">Fare Type</p>
                                    <div className="fare-form">
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="defence" id="defence" />
                                        <label className="form-check-label" htmlFor="defence">
                                          Defence Forces
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="students" id="students" />
                                        <label className="form-check-label" htmlFor="students">
                                          Students
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="senior" id="senior" />
                                        <label className="form-check-label" htmlFor="senior">
                                          Students
                                        </label>
                                      </div>
                                    </div>
                                    {/*Search Button*/}
                                    <button type="button" className="btn btn-primary f-search-btn" onClick={() => SearchFlights('R')}>Search Flights</button>
                                    {/* <input type="submit" className="btn btn-primary f-search-btn" onClick={Roundtripevent} defaultValue="Search Flight" /> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="ex1-pills-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                          <div className="booking-content">
                            <div className="row">
                              <div className="col-md-3">
                                <p className="b-sub-txt">Depart from</p>
                                <small>Origin</small>
                                <div className="origin-form"><input type="text" defaultValue="Dubai" className="form-control" name='origin'  onBlur={(e) => inputSearchSectorChangeFocusOut(e, "source")} /></div>
                                <p className="b-sub-txt-2">Traveller(s)</p>
                                <div className="row">
                                  <div className="col-lg-4 col-md-4 col-4">
                                    <label className="small-label">Adult (12+)</label>
                                    <select className="form-control t-select" id="Adult" fdprocessedid="jtwsm" name="adult" onChange={(e) => inputOnewaySearchRequestChangeHandler(e)}><option value={1}>01</option>
                                      <option value={2}>02</option>
                                      <option value={3}>03</option>
                                      <option value={4}>04</option>
                                      <option value={5}>05</option>
                                      <option value={6}>06</option>
                                      <option value={7}>07</option>
                                      <option value={8}>08</option>
                                      <option value={9}>09</option>
                                    </select>
                                  </div>
                                  <div className="col-lg-4 col-md-4 col-4">
                                    <label className="small-label">Child (2-11)</label>
                                    <select className="form-control t-select" id="Child" name="child" onChange={(e) => inputOnewaySearchRequestChangeHandler(e)} fdprocessedid="pxdj69"><option selected="selected" value={0}>00</option>
                                      <option value={1}>01</option>
                                      <option value={2}>02</option>
                                      <option value={3}>03</option>
                                      <option value={4}>04</option>
                                      <option value={5}>05</option>
                                      <option value={6}>06</option>
                                      <option value={7}>07</option>
                                      <option value={8}>08</option>
                                    </select>
                                  </div>
                                  <div className="col-lg-4 col-md-4 col-4">
                                    <label className="small-label" style={{ width: '100%' }}>Infant (0-2)</label>
                                    <select className="form-control t-select" id="Child" name="infant" onChange={(e) => inputOnewaySearchRequestChangeHandler(e)} fdprocessedid="pxdj69"><option selected="selected" value={0}>00</option>
                                      <option value={1}>01</option>
                                      <option value={2}>02</option>
                                      <option value={3}>03</option>
                                      <option value={4}>04</option>
                                      <option value={5}>05</option>
                                      <option value={6}>06</option>
                                      <option value={7}>07</option>
                                      <option value={8}>08</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1 pt-5 text-center">
                                <div className="swap-image">
                                  <img src="assets/img/swap.webp" width={35} alt="" />
                                </div>
                              </div> <div className="col-md-3">
                                <p className="b-sub-txt">Going to</p>
                                <small>Destination</small>
                                <div className="origin-form"><input type="text" defaultValue="CCJ" className="form-control" name='destination'  onBlur={(e) => inputSearchSectorChangeFocusOut(e, "destination")} /></div>
                                <div className="col-lg-12 col-md-12 col-12">
                                  <p className="b-sub-txt-2">Class</p>
                                  <label className="small-label" style={{ width: '100%' }}>Select Class</label>
                                  <select className="form-control t-select" name="airlineClass" id="airline_class" onChange={(e) => inputOnewaySearchRequestChangeHandler(e)} fdprocessedid="92g40h">
                                    <option value="Economy" selected>Economy</option>
                                    <option value="StandardEconomy">Standard Economy</option>
                                    <option value="PremiumEconomy">Premium Economy</option>
                                    <option value="Business">Business</option>
                                    <option value="PremiumBusiness">Premium Business</option>
                                    <option value="First">First</option>
                                    <option value="PremiumFirst">Premium First</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="row">
                                  <div className="col-md-2">&nbsp;</div>
                                  <div className="col-md-5 col-lg-5">
                                    <p className="b-sub-txt">Departure Date</p>
                                    <small>&nbsp;</small>
                                    <div className="origin-form"><input type="text" defaultValue="Choose Date" id='input_from_multi' className="form-control" name='departureDate' onSelect={(e) => inputDateChangeHandler(e)} /></div>
                                  </div>
                                  <div className="col-md-5 col-lg-5">
                                    <p className="b-sub-txt">Return Date</p>
                                    <small>&nbsp;</small>
                                    <div className="origin-form"><input type="text" defaultValue="Choose Date" id='input_to_multi' className="form-control" name='arrivalDate' onSelect={(e) => inputDateChangeHandler(e)} /></div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-2 col-md-2" />
                                  <div className="col-lg-10 col-md-10">
                                    {/*Search Button*/}
                                    <button type="button" className="btn btn-primary f-search-btn" onClick={() => SearchFlights('M')}>Search Flights</button>
                                    <p className="b-sub-txt">Fare Type</p>
                                    <div className="fare-form">
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="defence" id="defence" />
                                        <label className="form-check-label" htmlFor="defence">
                                          Defence Forces
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="students" id="students" />
                                        <label className="form-check-label" htmlFor="students">
                                          Students
                                        </label>
                                      </div>
                                      <div className="form-check">
                                        <input className="form-check-input" type="radio" name="senior" id="senior" />
                                        <label className="form-check-label" htmlFor="senior">
                                          Students
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* route content */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="top-flight-deals">
        <div className="container">
          <h2 className="text-center">Top Flight Deals</h2>
          {/* <div className="owl-carousel top-flight-deals"> */}
          <OwlCarousel items={5} className="owl-carousel top-flight-deals"
            loop
            autoplay
          
              margin={8} 
              responsive={{
                // Responsive breakpoints and corresponding item numbers
                0: {
                  items: 1,
                },
                600: {
                  items: 2,
                },
                1024: {
                  items: 5,
                },
              }}

           >
            <div className="flight-route">
              <div className="flight-offer">
                <h5>Flat 15% Off!</h5>
              </div>
              <div className="flight-details">
                <div className="route-box">
                  <div className="f-route">
                    <div>
                      <div className="f-route-short">JAI</div>
                      <div className="f-route-name t-ovrflw" title="Jaipur">Jaipur</div>
                    </div>
                  </div>
                  <span className="sc-n84dau-56 cOakUb">
                    <div alt="flight-icon" className="sc-n84dau-62 jUcXfi" />
                  </span>
                  <div className="s-route">
                    <div>
                      <div className="f-route-short">DED</div>
                      <div className="f-route-name t-ovrflw" title="Dehradun">Dehradun</div>
                    </div>
                  </div>
                </div>
                <p className="text-center">Travel between</p>
                <h6 className="text-center text-blue offer-date">March 15, Tue - March 25, Fri</h6>
                <h6 className="offer-price">₹ 4599</h6>
              </div>
            </div>
            <div className="flight-route">
              <div className="flight-offer">
                <h5>Flat 15% Off!</h5>
              </div>
              <div className="flight-details">
                <div className="route-box">
                  <div className="f-route">
                    <div>
                      <div className="f-route-short">JAI</div>
                      <div className="f-route-name t-ovrflw" title="Jaipur">Jaipur</div>
                    </div>
                  </div>
                  <span className="sc-n84dau-56 cOakUb">
                    <div alt="flight-icon" className="sc-n84dau-62 jUcXfi" />
                  </span>
                  <div className="s-route">
                    <div>
                      <div className="f-route-short">DED</div>
                      <div className="f-route-name t-ovrflw" title="Dehradun">Dehradun</div>
                    </div>
                  </div>
                </div>
                <p className="text-center">Travel between</p>
                <h6 className="text-center text-blue offer-date">March 15, Tue - March 25, Fri</h6>
                <h6 className="offer-price">₹ 4599</h6>
              </div>
            </div>
            <div className="flight-route">
              <div className="flight-offer">
                <h5>Flat 15% Off!</h5>
              </div>
              <div className="flight-details">
                <div className="route-box">
                  <div className="f-route">
                    <div>
                      <div className="f-route-short">JAI</div>
                      <div className="f-route-name t-ovrflw" title="Jaipur">Jaipur</div>
                    </div>
                  </div>
                  <span className="sc-n84dau-56 cOakUb">
                    <div alt="flight-icon" className="sc-n84dau-62 jUcXfi" />
                  </span>
                  <div className="s-route">
                    <div>
                      <div className="f-route-short">DED</div>
                      <div className="f-route-name t-ovrflw" title="Dehradun">Dehradun</div>
                    </div>
                  </div>
                </div>
                <p className="text-center">Travel between</p>
                <h6 className="text-center text-blue offer-date">March 15, Tue - March 25, Fri</h6>
                <h6 className="offer-price">₹ 4599</h6>
              </div>
            </div>
            <div className="flight-route">
              <div className="flight-offer">
                <h5>Flat 15% Off!</h5>
              </div>
              <div className="flight-details">
                <div className="route-box">
                  <div className="f-route">
                    <div>
                      <div className="f-route-short">JAI</div>
                      <div className="f-route-name t-ovrflw" title="Jaipur">Jaipur</div>
                    </div>
                  </div>
                  <span className="sc-n84dau-56 cOakUb">
                    <div alt="flight-icon" className="sc-n84dau-62 jUcXfi" />
                  </span>
                  <div className="s-route">
                    <div>
                      <div className="f-route-short">DED</div>
                      <div className="f-route-name t-ovrflw" title="Dehradun">Dehradun</div>
                    </div>
                  </div>
                </div>
                <p className="text-center">Travel between</p>
                <h6 className="text-center text-blue offer-date">March 15, Tue - March 25, Fri</h6>
                <h6 className="offer-price">₹ 4599</h6>
              </div>
            </div>
            <div className="flight-route">
              <div className="flight-offer">
                <h5>Flat 15% Off!</h5>
              </div>
              <div className="flight-details">
                <div className="route-box">
                  <div className="f-route">
                    <div>
                      <div className="f-route-short">JAI</div>
                      <div className="f-route-name t-ovrflw" title="Jaipur">Jaipur</div>
                    </div>
                  </div>
                  <span className="sc-n84dau-56 cOakUb">
                    <div alt="flight-icon" className="sc-n84dau-62 jUcXfi" />
                  </span>
                  <div className="s-route">
                    <div>
                      <div className="f-route-short">DED</div>
                      <div className="f-route-name t-ovrflw" title="Dehradun">Dehradun</div>
                    </div>
                  </div>
                </div>
                <p className="text-center">Travel between</p>
                <h6 className="text-center text-blue offer-date">March 15, Tue - March 25, Fri</h6>
                <h6 className="offer-price">₹ 4599</h6>
              </div>
            </div>
            <div className="flight-route">
              <div className="flight-offer">
                <h5>Flat 15% Off!</h5>
              </div>
              <div className="flight-details">
                <div className="route-box">
                  <div className="f-route">
                    <div>
                      <div className="f-route-short">JAI</div>
                      <div className="f-route-name t-ovrflw" title="Jaipur">Jaipur</div>
                    </div>
                  </div>
                  <span className="sc-n84dau-56 cOakUb">
                    <div alt="flight-icon" className="sc-n84dau-62 jUcXfi" />
                  </span>
                  <div className="s-route">
                    <div>
                      <div className="f-route-short">DED</div>
                      <div className="f-route-name t-ovrflw" title="Dehradun">Dehradun</div>
                    </div>
                  </div>
                </div>
                <p className="text-center">Travel between</p>
                <h6 className="text-center text-blue offer-date">March 15, Tue - March 25, Fri</h6>
                <h6 className="offer-price">₹ 4599</h6>
              </div>
            </div>
          </OwlCarousel>
          {/* </div> */}
        </div>
      </section>

      <section id="top-airline-deals">
        <div className="container">
          <h2 className="text-center">Best Airline Deals</h2>
          <OwlCarousel items={3} className="owl-carousel top-airline-deals"
            loop
            autoplay
          
margin={8} 

responsive={{
  // Responsive breakpoints and corresponding item numbers
  0: {
    items: 1,
  },
  600: {
    items: 2,
  },
  1024: {
    items: 5,
  },
}}
            
            
            >
            {/* <div className="owl-carousel top-airline-deals"> */}
            <div className="item">
              <img src="assets/img/deals/deal1.webp" className="img-fluid" />
            </div>
            <div className="item">
              <img src="assets/img/deals/deal2.webp" className="img-fluid" />
            </div>
            <div className="item">
              <img src="assets/img/deals/deal3.webp" className="img-fluid" />
            </div>
            <div className="item">
              <img src="assets/img/deals/deal4.webp" className="img-fluid" />
            </div>
            {/* </div> */} </OwlCarousel>
        </div>
      </section>
      <section id="top-hotel-deals">
        <div className="container">
          <h2 className="text-center">Discover our best hotels to stay</h2>
          {/* <div className="owl-carousel top-hotel-deals"> */}
          <OwlCarousel items={5} className="owl-carousel top-hotel-deals"
            loop
            autoplay
            margin={8} 
            responsive={{
              // Responsive breakpoints and corresponding item numbers
              0: {
                items: 2,
              },
              600: {
                items: 2,
              },
              1024: {
                items: 5,
              },
            }}
            >
            <div className="item hotel-area">
              <div className="hotel-overlay">
                <div className="deal-icon"><img src="assets/img/deal-icon.webp" style={{ width: '90px !important' }} /></div>
                <div className="hotel-details">
                  <h5 className="hotel-name">Grant Hayat</h5>
                  <h5 className="hotel-loc"><i className="material-icons">location_on</i> Kochi</h5>
                  <p className="hotel-sub">Starting from</p>
                  <h4 className="hotel-price">₹ 4599</h4>
                </div>
              </div>
              <img src="assets/img/hotel.webp" className="img-fluid" />
            </div>
            <div className="item hotel-area">
              <div className="hotel-overlay">
                <div className="hotel-details">
                  <h5 className="hotel-name">Grant Hayat</h5>
                  <h5 className="hotel-loc"><i className="material-icons">location_on</i> Kochi</h5>
                  <p className="hotel-sub">Starting from</p>
                  <h4 className="hotel-price">₹ 4599</h4>
                </div>
              </div>
              <img src="assets/img/hotel.webp" className="img-fluid" />
            </div>
            <div className="item hotel-area">
              <div className="hotel-overlay">
                <div className="hotel-details">
                  <h5 className="hotel-name">Grant Hayat</h5>
                  <h5 className="hotel-loc"><i className="material-icons">location_on</i> Kochi</h5>
                  <p className="hotel-sub">Starting from</p>
                  <h4 className="hotel-price">₹ 4599</h4>
                </div>
              </div>
              <img src="assets/img/hotel.webp" className="img-fluid" />
            </div>
            <div className="item hotel-area">
              <div className="hotel-overlay">
                <div className="hotel-details">
                  <h5 className="hotel-name">Grant Hayat</h5>
                  <h5 className="hotel-loc"><i className="material-icons">location_on</i> Kochi</h5>
                  <p className="hotel-sub">Starting from</p>
                  <h4 className="hotel-price">₹ 4599</h4>
                </div>
              </div>
              <img src="assets/img/hotel.webp" className="img-fluid" />
            </div>
            <div className="item hotel-area">
              <div className="hotel-overlay">
                <div className="deal-icon"><img src="assets/img/deal-icon.webp" style={{ width: '90px !important' }} /></div>
                <div className="hotel-details">
                  <h5 className="hotel-name">Grant Hayat</h5>
                  <h5 className="hotel-loc"><i className="material-icons">location_on</i> Kochi</h5>
                  <p className="hotel-sub">Starting from</p>
                  <h4 className="hotel-price">₹ 4599</h4>
                </div>
              </div>
              <img src="assets/img/hotel.webp" className="img-fluid" />
            </div>
            <div className="item hotel-area">
              <div className="hotel-overlay">
                <div className="deal-icon"><img src="assets/img/deal-icon.webp" style={{ width: '90px !important' }} /></div>
                <div className="hotel-details">
                  <h5 className="hotel-name">Grant Hayat</h5>
                  <h5 className="hotel-loc"><i className="material-icons">location_on</i> Kochi</h5>
                  <p className="hotel-sub">Starting from</p>
                  <h4 className="hotel-price">₹ 4599</h4>
                </div>
              </div>
              <img src="assets/img/hotel.webp" className="img-fluid" />
            </div>
            <div className="item hotel-area">
              <div className="hotel-overlay">
                <div className="deal-icon"><img src="assets/img/deal-icon.webp" style={{ width: '90px !important' }} /></div>
                <div className="hotel-details">
                  <h5 className="hotel-name">Grant Hayat</h5>
                  <h5 className="hotel-loc"><i className="material-icons">location_on</i> Kochi</h5>
                  <p className="hotel-sub">Starting from</p>
                  <h4 className="hotel-price">₹ 4599</h4>
                </div>
              </div>
              <img src="assets/img/hotel.webp" className="img-fluid" />
            </div>
            <div className="item hotel-area">
              <div className="hotel-overlay">
                <div className="deal-icon"><img src="assets/img/deal-icon.webp" style={{ width: '90px !important' }} /></div>
                <div className="hotel-details">
                  <h5 className="hotel-name">Grant Hayat</h5>
                  <h5 className="hotel-loc">Kochi</h5>
                  <p className="hotel-sub">Starting from</p>
                  <h4 className="hotel-price">₹ 4599</h4>
                </div>
              </div>
              <img src="assets/img/hotel.webp" className="img-fluid" />
            </div>
            <div className="item hotel-area">
              <div className="hotel-overlay">
                <div className="deal-icon"><img src="assets/img/deal-icon.webp" style={{ width: '90px !important' }} /></div>
                <div className="hotel-details">
                  <h5 className="hotel-name">Grant Hayat</h5>
                  <h5 className="hotel-loc">Kochi</h5>
                  <p className="hotel-sub">Starting from</p>
                  <h4 className="hotel-price">₹ 4599</h4>
                </div>
              </div>
              <img src="assets/img/hotel.webp" className="img-fluid" />
            </div>
            {/* </div> */} </OwlCarousel>
        </div>
      </section>


      <section id="why-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12 text-center why-us-block">
              <img src="assets/img/best-price.webp" />
              <h4>Assured Best Rates</h4>
              <p>Find lowest possible prices to<br />destinations</p>
            </div>
            <div className="col-lg-4 col-md-4 col-12 text-center why-us-block">
              <img src="assets/img/best-price.webp" />
              <h4>Easy Booking</h4>
              <p>Search and save - the fastest way to<br />book your trip.</p>
            </div>
            <div className="col-lg-4 col-md-4 col-12 text-center why-us-block">
              <img src="assets/img/safe-icon.webp" />
              <h4>Safe &amp; Secure</h4>
              <p>Trusted by Millions; Every Transaction<br />is Safe &amp; Secure</p>
            </div>
          </div>
        </div>
        <div className="why-us-bg">
          <div className="container">
            <h2 className="text-center">Why TravelMythri is the Best Travel Portal</h2>
            <p>TravelMythri is the niche place that offers you convenience of booking cheap flight tickets and eye riveting deals on competitive prices. Right from selecting your favourite deals on the portal to the choice of destination you want, TravelMythri is going to make your travel easy and smooth. We ensure safe financial transactions through secured payment gateways. Above all, we have ATOL registration backing our efforts. Catch the fresh flight deals and that’s discounted. Travel like a monarch with TravelMythri.</p>
          </div>
        </div>
      </section>


      <div>
        <section id="mobile-app">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-6 col-md-6">
                <h2>Download Our Mobile App</h2>
                <p>Book the flight ticket and hotel with the huge discount.
                  Refer friends and get generous bonuses from theirs orders.</p>
                <form id="app-download" className="app-form">
                  <div className="d-flex">
                    <input type="text" className="form-control" placeholder="Enter your Mobile number" />
                    <button type="submit" className="btn btn-primary app-send">Send App Link</button>
                  </div>
                </form>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="d-flex">
                  <div className="app-icons flex-row">
                    <div className="mb-3"><img src="assets/img/appstore.svg" width={150} /></div>
                    <div className="flex-row"><img src="assets/img/playstore.svg" width={150} /></div>
                  </div>
                  <div className="app-icons flex-row">
                    <div className="mb-3 pl-20"><img src="assets/img/qr.png" width={105} /></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <img src="assets/img/mobile-app.webp" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>
      </div>




      {/* model popup login */}
      <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Sign in to your TravelMythri Account!</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <form className="login-form" id="login" action>
                <input type="text" className="form-control" defaultValue="Enter Mobile Number" />
                <button type="submit" className="btn btn-primary">GET OTP</button>
                <div className="pt-2 pb-2">
                  <p className="or-section">Or</p>
                </div>
                <div className="text-center">
                  <a className="google-login"><img src="assets/img/Google__G__Logo.svg" width={30} alt="" /> Sign in with Google</a>
                </div>
                <div className="pt-3 pb-3 small-text text-center"><p>By continuing, you agree to our <a className="privacy-link">privacy policy &amp; terms of use.</a></p></div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* BODY END */}



    </div>


  )
}



export default Cc