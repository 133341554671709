import React, { Component } from 'react'
import { useNavigate, Link, json } from "react-router-dom";
class Disclaimer extends Component {
  render() {
    return (
      <div>
          <div className="container" style={{ paddingTop: '25px' }}>
        <h2>Disclaimer</h2>
        <p>The information contained in this Web Site is only for general information about Alhind Tours & Travels Pvt. Ltd., its subsidiaries and associates (here in after referred to as 'alhind') and the services that they offer and is not in any way binding on alhind. It does not constitute advice and should not be relied upon in making or refraining from making any decision.</p>
      <p>Alhind hereby excludes any warranty (either express or implied) as to the correctness, completeness, timeliness, accuracy, fitness for a particular purpose of the Web Site or any of its contents.</p>
      <p>By accessing this Web Site you agree that alhind will not be liable for any direct or indirect or consequential loss, damages or compensation arising from the use of the information and material contained in this Web Site or from your access of other material on the internet via web links for this Web Site.</p>
      <p>The carrying of passengers, baggage and cargo by air will be subject to the conditions of carriage and regulations of respective carriers which may be seen on request by contacting the respective carriers local office.</p>
      <p>Unless otherwise stated, copyright or similar rights in all material contained in this Web Site is owned by alhind. Your access to it does not imply a free license to use it unless permitted by law</p>
     <strong>Cancellation / Refund / Reissuance of ticket policy</strong>
     <p>Guests are requested to contact the carrier for more information on the policy.</p>
      </div>
      
      </div>
    )
  }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <Disclaimer {...props} navigate={navigate} />;
  }
  
  export default WithNavigate;
