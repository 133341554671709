import AxiosConfig from '../Configuration/AxiosConfig'
import HeaderConfig from '../Configuration/HeaderConfig';
import { CheckInternetConnection, EmptyResponse } from '../Services/UtilitiesServices';

  export function GetAllHajjPackages()
  { 
      return AxiosConfig.post("HajjPackage/GetAllHajjPackages",{}, HeaderConfig());
  }

  export function GetHajjPackageDetailsById(data)
  { 
      return AxiosConfig.post("HajjPackage/HajjPackageFullDetails",data, HeaderConfig());
  }

  export function PostHajjPackageEnquiry(data)
  { 
    console.log(data);
      return AxiosConfig.post("HajjPackage/PostHajjEnquiry",data, HeaderConfig());
  }