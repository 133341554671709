import AxiosConfig from '../Configuration/AxiosConfig'
import HeaderConfig from '../Configuration/HeaderConfig';
import { CheckInternetConnection } from '../Services/UtilitiesServices';

export function GetFlightDetails(data)
  {
    return AxiosConfig.post("AirlineSearch/GetFlightDetails",data, HeaderConfig());
  }

  export function GetFlightDetailsIR(data)
  {
    return AxiosConfig.post("AirlineSearch/GetFlightDetailsIR",data, HeaderConfig());
  }

  export function GetPricingDetails(data)
  {
    return AxiosConfig.post("Pricing/GetPricingDetails",data, HeaderConfig());
  }  

  export function GetPricingDetailsIR(data)
  {
    return AxiosConfig.post("Pricing/GetPricingDetailsIR",data, HeaderConfig());
  }

  export function GetRePricingDetails(data)
  {
    return AxiosConfig.post("Pricing/GetRePricingDetails",data, HeaderConfig());
  }

  // export function GetRePricingDetailsIR(data)
  // {
  //   return AxiosConfig.post("AirlineSearch/GetRePricingDetailsIR",data);
  // }

  export function GetBookingConfirm(data)
  {
    return AxiosConfig.post("Booking/GetBookingResponse",data, HeaderConfig());
  }

  // export function GetBookingConfirmIR(data)
  // {
  //   return AxiosConfig.post("AirlineSearch/GetBookingResponseIRT",data);
  // }

  export function GetTicketCopyUrl(data)
  {
    return AxiosConfig.post("Update/TicketToPrint",data, HeaderConfig());
  }

  export function GetDealCodDetails(data)
  {
    return AxiosConfig.post("Pricing/GetDealCodDetails",data, HeaderConfig());
  }  

  export function GetSeatAvailability(data)
  {
    return AxiosConfig.post("Pricing/GetSeatAvailability",data, HeaderConfig());
  }  