import React, { Component } from 'react'
import { useNavigate, Link, json } from "react-router-dom";
class Insurance extends Component {
  render() {
    return (
      <div>
           <div className="container">
        <h2 class="mb-2 mx-2 cs-title mt-5 text-center">Launching soon!</h2>
        <p className="cs-text text-center">We are currently developing the system for a better experience.<br/>Apologize for the inconvenience and thank you.</p>
        <div className="row align-items-center justify-content-center">
        <div className="col-lg-4 col-md-4">
       
        <img src="assets/img/insurance-bg.webp" className="img-fluid" />
        </div>
        <div className="col-lg-6 col-md-6">
        <div className="card cs-card">
          <div className="card-title">
          <h4>For Insurance Enquiries Please contact us </h4>
          </div>
          <div className="card-body">
         
          <h4><img src="assets/img/support.svg" width={30}/> 0495 2389900</h4>
        <h4><img src="assets/img/email.svg" width={30}/> b2c@alhindonline.com</h4>
          </div>
        </div>
        </div>
        </div>

        </div>
      
      </div>
    )
  }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <Insurance {...props} navigate={navigate} />;
  }
  
  export default WithNavigate;
