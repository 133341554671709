import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function PrivacyPolicy(props) {
  const navigate = useNavigate();

  const deleteProfile = () => {
    navigate('/Delete');
  };

  // const [userDetails, setUserDetails] = useState(null);

  // useEffect(() => {
  //   const tokenValue = localStorage.getItem("tokenValue");
  //   if (tokenValue !== null) {
  //     const userDetails = JSON.parse(localStorage.getItem("TM_UDetails"));
  //     setUserDetails(userDetails);
  //   }
  // }, []);

  return (
    <div>
      

      <div className="container" style={{ paddingTop: '25px' }}>
        <h2>Privacy Policy</h2>
        <p>We understand that making purchases online involves a great deal of trust on your part. We take this trust very seriously, and make it our highest priority to ensure the security and confidentiality of the personal information you provide to us. Please read the following policy to learn about our privacy practices</p>
        <h5>What information we collect from you?</h5>
        <p><strong>General:</strong> We receive and store any information you enter on our Website or give us in any other way. This includes information that can identify you (personal information), including your first and last name, telephone number, postal and e-mail addresses, contact details and, in some cases, billing information (such as credit card number, cardholder name, and expiration date). We also may request information about your traveler preferences, including meal requests, seat selection, frequent flyer/hotel/car rental program information, and ticketing options. You can choose not to provide information to us, but in general some information about you is required in order for you to register as a member; purchase products or services; complete a traveler profile; participate in a survey, contest, or promotions; or initiate other transactions on our site.</p>
      <p>
      <strong>Passenger Information:</strong> When you make travel or other arrangements for someone else through the Website , we will request personal information and travel preferences about that individual. You should obtain the consent of other individuals prior to providing the Website with their personal information and travel preferences, as any access to view or change their information will be available only through your account.
      </p>
      <p>
      <strong>Information from Other Sources:</strong> We also may periodically obtain information about you from our business partners and other independent third-party sources and add it to our account information. Examples of information we may receive include: updated delivery and address information, which we use to correct our records and to facilitate proper delivery of travel information; and demographic information, which we use to better understand your potential purchasing preferences.
      </p>
     <p>
     <strong>Automatic Information:</strong> We automatically collect some information about your computer when you visit the Website. For example, we will collect your IP address, Web browser software (such as Netscape Navigator or Internet Explorer), and referring Web site. We also may collect information about your online activity, such as trips viewed and purchases made. One of our goals in collecting this automatic information is to help customize your user experience.
     </p>
     <p>
     <strong>Cookies and Other Web Technologies:</strong> When you visit the Website we may assign your computer a cookie (a small, unique identifier text file). For example, whenever you sign in as a member, we record your member ID and the name on your member account in the cookie file on your computer. We also may record your password in this cookie file to enable you for automatic sign-in. Note that member IDs, passwords, and any other account-related data included in such cookies are encrypted for security purposes. You can always choose not to receive a cookie file by enabling your Web browser to refuse cookies or to prompt you before accepting a cookie. Please note that if you refuse to accept cookies from the Website , you may not be able to access many of the travel tools offered by the Website .
     </p>
      
     <p>
     <strong>Information Collected by Third-Parties:</strong> Third-parties, such as vendors and business partners, may use cookies and other technologies (such as Web beacons or single-pixel GIFS) to collect information about your online activity. The purposes for which this information is collected include: to measure usage of our site; to analyze, modify, and personalize advertising content on the Website and other sites; and to provide ads about goods and services we hope will be of interest to you. We do not have access to or control over cookies or other features these third parties may use, and their information practices are not covered by this Privacy Policy. Some of these third parties may be third-party network advertisers that offer you the option of not having this information collected. You may learn more by clicking here.
     </p>
      
    <h5>How we use your information</h5>
      
       <p>
       The Website uses sensitive billing information (such as cardholder name, credit card number, and expiration date) for the purpose of completing the travel purchases you conduct on our site. We use other information about you for the following general purposes:
       </p>
        
        <ul>
        <li>To provide you with the products and services you request;</li>

 <li>To provide you with travel confirmation and updates;</li>

 <li>To manage your account, including processing bills and providing travel notifications;</li>

 <li>To communicate with you in general;</li>

 <li>To respond to your questions and comments;</li>

 <li>To measure interest in and improve our products, services, and web site;</li>

 <li>To otherwise customize your experience with the website;</li>

 <li>To reward you as part of any reward and recognition program you choose to join;</li>

 <li>To solicit information from you, including through surveys;</li>

 <li>To resolve disputes, collect fees, or troubleshoot problems;</li>

 <li>To prevent potentially prohibited or illegal activities;</li>

 <li>To enforce our terms of use;</li>
        </ul>
   <p>Seek your feedback or to contact you in relation to services offered on the website ; and as otherwise described to you at the point of collection.</p>    
    <h5>With whom we share your information?</h5>  
     <p>
     We do not sell or rent your personal information to anyone. We may however share your information with the following entities:
     </p>
        <p>
        Suppliers, such as hotel, airline, cruise, car rental companies, or activity providers, who fulfill your travel reservations. Throughout the Website, all services provided by a third-party supplier are described as such. We do not place limitations on our suppliersâ€™ use or disclosure of your personal information. Therefore, we encourage you to review the privacy policies of any travel supplier whose products you purchase through the Website. Please note that these suppliers also may contact you as necessary to obtain additional information about you or to facilitate your travel reservation.
        </p>
       <p>
       Third-party vendors who provide services or functions on our behalf, including credit card processing, business analytics, customer service, marketing, distribution of surveys or sweepstakes programs, and fraud prevention. These companies have access to personal information needed to perform their functions but are not permitted to share or use the information for any other purpose.
       </p>
    <p>
    Business partners with whom we may jointly offer products or services. You can tell when a third party is involved in a product or service you have requested because their name will appear with ours. If you choose to access these optional services, we may share information about you, including your personal information, with those partners. Please note that we do not control the privacy practices of these third-party business partners and recommend you evaluate their practices before deciding to provide your personal information.
    </p>
<p>
Companies within our business group. Sharing this information with our corporate affiliates to enables us to provide you with other information about products and services, both travel-related and other, which might interest you. To the extent that our corporate affiliates have access to your information, they will follow practices that are at least as restrictive as the practices described in this Privacy Policy. They also will comply with applicable laws governing the transmission of promotional communications and, at a minimum, give you an opportunity in any commercial e-mail they send to choose not to receive such e-mail messages in the future.Companies within our business group. Sharing this information with our corporate affiliates to enables us to provide you with other information about products and services, both travel-related and other, which might interest you. To the extent that our corporate affiliates have access to your information, they will follow practices that are at least as restrictive as the practices described in this Privacy Policy. They also will comply with applicable laws governing the transmission of promotional communications and, at a minimum, give you an opportunity in any commercial e-mail they send to choose not to receive such e-mail messages in the future.
</p>
<h5>We also may share your information:</h5>
<p>
In response to subpoenas, court orders, or other legal process; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law. In such cases we reserve the right to raise or waive any legal objection or right available to us.:
</p>
<p>
When we believe it is appropriate to investigate, prevent, or take action regarding illegal or suspected illegal activities; to protect and defend the rights, property, or safety of the Website, our customers, or others; and in connection with our Terms of Service and other agreements.
</p>
<p>
In connection with a corporate transaction, such as a divestiture, merger, consolidation, or asset sale or in the unlikely event of bankruptcy.In connection with a corporate transaction, such as a divestiture, merger, consolidation, or asset sale or in the unlikely event of bankruptcy.
</p>
     <p>
     Other than as set out above, you will be notified when personal information about you will be shared with third parties, and you will have an opportunity to choose not to have us share such information.
      </p>  
<p>
We also may share aggregate or anonymous information with third parties, including advertisers and investors. For example, we may tell our advertisers the number of visitors a Web site receives or what are the most popular vacation destinations. This information does not contain any personal information and is used to develop content and services we hope you will find of interest.
</p>
   <h5>How you can access your information</h5>     
<p>
You can access your information at various points on the the Website. These include:
</p>
<p>
The My Page on the Website, where you can view, update, or change your personal information. On this page, you also can change your password; add new travelers and delete existing travelers from your member profile; update or correct names, phone numbers, and emergency contacts for travelers associated with your profile; and update your travel preferences.
  </p>       

<p>
The My Itineraries page, where you can view, update, or delete your saved trip itineraries.
</p>
<h5>How we protect your information</h5>
<p>
We want you to feel confident about using the Website to plan and purchase your travel, so we are committed to protecting the information we collect. While no Web site can guarantee security, we have implemented appropriate administrative, technical, and physical security procedures to help protect the personal information you provide to us. For example, only authorized employees/ development partners are permitted to access personal information, and they only may do so for permitted business functions. In addition, we may use encryption when transmitting your sensitive personal information between your system and ours, and we employ firewalls and intrusion detection systems to help prevent unauthorized persons from gaining access to your information.
</p>
<p>
Our systems are configured with data encryption, or scrambling, technologies, and industry-standard firewalls. When you send personal information to the Website over the Internet, your data is protected by Secure Socket Layer (SSL) technology to ensure safe transmission of certain confidential data.
</p>
<h5>External Links</h5>
<p>
If any part of the Website links you to other Web sites, those Web sites do not operate under this Privacy Policy. We recommend you examine the privacy statements posted on those other Web sites to understand their procedures for collecting, using, and disclosing personal information.
</p>
<h5>Changes to this Privacy Policy</h5>
<p>
We may update this Privacy Policy in the future. We will notify you about material changes to this Privacy Policy by sending a notice to the e-mail address you provided to us or by placing a prominent notice on our Web site.
</p>







      
        <h5>How long do we keep your personal information?</h5>
        <ul>
          <li>Your personal information will be stored on Alhind.com's servers for a reasonable duration as required for the outlined purposes in this policy. In certain situations, we might retain your personal information for extended periods, especially when mandated by legal, regulatory, tax, or accounting obligations.</li>
          <li>Once your personal data is no longer necessary, we will guarantee its secure deletion or storage in a manner that ensures it is no longer accessible or utilized by the company.</li>
          {/* {userDetails && userDetails.userId > 0 && ( */}
          <li>If users wish to delete their accounts, they can do so by following this 
          
                <a onClick={deleteProfile} style={{ color: 'blue', cursor: 'pointer' }}>Link</a>    
          </li>
          {/* )} */}
        </ul>
        <h5>How you can contact us</h5>
        <p>
        If you have questions about either Privacy Policy (or your travel planning or purchases), please e-mail us using our support and feedback form. In addition, you may directly contact the travel supplier at the phone number or address provided on your travel itinerary.
        </p>
        <p>This Privacy Policy is effective as of March 15, 2024</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
