import React, { Component } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { GetDeleteProfilePageLoadData, DeleteProfileRequest, DeleteProfileOTPSubmission } from '../../Services/AccountServices'
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
class Delete extends Component {

    constructor(props) {
    super(props);
    this.state = {
      ReasonList: [],
      isData: false,
      isOtherReason: false,
      reasonDetails: {
        reason:""
      },
      UserDetails: {},
      IsOTPSUbmitted: false,
      show: false,
      show1: false,
      mobile:"",

    };
    this.SubmitDeleteRequest = this.SubmitDeleteRequest.bind(this);
    this.DeletionOTPSubmit = this.DeletionOTPSubmit.bind(this);

  }

  componentDidMount() {

    let uData = JSON.parse(localStorage.getItem("TM_UDetails"));

    if(uData != null)
    {
      let reasonData = this.state.reasonDetails;
      reasonData.userId = uData.userId;
      this.setState({
        UserDetails: uData,
        reasonDetails: reasonData,
      });
  
  
      GetDeleteProfilePageLoadData().then((response) => {
        
        if (response.data.status) {
          let data = this.state.reasonDetails;
        //  console.log(response.data);
        data.reason = String(response.data.reasonsList[0].reason);
          this.setState({
            isData: true,
            ReasonList: response.data.reasonsList,
          })
        }
        else if(!response.data.status && response.data.responseMessage == "SuperUser")
        {
          window.alert("Super User canot delete Account");
          this.props.navigate(-1);
        }
      })
    }
    else
    {
      this.setState({
        isData: false,
      })
    }

    
  }

  SelectReason(event) {
    // console.log(event.target.text);
    let reason = this.state.reasonDetails
    reason.reason = event.target.value;
     if (event.target.value == 0) {
      this.setState({
        isOtherReason: true,
      })
     }
     else
     {
      this.setState({
        isOtherReason: false,
        reasonDetails: reason,
      })
     }
  }

  onChange(e) {
    let reason = this.state.reasonDetails;
    reason.reason = e.target.value;
    this.setState({
      reasonDetails: reason
    })
  }

  SubmitDeleteRequest() {
    let data = {};
    data.userId = this.state.UserDetails.userId;

    let objReason= this.state.reasonDetails;
    if (objReason.reasonId==0 && objReason.reason == "") {
      alert("Please enter your reason or Select from list");
      return;
    }
  
    //Delete API...
    DeleteProfileRequest(data).then((response) => {
      // console.log(response);
      if (response.data.status) {
        this.setState({
          IsOTPSUbmitted: true,
          show: true,
          mobile : response.data.mobile,
        });
      }
      else if(!response.data.status && response.data.responseMessage == "Can't Delete This Account")
      {
        alert(response.data.responseMessage); 
      }
    })
  }

  DeletionOTPSubmit() {
    let data = this.state.reasonDetails;

    //Delete OTP - Final API...
    DeleteProfileOTPSubmission(data).then((response) => {
      // console.log(response);
      if (response.data.status) {
        this.setState({
          show: false,
          show1: true
        });

        localStorage.removeItem("TM_UDetails");
        localStorage.removeItem("tokenValue");
        localStorage.removeItem("onewaySearchResult");

      }

    })
  }
  setOTP(e) {
    let data = this.state.reasonDetails;
    data.otp = e.target.value
    this.setState({
      reasonDetails: data
    })
  }

  handleKeyDown = (event, airportListLength) => {

    if (event.key === "Enter") {
      this.DeletionOTPSubmit();
    }
  };

  RedirectToHome() {
    // this.props.navigate('/');
    window.location.reload();
  }

  handleClose = (i) => {
    if (i == 1)
      this.setState({ show: false });
    else
      this.setState({ show1: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };
  render() {
    const { show, show1 } = this.state;
    return (this.state.isData) ?  (
      <div>
        <div className="container position-relative pt-5">
          <div className="col-md-4 offset-md-4 center-screen">
            <h4 className="text-center">Account Deletion</h4>
            <h5>Sorry to see you go!</h5>
            <p>We regret that you have decided to remove your Alhind account. We comply with your request to have all information related to your account deleted.</p>
            <p>Please choose one of the reason from below</p>
            <select className="form-control mb-3" onChange={(event) => this.SelectReason(event)}>
              {
                this.state.ReasonList.map((item, index) =>
                  <option value={item.reason}>{item.reason}</option>
                )
              }
              <option value="0">Other</option>
            </select>
            {this.state.isOtherReason && <textarea disabled={!this.state.isOtherReason} className="form-control mb-3" placeholder="Others"
              onChange={(event) => { this.onChange(event) }}
            ></textarea>}
            <button className="btn btn-primary full-width mb-3" onClick={this.SubmitDeleteRequest}>SUBMIT</button>



            <Modal show={show} onHide={() => this.handleClose(1)}>
              <div className="login-form">
                <Modal.Header closeButton>
                  <Modal.Title>Enter OTP</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p className="mb-3 text-center">An otp has been sent to : <span>{this.state.mobile}</span>

                  </p>
                  <div className="did-floating-label-content">

                    <input
                      autoFocus
                      type="text"
                      name='otp'
                      required=""
                      spellCheck="false" placeholder=" "
                      className="did-floating-input form-control"
                      autoComplete='off'
                      onChange={(event) => this.setOTP(event)}
                      onKeyDown={(e) => {
                        this.handleKeyDown(e);
                      }}
                    />

                    <label className="did-floating-label">Enter OTP</label>

                  </div>
                </Modal.Body>
                <Modal.Footer>

                  <Button variant="primary" onClick={this.DeletionOTPSubmit}>
                    SUBMIT
                  </Button>
                </Modal.Footer>
              </div>
            </Modal>



            {/* Delete Confirmation... */}
            <Modal show={show1} onHide={() => this.handleClose(2)}>
              <div className="login-form">
                <Modal.Header closeButton>
                  <Modal.Title>Enter OTP</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                  <p className="mb-3 text-center">Account Deleted Successfully</p>

                </Modal.Body>
                <Modal.Footer>

                  <Button variant="primary" onClick={this.RedirectToHome}>
                    SUBMIT
                  </Button>
                </Modal.Footer>
              </div>
            </Modal>


          </div>
        </div>
      </div>
    ): (
      <div>
<div className="container">
  <div className="row">
  <div className="col-lg-6 col-md-6 text-center pt-5 offset-lg-3">
<div className="delete-box">

<h2 class="mb-2 mx-2 cs-title mt-3">Please login to continue</h2>
  <p>
    In order to proceed, Please login to your account!
  </p>
</div>

  </div>
  </div>
  
  
</div>


      </div>
    )
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <Delete {...props} navigate={navigate} />
}

export default WithNavigate


