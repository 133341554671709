import React, { Component } from 'react'
import { useNavigate, Link, json } from "react-router-dom";
import { GetAllHajjPackages } from "../../Services/HajjPackageServices";

class Umrah extends Component {

  constructor(props) {
    super(props);
    this.state = {
      HajjPackageList: [],

    };
  }

  componentDidMount() {
    this.getHajjPackageList();
  }

  gotoItineraryPage = (id, deptId) => {
    console.log(id);
    console.log(deptId);
    this.props.navigate(`/UmrahItinerary/${id}/${deptId}`);
  };

  getHajjPackageList() {
    GetAllHajjPackages().then((response) => {
      if (response.data.status) {
        this.setState({
          HajjPackageList: response.data.topPackageList
        });
      }
    });
  }

  render() {
    const { HajjPackageList } = this.state;

    return (
      <div>
        <section className="page-header">
          <div
            className="page-header__bg"
            style={{ backgroundImage: 'url("assets/img/umrah-banner.jpg")' }}
          />
          <div className="container">
            <h2 className="page-header__title">Umrah Packages</h2>

          </div>
        </section>
        <section className="blog-page section-space">
          <div className="container">
            <div className="row gutter-y-30">

              {HajjPackageList.length > 0 && HajjPackageList.map((item, index) => (
                <div className="col-md-6 col-lg-4">
                  <div className="blog-card wow fadeInUp">
                    <a onClick={() => this.gotoItineraryPage(item.packageId, item.deptId)} className="blog-card__image">
                      <img src={item.image ? item.image+`?timestamp=${new Date().getTime()}` : `${process.env.PUBLIC_URL}/assets/img/hajjDefault.jpg`} alt="Package" />
                      <div className="blog-five__card__date">
                        <span>{item.packageDays - 1}</span>
                        Night
                      </div>
                      <div className="blog-five__card__date2">
                        <span>{item.packageDays}</span>
                        Days
                      </div>
                      {item.departureDate && (
                        <div className="blog-five__card__date3">
                          <span>{item.departureDate}</span>
                        </div>
                      )}

                    </a>
                    <div
                      className="blog-card__content"
                      style={{
                        backgroundImage: 'url("assets/img/blog-bg-1-1.png")'
                      }}
                    >
                      <h3 className="blog-card__title">
                        <a onClick={() => this.gotoItineraryPage(item.packageId, item.deptId)}>{item.packageName}</a>
                      </h3>
                      {/* <Link to={`/UmrahItinerary/${item.packageId}`}>Go to Details</Link> */}
                      <a onClick={() => this.gotoItineraryPage(item.packageId, item.deptId)} className="blog-card__link">
                        <span className="blog-card__link__front">
                          <span className="icon-duble-arrow" />
                          <svg
                            fill="#ffffff"
                            height="15px"
                            width="15px"
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 512.094 512.094"
                            xmlSpace="preserve"
                            stroke="#ffffff"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <path d="M263.641,248.4L18.308,3.067c-4.16-4.053-10.987-3.947-15.04,0.32c-3.947,4.16-3.947,10.667,0,14.827l237.76,237.76 L3.268,493.733c-4.267,4.053-4.373,10.88-0.213,15.04c4.16,4.16,10.88,4.373,15.04,0.213c0.107-0.107,0.213-0.213,0.213-0.213 L263.641,263.44C267.801,259.28,267.801,252.56,263.641,248.4z" />{" "}
                                    <path d="M508.974,248.4L263.641,3.067c-4.267-4.053-10.987-3.947-15.04,0.213c-3.947,4.16-3.947,10.667,0,14.827l237.76,237.76 l-237.76,237.867c-4.267,4.053-4.373,10.88-0.213,15.04c4.16,4.16,10.88,4.373,15.04,0.213c0.107-0.107,0.213-0.213,0.213-0.213 L508.974,263.44C513.135,259.28,513.135,252.56,508.974,248.4z" />{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>
                          </svg>
                        </span>
                        <span className="blog-card__link__back">
                          <span />

                          View Package
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              ))}




            </div>
          </div>
        </section>


      </div>
    )
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <Umrah {...props} navigate={navigate} />;
}

export default WithNavigate;
