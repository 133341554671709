import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import dateFormat, { masks } from "dateformat";
import { useState, useEffect } from "react";
import { GetOneWaySearch } from "../../Services/OneWaySearch";
import { GetFlightDetails } from "../../Services/FlightDetails";
import DatePicker from "react-datepicker";
// import AirportJson from '../../appData/airportlist.json';
import { useNavigate, Link, json } from 'react-router-dom'
import { AES, enc } from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import { GetNonUserToken } from "../../Services/NonUser"
import { ToastContainer, toast } from 'react-toastify';
import { CheckNetworkStatus } from '../../Utilities/Validations';
import { GetAirportList } from "../../Services/UtilitiesServices";
import ModifySearchPopUp from "./ModifySearchPopUp";
import { GetPricingDetails } from "../../Services/FlightDetails"
import ReactSlider from 'react-slider'
import {URL_EncodeKey} from "../../Services/EncryptionService"
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Modal from 'react-bootstrap/Modal';
import Slider from "react-slick";


class SearchResultOnewayTrip extends Component {
  OnewayDestination="";
  RoundTripDestination="";
  //isMobile=false;
  constructor(props) {
    super(props);
    this.targetTextBoxRef = React.createRef();
    this.datePickerRef = React.createRef();
    this.targetTextBoxRef2 = React.createRef();
    this.datePickerRef2 = React.createRef();
    this.autoSuggestResultRef = React.createRef();
    this.autoSuggestResultRef2 = React.createRef();
    this.autoSuggestResultRef3 = React.createRef();
    this.autoSuggestResultRef4 = React.createRef();
    this.state = {
      show: false,
      isOnline: navigator.onLine,
      AirportJson : [],
      selectedIndex: -1,
      selectedIndex2: -1,
      selectedIndex3: -1,
      selectedIndex4: -1,
      originError: false,
      destinationError: false,
      originErrorR: false,
      destinationErrorR: false,
      RequestFields:[],
      selectedFareId: null,
      selectedNetAmount: null,
      selectedAmount: null,
      OnewayFullSearchResult: [],
      LowerFareResult: [],
      //ItinSearchResult: [],

      AvlAirlineResult: [],
      RequestBody: {},
      isLoaded: false,
      isResultFound: false,
      isFadeOut:true,
      FlightDetailsResult: [],
      objitinResult: [],
      objseglistResult: [],
      itinId: 0,
      fareId: 0,
      providerCode: "",
      itinIdR: 0,
      fareIdR: 0,
      providerCodeR: "",
      RequestBody: {},
      originSectorRound: {},
      destinationSectorRound: {},

      originSectorOneway: [],

      RoundTripFullSearchResult: [],
      LowerFareResult: [],
      ItinSearchResult: [],
      AvlAirlineResult: [],
      ItinSearchResultR: [],

      ResponseBodyCopy: {},
      onewayDepartureDate: new Date(),

      //Filter
      ResultFIlter: {
        steps: []
      },
      Airlines: [],
      AirlineCheckbox : [],
      ItinSearchResultCopy: [],
      //priceRange: 0,
      priceRangeMin: 0,
      priceRangeMax: 0,
      DepartureTimes: [],
      DepartureTimesCheckbox: [],
      ArrivalTimes: [],
      ArrivalTimesCheckbox: [],
      Stops: [],
      StopsCheckbox : [],

      isFastestActive:false,
      isCheapestActive:false,
      isEarlierActive:false,


      //Autocomplete...
      value: '',
      suggestions: [],
      OnewayOriginSearchTerm: '',
      OnewayDestinationSearchTerm: '',
      OnewayOriginSearchResults: [],
      OnewayDestinationSearchResults: [],
      OnewayOriginAirport: [],
      OnewayDestinationAirport: [],

      //RoundTrip
      RoundTripOriginSearchTerm: '',
      RoundTripDestinationSearchTerm: '',
      RoundTripOriginSearchResults: [],
      RoundTripDestinationSearchResults: [],
      RoundTripOriginAirport: [],
      RoundTripDestinationAirport: [],


      RoundTripOnwardDate:new Date(),
      RoundTripReturnDate:new Date(),
      Today:new Date(),

      MoreFareDefaultSelectionIndex:0,
      value: 0,
      max: 100,
      speed: 50, // milliseconds between each step
      settings : {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024, // Tablets
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 600, // Mobile devices
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      }
    };
    this.intervalId = null;
    this.secondInputRef = React.createRef();
    this.swapOriginAndDestination = this.swapOriginAndDestination.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    //this.swapOriginAndDestinationRoundTrip = this.swapOriginAndDestinationRoundTrip.bind(this);

  }
  handleFlightDetailsClose = () => {
    this.setState({ show: false });
  };

  handleFlightDetailsShow = () => {
    this.setState({ show: true });
  };
  resetFilter()
  {
    // console.log("asdasddaasasd");
    // console.log(this.state.ArrivalTimesCheckbox);
    //Clear Airline Filter...
    this.setState({
      Airlines : [],
      Stops: [],
      DepartureTimes: [],
      ArrivalTimes: [],

      priceRangeMin: this.state.OnewayFullSearchResult.minimumFare,
      priceRangeMax: this.state.OnewayFullSearchResult.maximumFare,

      AirlineCheckbox : [],
      StopsCheckbox : [],
      DepartureTimesCheckbox : [],
      ArrivalTimesCheckbox : [],

    });

    if(this.state.isFastestActive == true ||this.state.isCheapestActive == true ||this.state.isEarlierActive == true )
    {
      this.setState({
        ItinSearchResult : this.state.ItinSearchResultCopy,
        isFastestActive:false,
        isCheapestActive:false,
        isEarlierActive:false,
      });
    }

    //Display selection Removes...

  }

  //AutoComplete fn...
  handleSearch = (event) => {
    let airportsTemp = this.state.AirportJson;

    const { value } = event.target;
    if (event.target.name == "onewayOrigin") {
      this.setState({ OnewayOriginSearchTerm: value });
      this.OnewayOrigin = "";
    }
    else if (event.target.name == "onewayDestination") {
      this.setState({ OnewayDestinationSearchTerm: value });
      this.OnewayDestination = ""
    }
    else if (event.target.name == "RoundTripOrigin") {
      this.setState({ RoundTripOriginSearchTerm: value });
      this.RoundTripOrigin = ""
    }
    else if (event.target.name == "RoundTripDestination") {
      this.setState({ RoundTripDestinationSearchTerm: value });
      this.RoundTripDestination = ""
    }


    if (value.length >= 2) {
      let index= -1;
      let SortedAirportArray=[];
      const matchingAirports = airportsTemp.filter(
        (airport) =>
        airport.code.toLowerCase().includes(value.toLowerCase()) ||
        airport.cityCode.toLowerCase().includes(value.toLowerCase()) ||
        airport.airportName.toLowerCase().includes(value.toLowerCase()) ||
        airport.countryCode.toLowerCase().includes(value.toLowerCase()) ||
        airport.cityName.toLowerCase().includes(value.toLowerCase())
      );

                // selected code to top of the Array.apply..
                for(let i=0; i<matchingAirports.length;i++)
                {
                  if(matchingAirports[i].code.toLowerCase() == value.toLowerCase())
                  {
                      index=i;  
                      break;
                                       
                  } 
                  else if(matchingAirports[i].code.toLowerCase() === matchingAirports[i].cityCode.toLowerCase())
                  {
                      index=i; 
                  }                  
                }

                if(index >= 0)
                {
                    SortedAirportArray.push(matchingAirports[index]);
                    for(let j=0;j < matchingAirports.length; j++)
                    {
                        if(index != j)
                        {
                            SortedAirportArray.push(matchingAirports[j]);
                        }
                    }
                }
                else
                {
                    SortedAirportArray=[];
                    SortedAirportArray=matchingAirports;
                }                

                if(SortedAirportArray.length === 1) 
                {
                    if (event.target.name == "onewayOrigin"){
                    this.handleSelectAirport(SortedAirportArray[0].code, SortedAirportArray[0].airportName, "onewayOrigin", SortedAirportArray[0], SortedAirportArray[0].countryCode, SortedAirportArray[0].cityName);
                    this.targetTextBoxRef.current.focus();
                 
                    if (this.OnewayDestination == this.OnewayOrigin) 
                    {
                        this.setState({ SameAirportsError: true});
                        return;
                    }
                    else
                    {
                        this.targetTextBoxRef.current.focus();
                        this.setState({ 
                            originError: false,
                            SameAirportsError: false
                        });
                        return
                    }
                    }
                    else if (event.target.name == "onewayDestination"){
                        this.handleSelectAirport(SortedAirportArray[0].code, SortedAirportArray[0].airportName, "onewayDestination", SortedAirportArray[0], SortedAirportArray[0].countryCode, SortedAirportArray[0].cityName);
                        
                          if (this.OnewayDestination == this.OnewayOrigin) 
                          {
                            this.setState({ SameAirportsError: true});
                            return;
                          }
                          else
                          {
                            if (this.datePickerRef && this.datePickerRef.current && this.datePickerRef.current.input) {
                                this.datePickerRef.current.input.focus();
                                this.setState({ 
                                    destinationError: false,
                                    SameAirportsError: false
                                });
                              }
                            return
                          }
                    }
                    else if (event.target.name == "RoundTripOrigin"){
                        this.handleSelectAirport(SortedAirportArray[0].code, SortedAirportArray[0].airportName, "RoundTripOrigin", SortedAirportArray[0], SortedAirportArray[0].countryCode, SortedAirportArray[0].cityName);
                        this.targetTextBoxRef2.current.focus();
                      
                        if (this.RoundTripOrigin == this.RoundTripDestination) 
                        {
                        this.setState({ SameAirportsError: true});
                        return;
                        }
                        else
                        {
                            this.targetTextBoxRef2.current.focus();
                            this.setState({ 
                                originErrorR: false,
                                SameAirportsError: false
                            });
                            return
                        }
                    }
                    else if (event.target.name == "RoundTripDestination"){
                            this.handleSelectAirport(SortedAirportArray[0].code, SortedAirportArray[0].airportName, "RoundTripDestination", SortedAirportArray[0], SortedAirportArray[0].countryCode, SortedAirportArray[0].cityName);

                              if (this.RoundTripOrigin == this.RoundTripDestination) 
                              {
                                this.setState({ SameAirportsError: true});
                                return;
                              }
                              else
                              {
                                if (this.datePickerRef2 && this.datePickerRef2.current && this.datePickerRef2.current.input) {
                                    this.datePickerRef2.current.input.focus();
                                    this.setState({ 
                                        destinationErrorR: false,
                                        SameAirportsError: false
                                     });
                                  }
                                return
                              }     
                    }
                }
                else
                {
                    if (event.target.name == "onewayOrigin"){
                    this.setState({ originError: true});
                    }
                    else if (event.target.name == "onewayDestination"){
                    this.setState({ destinationError: true});
                    }
                    else if (event.target.name == "RoundTripOrigin"){
                    this.setState({ originErrorR: true});
                    }
                    else if (event.target.name == "RoundTripDestination"){
                    this.setState({ destinationErrorR: true});
                    }
                }   
  
                
      if (event.target.name == "onewayOrigin") {
        this.setState({ OnewayOriginSearchResults: SortedAirportArray });
      }
      else if (event.target.name == "onewayDestination") {

        this.setState({ OnewayDestinationSearchResults: SortedAirportArray });
      }
      else if (event.target.name == "RoundTripOrigin") {
      
        this.setState({ RoundTripOriginSearchResults: SortedAirportArray });
      }
      else if (event.target.name == "RoundTripDestination") {
       
        this.setState({ RoundTripDestinationSearchResults: SortedAirportArray });
      }
    }
    else if(event.target.value == "")   //IF nothing in the textbox..
    {
        if (event.target.name == "onewayOrigin" || event.target.name == "RoundTripOrigin") {
            this.OnewayOrigin = "";
            this.RoundTripOrigin = "";

            this.setState({ 
                SameAirportsError : false,
                OnewayOriginSearchTerm :  "",
                RoundTripOriginSearchTerm : "",
                OnewayOriginAirport : {},
                RoundTripOriginAirport : {},
            });

        }
        else if (event.target.name == "onewayDestination" || event.target.name == "RoundTripDestination") {
            this.OnewayDestination = "";
            this.RoundTripDestination = "";

            this.setState({ 
                SameAirportsError : false,
                OnewayDestinationSearchTerm : "",
                RoundTripDestinationSearchTerm : "",
                OnewayDestinationAirport : {},
                RoundTripDestinationAirport : {},
            });
        }

       
    }
    else {
      if (event.target.name == "onewayOrigin") {
        this.setState({ OnewayOriginSearchResults: [] });
      }
      else if (event.target.name == "onewayDestination") {
        this.setState({ OnewayDestinationSearchResults: [] });
      }
      else if (event.target.name == "RoundTripOrigin") {
        this.setState({ RoundTripOriginSearchResults: [] });
      }
      else if (event.target.name == "RoundTripDestination") {
        this.setState({ RoundTripDestinationSearchResults: [] });
      }
    }
  };
  handleSelectAirport = (code, name, type, airportDetails) => {
    const selectedAirport = `${code}-${airportDetails.cityName}-${name}-${airportDetails.countryCode}`;
    if (type == "onewayOrigin") {
      this.OnewayOrigin = code;
      this.setState({        
        OnewayOriginSearchTerm: selectedAirport,
        OnewayOriginSearchResults: [],
        OnewayOriginAirport: airportDetails,
        originError: false,
        selectedIndex: -1,

        RoundTripOriginSearchTerm: selectedAirport,
        RoundTripOriginSearchResults: [],
        RoundTripOriginAirport: airportDetails,
        originErrorR: false,
        selectedIndex3: -1,
        OnewayOriginSelected : true,

      }, () => {
        this.scrollSelectedIntoView();
      });

    }
    else if (type == "onewayDestination") {
      this.OnewayDestination=code;
      this.RoundTripDestination=selectedAirport;
      this.setState({
        OnewayDestinationSearchTerm: selectedAirport,
        OnewayDestinationSearchResults: [],
        OnewayDestinationAirport: airportDetails,
        destinationError: false,
        selectedIndex2: -1,

        RoundTripDestinationSearchTerm: selectedAirport,
        RoundTripDestinationSearchResults: [],
        RoundTripDestinationAirport: airportDetails,
        destinationErrorR: false,
        selectedIndex4: -1,
        OnewayDestinationSelected : true,
      }, () => {
        this.scrollSelectedIntoView();
      });

    }
    else if (type == "RoundTripOrigin") {
     
      this.RoundTripOrigin = code;
      this.setState({
        RoundTripOriginSearchTerm: selectedAirport,
        RoundTripOriginSearchResults: [],
        RoundTripOriginAirport: airportDetails,
        originErrorR: false,
        selectedIndex3: -1,

        OnewayOriginSearchTerm: selectedAirport,
        OnewayOriginSearchResults: [],
        OnewayOriginAirport: airportDetails,
        originError: false,
        selectedIndex: -1,
        RoundTripOriginSelected : true,
      }, () => {
        this.scrollSelectedIntoView();
      });

    }
    else if (type == "RoundTripDestination") {
     
      this.RoundTripDestination = code;
      // this.RoundTripDestination=selectedAirport;
      this.setState({
        RoundTripDestinationSearchTerm: selectedAirport,
        RoundTripDestinationSearchResults: [],
        RoundTripDestinationAirport: airportDetails,
        destinationErrorR: false,
        selectedIndex4: -1,

        OnewayDestinationSearchTerm: selectedAirport,
        OnewayDestinationSearchResults: [],
        OnewayDestinationAirport: airportDetails,
        destinationError: false,
        selectedIndex2: -1,
        RoundTripDestinationSelected : true,
      }, () => {
        this.scrollSelectedIntoView();
      });

    }
    // this.render();
    // this.inputSearchSectorChangeFocusOut(type)

       //If same Airport...
       if(type == "onewayOrigin" || type == "onewayDestination")
       {
        if(this.OnewayDestination == this.OnewayOrigin){
          this.setState({
              SameAirportsError: true
          })
          }
          else{
              this.setState({
                  SameAirportsError: false
              })
          }
       }
       else{
        if(this.RoundTripDestination == this.RoundTripOrigin){
          this.setState({
              SameAirportsError: true
          })
          }
          else{
              this.setState({
                  SameAirportsError: false
              })
          }
    
       }

  };

  changeAirportListShowStatus(FieldName){
    if(FieldName == "Destination")
    {
        this.setState({
            OnewayOriginSearchResults:[],
            RoundTripOriginSearchResults:[],
        });

        //IF no-airport selected THEN clear the field...
        if(this.OnewayOrigin == "")
        {
            this.setState({
                OnewayOriginSearchTerm : "",
            })
        }

        if(this.RoundTripOrigin == "")
        {
            this.setState({
                RoundTripOriginSearchTerm : "",
            })
        }
    }
    else
    {
        this.setState({
            OnewayDestinationSearchResults:[],
            RoundTripDestinationSearchResults:[],            
        });

        //IF no-airport selected THEN clear the field...
        if(this.OnewayDestination == "")
        {
            this.setState({
                OnewayDestinationSearchTerm : "",
            })
        }


        if(this.RoundTripDestination == "")
        {
            this.setState({
                RoundTripDestinationSearchTerm : "",
            })
        }
    }
}

  // setFadeOutStyle()
  // {
  //   // document.body.style.overflow = "auto";
  //   // document.body.style.overflow = "auto";
  //   // document.body.style.overflow = "auto";
  //   // document.body.style.overflow = "auto";
  // //   this.setState({
  // //    //isFadeOut:false,
  // //   })
  // }

  handleKeyDown = (event, airportListLength) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();
      this.handleArrowUp();
    } else if (event.key === "ArrowDown") {
      event.preventDefault();
      this.handleArrowDown();
    }
    else if (event.key === "Enter" && airportListLength > 0) {
    event.preventDefault();
    this.handleEnterKey(event);
    }
  };

  handleArrowUp = () => {
    const { selectedIndex,selectedIndex2,selectedIndex3,selectedIndex4, OnewayOriginSearchResults,OnewayDestinationSearchResults,RoundTripOriginSearchResults,RoundTripDestinationSearchResults } = this.state;
    const newIndex = selectedIndex > 0 ? selectedIndex - 1 : OnewayOriginSearchResults.length - 1; 
    const newIndex2 = selectedIndex2 > 0 ? selectedIndex2 - 1 : OnewayDestinationSearchResults.length - 1;
    const newIndex3 = selectedIndex3 > 0 ? selectedIndex3 - 1 : RoundTripOriginSearchResults.length - 1;
    const newIndex4 = selectedIndex4 > 0 ? selectedIndex4 - 1 : RoundTripDestinationSearchResults.length - 1;
    this.setState({ selectedIndex: newIndex,selectedIndex2: newIndex2,selectedIndex3: newIndex3,selectedIndex4: newIndex4 }, () => {
      this.scrollSelectedIntoView();
    });
  };

  handleArrowDown = () => {
    const { selectedIndex,selectedIndex2,selectedIndex3,selectedIndex4, OnewayOriginSearchResults,OnewayDestinationSearchResults,RoundTripOriginSearchResults,RoundTripDestinationSearchResults } = this.state;
    const newIndex = selectedIndex < OnewayOriginSearchResults.length - 1 ? selectedIndex + 1 : 0;
    const newIndex2 = selectedIndex2 < OnewayDestinationSearchResults.length - 1 ? selectedIndex2 + 1 : 0;
    const newIndex3 = selectedIndex3 < RoundTripOriginSearchResults.length - 1 ? selectedIndex3 + 1 : 0;
    const newIndex4 = selectedIndex4 < RoundTripDestinationSearchResults.length - 1 ? selectedIndex4 + 1 : 0;

    this.setState({ selectedIndex: newIndex,selectedIndex2: newIndex2,selectedIndex3: newIndex3,selectedIndex4: newIndex4 }, () => {
      this.scrollSelectedIntoView();
    });
  };

  scrollSelectedIntoView = () => {
    const { selectedIndex,selectedIndex2,selectedIndex3,selectedIndex4 } = this.state;
    if (this.autoSuggestResultRef.current) {
      const selectedElement = this.autoSuggestResultRef.current.querySelector(`[data-index="${selectedIndex}"]`);
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.autoSuggestResultRef2.current) {
      const selectedElement2 = this.autoSuggestResultRef2.current.querySelector(`[data-index="${selectedIndex2}"]`);
      if (selectedElement2) {
        selectedElement2.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.autoSuggestResultRef3.current) {
      const selectedElement3 = this.autoSuggestResultRef3.current.querySelector(`[data-index="${selectedIndex3}"]`);
      if (selectedElement3) {
        selectedElement3.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    if (this.autoSuggestResultRef4.current) {
      const selectedElement4 = this.autoSuggestResultRef4.current.querySelector(`[data-index="${selectedIndex4}"]`);
      if (selectedElement4) {
        selectedElement4.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  };

 formattedDate = (date) => {
    const options = { day: '2-digit', month: 'short' };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  handleEnterKey = (event) => {
    const { selectedIndex,selectedIndex2,selectedIndex3,selectedIndex4, OnewayOriginSearchResults,OnewayDestinationSearchResults,RoundTripOriginSearchResults,RoundTripDestinationSearchResults } = this.state;
    let defaultIndex=selectedIndex;
    let defaultIndex2=selectedIndex2;
    let defaultIndex3=selectedIndex3;
    let defaultIndex4=selectedIndex4;
    if (defaultIndex >= -1 && defaultIndex < OnewayOriginSearchResults.length) {
      if(defaultIndex == -1)
            
             defaultIndex = 0;
      const selectedAirport = OnewayOriginSearchResults[defaultIndex];
      if (event.target.name == "onewayOrigin"){
      this.handleSelectAirport(
        selectedAirport.code,
        selectedAirport.airportName,
        "onewayOrigin",
        selectedAirport,
        selectedAirport.countryCode,
        selectedAirport.cityName
      );
      this.targetTextBoxRef.current.focus();
      this.setState({ originError: false});
      return
      }
    }
    if (defaultIndex2 >= -1 && defaultIndex2 < OnewayDestinationSearchResults.length) {
      if(defaultIndex2 == -1)
            
            defaultIndex2 = 0;
        const selectedAirport = OnewayDestinationSearchResults[defaultIndex2];
      if (event.target.name == "onewayDestination"){
        this.handleSelectAirport(
            selectedAirport.code,
            selectedAirport.airportName,
            "onewayDestination",
            selectedAirport,
            selectedAirport.countryCode,
            selectedAirport.cityName
          );
          const {
            OnewayOriginSearchTerm,
          } = this.state;

          if (this.OnewayDestination == this.OnewayOrigin) 
          {
            this.setState({ SameAirportsError: true});
            return;
          }
          else
          {
            this.setState({ SameAirportsError: false});
            if (this.datePickerRef && this.datePickerRef.current && this.datePickerRef.current.input) {
              this.datePickerRef.current.input.focus();
              this.setState({ 
                destinationError: false,
                SameAirportsError: false
              });
            }
            return 
          } 

         

      }
      
    }
    if (defaultIndex3 >= -1 && defaultIndex3 < RoundTripOriginSearchResults.length) {
      if(defaultIndex3 == -1)
            
            defaultIndex3 = 0;
        const selectedAirport = RoundTripOriginSearchResults[defaultIndex3];
       if (event.target.name == "RoundTripOrigin"){
        this.handleSelectAirport(
            selectedAirport.code,
            selectedAirport.airportName,
            "RoundTripOrigin",
            selectedAirport,
            selectedAirport.countryCode,
            selectedAirport.cityName
          );
          this.targetTextBoxRef2.current.focus();
          this.setState({ originErrorR: false});
          return
      }
    }
    if (defaultIndex4 >= -1 && defaultIndex4 < RoundTripDestinationSearchResults.length) {
      if(defaultIndex4 == -1)
            
            defaultIndex4 = 0;
        const selectedAirport = RoundTripDestinationSearchResults[defaultIndex4];
       if (event.target.name == "RoundTripDestination"){
        this.handleSelectAirport(
            selectedAirport.code,
            selectedAirport.airportName,
            "RoundTripDestination",
            selectedAirport,
            selectedAirport.countryCode,
            selectedAirport.cityName
          );
          const {
            RoundTripOriginSearchTerm,
          } = this.state;

          if (this.RoundTripDestination == RoundTripOriginSearchTerm) 
          {
            this.setState({ SameAirportsError: true});
            return;
          }
          else
          {
            if (this.datePickerRef2 && this.datePickerRef2.current && this.datePickerRef2.current.input) {
              this.datePickerRef2.current.input.focus();
              this.setState({ destinationErrorR: false});
            }
            return
          }
      }
    }

    //Reset same Airport Error...
    if (this.RoundTripOrigin == this.RoundTripDestination) 
      {
      
        this.setState({ SameAirportsError: true});
        return;
      }
      else
      {
      
        this.setState({ SameAirportsError: false});
        if (this.datePickerRef && this.datePickerRef.current && this.datePickerRef.current.input) {
          this.datePickerRef.current.input.focus();
          this.setState({ 
            destinationError: false,
            SameAirportsError: false
          });
        }
        return 
      }
    
  };

  ResetFilter(){

    this.setState({
      Airlines: [],
      priceRangeMin: 0,
      priceRangeMax: 0,
      Stops: [],
      DepartureTimes: [],
      ArrivalTimes: [],
      });

  }


  componentDidMount() {
    this.intervalId = setInterval(this.moveSlider, this.state.speed);
    //this.isMobile=true;

    window.scrollTo(0, 0);
    window.addEventListener("online", this.onlineHandler);
    window.addEventListener("offline", this.offlineHandler);
    //window.addEventListener('offline', this.handleOffline);
    //its required when fade effect remove class used...
    // document.body.classList.add('modal-backdrop');
    // document.body.style.overflow = "auto";    
  // this.ResetFilter();
    this.setState({
      isLoaded:false,
    })
  const params = new URLSearchParams(window.location.search);
  const onewaySearchRequest = params.get('onewaySearchRequest');


    //this.requestBody = JSON.parse(localStorage.getItem("onewaySearchResult"));
    this.requestBody = JSON.parse(onewaySearchRequest);
    // console.log(this.requestBody);
   
    this.setState({
      RequestBody:this.requestBody
    })   
    //this.setRequestBody(JSON.parse(localStorage.getItem("onewaySearchResult")));

    // console.log(JSON.parse(onewaySearchRequest));

    this.setRequestBody(JSON.parse(onewaySearchRequest));
    
    //SetOriginDestinationAirportDetails...
    this.setAirportDetailsFromRequestBody();
    // this.getSearchResult();

    try{
      this.setState({  RequestBody: this.requestBody }, () => {
          this.getSearchResult();  
      })
    }catch(error){
          if(error.response.data.responseMessage=="InvalidToken")
          {           
            this.props.navigate('/');            
          }
          toast.success('Something went wrong...', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        };

            // //Get All Airports...
            // this.getAllAirports();

  }

  componentWillUnmount() {
    window.removeEventListener("online", this.onlineHandler);
    window.removeEventListener("offline", this.offlineHandler);
    clearInterval(this.intervalId);
  }

  onlineHandler = () => {
    this.setState({ isOnline: true });
  };

  offlineHandler = () => {
    this.setState({ isOnline: false });
    // Handle offline event and redirect to the '/issue' page
    toast.error('No Internet Connection...', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

    // Redirect to the '/issue' page
    this.props.navigate('/issue');
  };

  // componentWillUnmount() {
  //   // Remove event listeners when component unmounts
  //   window.removeEventListener('offline', this.handleOffline);
  // }

  // handleOffline = () => {
  //   // Handle offline event and redirect to the '/issue' page
  //   toast.error('No Internet Connection...', {
  //     position: 'top-right',
  //     autoClose: 3000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: 'colored',
  //   });

  //   // Redirect to the '/issue' page
  //   this.props.navigate('/issue');
  // }



  sleep = ms => {  
    return new Promise(resolve => setTimeout(resolve, ms));  
    }; 

  swapOriginAndDestination()
  {
      let tempData;
      tempData=this.state.OnewayOriginAirport;
      let origin=this.state.OnewayOriginSearchTerm;

      this.setState({
          OnewayOriginAirport:this.state.OnewayDestinationAirport,
          OnewayDestinationAirport:tempData,

          OnewayOriginSearchTerm:this.state.OnewayDestinationSearchTerm,
          OnewayDestinationSearchTerm:origin
      })

      //Round
      let tempData1;
      tempData1=this.state.RoundTripOriginAirport;
      let origin1=this.state.RoundTripOriginSearchTerm;

      this.setState({
          RoundTripOriginAirport:this.state.RoundTripDestinationAirport,
          RoundTripDestinationAirport:tempData1,

          RoundTripOriginSearchTerm:this.state.RoundTripDestinationSearchTerm,
          RoundTripDestinationSearchTerm:origin1
      })
      
  }


  // swapOriginAndDestinationRoundTrip()
  // {
  //     let tempData;
  //     tempData=this.state.RoundTripOriginAirport;
  

  //     let origin=this.state.RoundTripOriginSearchTerm;

  //     this.setState({
  //         RoundTripOriginAirport:this.state.RoundTripDestinationAirport,
  //         RoundTripDestinationAirport:tempData,

  //         RoundTripOriginSearchTerm:this.state.RoundTripDestinationSearchTerm,
  //         RoundTripDestinationSearchTerm:origin
  //     })
  // }

  //getAirportDetialsFormRequest...
  setAirportDetailsFromRequestBody()
  {
    let dataOnward={}, dataReturn={};
    const params = new URLSearchParams(window.location.search);
    const data = params.get('onewaySearchRequest');
    let requestDummyOnward=JSON.parse(data).objSectorList[0];
    //let requestDummyReturn=JSON.parse(localStorage.getItem("onewaySearchResult")).objsectorlist[1];

   
    this.OnewayOrigin = requestDummyOnward.origin;
    this.RoundTripOrigin = requestDummyOnward.origin;
    dataOnward.cityCode=requestDummyOnward.origin;
    dataOnward.countryCode=requestDummyOnward.origincountry;

    this.OnewayDestination=requestDummyOnward.destination;
    this.RoundTripDestination = requestDummyOnward.destination;
    dataReturn.cityCode=requestDummyOnward.destination;
    dataReturn.countryCode=requestDummyOnward.destinationcountry;

    let date={};
    date.departureDate=dateFormat(requestDummyOnward.departureDate, "yyyy-mm-dd")

    this.setState({
      OnewayOriginAirport:dataOnward,
      OnewayDestinationAirport:dataReturn,

      RoundTripOriginAirport:dataOnward,
      RoundTripDestinationAirport:dataReturn,
      originSectorRound:date,
    })
  }

  //#region FIlters...

  //Airline Filter
  filterCommonSettings = (event, item, i) => {

    window.scrollTo(0, 0);

    let value = item.airlineName

    // const { name, value } = event.target;
    let data = {};
    let isPresent = false;
    let airlines = [];
    airlines = this.state.Airlines;
    data = this.state.ResultFIlter;

    if (event.target.checked) {
      airlines.forEach((airline, index) => {
        if (airline == value) {
          return;
        }

      });
      airlines.push(value);
    }
    else {
      let ddd = [];
      airlines.map((item) => {

        if (item != value) {
          ddd.push(item);
        }
      });
      airlines = ddd
    }
    this.setState({
      Airlines: airlines,
    })
    let airlineData = {};
    airlineData.Airlines = airlines
    this.setState({
      ResultFIlter: airlineData
    })

    this.render();
    //Run Filter...
    // this.getFilteredSearchResult("airline");


    let value1 = this.state.AirlineCheckbox;
    value1[i]=event.target.checked;
    // console.log(value1);
    this.setState({AirlineCheckbox : value1})


  }

  priceRangeChange = (event) => {

    // console.log(this.state.OnewayFullSearchResult.minimumFare);
    // console.log(this.state.OnewayFullSearchResult.maximumFare);

    //   console.log(this.state.priceRangeMin);
    //   console.log(this.state.priceRangeMax);

    this.setState({
      priceRangeMin: event[0],
      priceRangeMax: event[1],
    })

    //Run Filter...
    //this.getFilteredSearchResult("price");
  }

  stopsChanged = (event, i) => {
    window.scrollTo(0, 0);
    let data = [];
    let resultFIlter = {};
    data = this.state.Stops;
    resultFIlter = this.state.ResultFIlter;
    // data.stops = event.target.value;
    if (event.target.checked) {
      data.forEach((stop, index) => {
        if (stop == event.target.value) {
          return;
        }
      });
      data.push(event.target.value);
    }
    else {
      let ddd = [];
      data.map((stop) => {

        if (stop != event.target.value) {
          ddd.push(stop);

        }
      });
      data = ddd
    }

    // resultFIlter.steps=data
    this.setState({
      Stops: data
    })

    //Run Filter...
    //this.getFilteredSearchResult("stop");

    let value1 = this.state.StopsCheckbox;
    value1[i]=event.target.checked;
    // console.log(value1);
    this.setState({StopsCheckbox : value1})

  }

  SortList = (type) => {
    let data = {}
    data = this.state.ResultFIlter;
    data.sortList = type;
    this.setState({
      ResultFIlter: data
    })

    //Run Filter...
    this.getFilteredSearchResult("sort");
  }

  //DepartureTImeSort...
  departreTime = (event, i) => {
    window.scrollTo(0, 0);
    let data = {}
    data = this.state.DepartureTimes;
    if (event.target.checked) {
      data.forEach((stop, index) => {
        if (stop == event.target.value) {
          return;
        }
      });
      data.push(event.target.value);
    }
    else {
      let ddd = [];
      data.map((stop) => {

        if (stop != event.target.value) {
          ddd.push(stop);

        }
      });
      data = ddd
    }
    // data.departureRange = event.target.value;
    this.setState({
      DepartureTimes: data
    })

    //Run Filter...
    //this.getFilteredSearchResult("departureRange");

    let value1 = this.state.DepartureTimesCheckbox;
    value1[i]=event.target.checked;
    // console.log(value1);
    this.setState({DepartureTimesCheckbox : value1})

  }

  //ArrivalTImeSort...
  arrivalTime = (event, i) => {
    window.scrollTo(0, 0);
    let data = {}
    data = this.state.ArrivalTimes;
    if (event.target.checked) {
      data.forEach((stop, index) => {
        if (stop == event.target.value) {
          return;
        }
      });
      data.push(event.target.value);
    }
    else {
      let ddd = [];
      data.map((stop) => {

        if (stop != event.target.value) {
          ddd.push(stop);

        }
      });
      data = ddd
    }
    // data.departureRange = event.target.value;
    this.setState({
      ArrivalTimes: data
    })


    
    let value1 = this.state.ArrivalTimesCheckbox;
    value1[i]=event.target.checked;
    // console.log(value1);
    this.setState({ArrivalTimesCheckbox : value1})


  }

  //Sort By Cheapest Fastest Earliest...
  sortArray = (event) => {
    window.scrollTo(0, 0);
    let type = event.target.value;
    if (type == "cheapest") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => a.netAmount - b.netAmount),
        isCheapestActive : true,
        isFastestActive  :false,
        isEarlierActive : false,
      }));
    }
    else if (type == "fastest") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => a.durationInMinutes - b.durationInMinutes),
        isFastestActive : true,
        isCheapestActive : false,
        isEarlierActive : false,
      }));
    }
    else if (type == "earliest") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => {
          const timeA = new Date(`${a.arrivalDate} ${a.arrivalTime}`);
          const timeB = new Date(`${b.arrivalDate} ${b.arrivalTime}`);

          return timeA - timeB
        }),
        isEarlierActive : true,
        isCheapestActive : false,
        isFastestActive : false,
      }));
    }
  };

  //Here Filter the data...
  getFilteredSearchResult = (type) => {
    //let dataOnwards=[];
    let tempArray = [];

    //////////////////////////////////
    if (type == "airline")//AIrline FIlter ...)
    {
      let list = [], airlineList = [];
      list = this.state.ItinSearchResultCopy;
      airlineList = this.state.ResultFIlter.Airlines;
      if (this.state.Airlines.length) {
        list.length > 0 && list.map((item, index) => (
          this.state.Airlines.map((airline, index2) => {
            if (airline == item.airlineName) {
              tempArray.push(item);
            }
          })

        ))
      }
      else {
        tempArray = list;
      }

    }
    else if (type == "price")//Price Range Filter... 
    {
      this.state.ItinSearchResult.map((item2, index3) => {
        if (item2.netAmount < this.state.ResultFIlter.priceRangeMax) {
          tempArray.push(item2);
        }
      })

    }
    else if (type == "stop") {
 
      // this.state.ItinSearchResult.map((item3, index3) => {
      //   if(this.state.ResultFIlter.stops!=2)
      //   {
      //     if (item3.noofStop == this.state.ResultFIlter.stops) {
      //       tempArray.push(item3);
      //     }
      //   }
      //   else
      //   {
      //     if (item3.noofStop > 1) {
      //       tempArray.push(item3);
      //     }
      //   }        
      // })

      if (this.state.ResultFIlter.steps.length) {
        let isAllSelected = false;
        if (this.state.ResultFIlter.steps.length == 3) {
          return;
        }

        this.state.ItinSearchResult.length > 0 && this.state.ItinSearchResult.map((item, index) => (
          this.state.ResultFIlter.steps.map((stop, index2) => {
            if (stop == item.noofStop) {
              tempArray.push(item);
            }
          })

        ))
      }

    }
    else if (type == "sort") {
      tempArray = this.state.ItinSearchResult.sort((a, b) => new Date(...a.initialRegistration.split('/').reverse()) - new Date(...b.initialRegistration.split('/').reverse()))


    }
    else if (type == "departureRange") {

      let departRange = this.state.ResultFIlter.departureRange;
      this.state.ItinSearchResult.map((item, index) => {
        const getDate = new Date(`${item.departureDate} ${item.departureTime}`);
        const hours = getDate.getHours();

        if (departRange == "before6") {
          const targetTime = 6;
          if (targetTime > hours) {
            tempArray.push(item);
          }

        }
        else if (departRange == "6to12") {
          const targetTime = 6, targetTime1 = 12;

          if (targetTime < hours && targetTime1 > hours) {
            tempArray.push(item);
          }
        }
        if (departRange == "12to6") {
          const targetTime = 12, targetTime1 = 18;

          if (targetTime < hours && targetTime1 > hours) {
            tempArray.push(item);
          }
        }
        if (departRange == "after6") {
          const targetTime = 18;
          if (targetTime < hours) {
            tempArray.push(item);
          }
        }
      })

    }
    else if (type == "arrivalRange") {

      let arrivalRange = this.state.ResultFIlter.arrivalRange;
      this.state.ItinSearchResult.map((item, index) => {
        const getDate = new Date(`${item.arrivalDate} ${item.arrivalTime}`);
        const hours = getDate.getHours();

        if (arrivalRange == "before6") {
          const targetTime = 6;
          if (targetTime > hours) {
            tempArray.push(item);
          }

        }
        else if (arrivalRange == "6to12") {
          const targetTime = 6, targetTime1 = 12;

          if (targetTime < hours && targetTime1 > hours) {
            tempArray.push(item);
          }
        }
        if (arrivalRange == "12to6") {
          const targetTime = 12, targetTime1 = 18;

          if (targetTime < hours && targetTime1 > hours) {
            tempArray.push(item);
          }
        }
        if (arrivalRange == "after6") {
          const targetTime = 18;
          if (targetTime < hours) {
            tempArray.push(item);
          }
        }
      })

    }

    this.setState({
      ItinSearchResult: tempArray
    })

    this.render();
  }

  sortByDropDown(type) {

    if (type == "1") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => a.netAmount - b.netAmount)
      }));
    }
    else if (type == "2") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => a.duration - b.duration)
      }));
    }
    else if (type == "earliest") {
      this.setState(prevState => ({
        ItinSearchResult: prevState.ItinSearchResult.slice().sort((a, b) => {
          const timeA = new Date(`${a.arrivalDate} ${a.arrivalTime}`);
          const timeB = new Date(`${b.arrivalDate} ${b.arrivalTime}`);

          return timeA - timeB
        })
      }));
    }
  }

  //#endregion



  setRequestBody = (data) => {
    this.setState({
      RequestBody: data,
      RoundTripOnwardDate:data.objSectorList[0].departureDate,      
      
    })
   
  }

  //#region OnewaySection
  originDestinationSectorChange(event, type) {
    let originSectorMain = [];
    let originSector1 = this.state.RequestBody.objsectorlist[0];
    //let destinationSector1=this.state.RequestBody.objsectorlist[1]; 

    if (type == "source") {
      Object.keys(this.state.AirportJson).forEach(function (key) {
        if (this.state.AirportJson[key].code == event.target.value) {
          //Onward...
          originSector1.origin = this.state.AirportJson[key].cityCode;
          originSector1.origincountry = this.state.AirportJson[key].countryCode;
          originSector1.tripmode = "O";

        }
      });
      originSectorMain.push(originSector1);
      //Set New State...
      this.setState({
        originSectorOneway: originSectorMain,
        OnewayFullSearchResult: originSectorMain


      });
    }
    else {
      Object.keys(this.state.AirportJson).forEach(function (key) {
        if (this.state.AirportJson[key].code == event.target.value) {
          //Onward...
          originSector1.destination = this.state.AirportJson[key].cityCode;
          originSector1.destinationcountry = this.state.AirportJson[key].countryCode;

        }
      });

      originSectorMain.push(originSector1);
      //Set New State...
      this.setState({
        originSectorOneway: originSectorMain,
        OnewayFullSearchResult: originSectorMain

      })
    }
  }

  setStartDate(date) {
    let RoundTripFullSearchResult = this.state.RoundTripFullSearchResult;
    let selectedDate = dateFormat(date, "yyyy-mm-dd");   
    RoundTripFullSearchResult.departureDate=selectedDate;
    let RequestBody = this.state.RequestBody;
    RequestBody.objsectorlist[0].departureDate = selectedDate;
    
    this.setState({
      RoundTripFullSearchResult : RoundTripFullSearchResult,
      RequestBody:RequestBody,
    })

    //set Round Trip Section...
    this.setTravelDateRoundTrip(date, "S", "setStartDate");
  }

  // setStartDate(date) {
  //   let dateValue = {};
  //   let allData = {};
  //   let array = [];


  //   allData = this.state.RequestBody;

    
  //   dateValue = this.state.RequestBody.objsectorlist[0];
  //   dateValue.departureDate = dateFormat(date, "yyyy-mm-dd");
  //   array.push(dateValue);
    
  //   allData.objsectorlist = array
  //   this.setState({
  //     RequestBody: allData,
  //     OnewayFullSearchResult: allData,
  //     onewayDepartureDate: dateFormat(date, "yyyy-mm-dd"),
  //   })

  //   let departureDateToSHowinDatePicker={};
  //   departureDateToSHowinDatePicker=this.state.OnewayFullSearchResult;
  //   departureDateToSHowinDatePicker.departureDate=dateFormat(date, "yyyy-mm-dd");
  //   this.setState({
  //     OnewayFullSearchResult:departureDateToSHowinDatePicker
  //   })


  //   //set Round trip Calenders here....
 
  //   let date1=this.state.RoundTripFullSearchResult;
  //   let date2={};
  //   date1.departureDate=dateFormat(date, "yyyy-mm-dd");
  //   date2.returnDate=dateFormat(this.state.RoundTripFullSearchResult.returnDate, "yyyy-mm-dd")
  //   if(date1.departureDate < date2.returnDate)
  //   {    

  //     this.setState({
  //       RoundTripFullSearchResult:date1,
  //       RoundTripOnwardDate:dateFormat(date, "yyyy-mm-dd"),
  //     })
  //   }
  //   else{

  //     date1.returnDate=dateFormat(date, "yyyy-mm-dd");
  //     this.setState({
  //       RoundTripFullSearchResult:date1,
  //       RoundTripReturnDate:dateFormat(date, "yyyy-mm-dd"),
  //       RoundTripOnwardDate:dateFormat(date, "yyyy-mm-dd"),
  //     })
  //   }

  // }

  dropDownSelectionChange = (event) => {
    const { name, value } = event.target;
if (name === "adult") {
    const adultValue = parseInt(value, 10);
    const childValue = Math.min(this.state.child, 10 - adultValue);
    const infantValue = Math.min(this.state.infant, adultValue);
    this.setState({
      adult: adultValue,
      child: childValue,
      infant: infantValue
    });
  } else if (name === "child") {
    const childValue = parseInt(value, 10);
    const adultValue = Math.min(this.state.adult, 10 - childValue);
    const infantValue = Math.min(this.state.infant, adultValue);
    this.setState({
      adult: adultValue,
      child: childValue,
      infant: infantValue
    });
  } else if (name === "infant") {
    const infantValue = parseInt(value, 10);
    this.setState({
      infant: infantValue
    });
  }


    let testData = {}, testData1 = {};
    testData = this.state.RequestBody;
    testData1 = this.state.OnewayFullSearchResult;


    if (event.target.name == "adult" || event.target.name == "child" || event.target.name == "infant") {
      if (event.target.name == "adult") {
        testData.adult = Number(event.target.value)
        testData1.adult = Number(event.target.value)
      }
      else if (event.target.name == "child") {
        testData.child = Number(event.target.value)
        testData1.adult = Number(event.target.value)
      }
      else if (event.target.name == "infant") {
        testData.infant = Number(event.target.value)
        testData1.adult = Number(event.target.value)
      }

      this.setState({
        RequestBody: testData,
        OnewayFullSearchResult: testData1
      })

      return;
    }
    else if (event.target.name == "airlineClass") {
      testData.airlineClass = event.target.value
      this.setState({
        RequestBody: testData,
        OnewayFullSearchResult: testData
      })
    }
  }

  SearchFlights = (type) => {
    
  //Check network connection...
  if(!CheckNetworkStatus())
    return;

  if (this.state.originError == false && this.state.destinationError == false)
  {
    const {
      OnewayOriginSearchTerm,
      OnewayDestinationSearchTerm,
    } = this.state;
    if (!OnewayOriginSearchTerm || !OnewayDestinationSearchTerm) {
      this.setState({ showValidationMessage: true});
      return;
    }
    else
    {
    this.setState({ showValidationMessage: false});
    let travelTypeToCheck;
    let requestToSend = this.state.RequestBody;
 
    let data = {}
    data.departureDate = this.state.OnewayFullSearchResult.departureDate;
    data.origin = this.state.OnewayOriginAirport.cityCode;
    data.origincountry = this.state.OnewayOriginAirport.countryCode;
    data.destination = this.state.OnewayDestinationAirport.cityCode;
    data.destinationcountry = this.state.OnewayDestinationAirport.countryCode;
    data.Tripmode = type;

    requestToSend.objsectorlist = [data];

    //TravelType...
    if (requestToSend.objsectorlist[0].origincountry != "IN" || requestToSend.objsectorlist[0].destinationcountry != "IN") {
      requestToSend.traveltype = "I"
      travelTypeToCheck = "I"
    }
    else {
      requestToSend.traveltype = "D"
      travelTypeToCheck = "D"
    }
    this.setState({
      RequestBody: requestToSend,
      OnewayFullSearchResult: requestToSend
    });

    //ReadyToSearch...
    // localStorage.setItem("onewaySearchResult", JSON.stringify(requestToSend));
    //Navigation....
    if (type == "O"){
      let url="/Oneway?onewaySearchRequest=" + encodeURIComponent(JSON.stringify(requestToSend));
      this.props.navigate(url);
      this.componentDidMount();
      //window.location.reload();
      // this.props.navigate('/Oneway');
    }      
    else if (type == "R") {
      if (travelTypeToCheck == "D")
        this.props.navigate('/Roundtrip');
      else
        this.props.navigate('/InternationalRoundTrip');   //Combained RoundTrip
    }
    else if (type == "S")
      this.props.navigate('/International');

    else if (type == "M")
      this.props.navigate('/Multicity');
    }

  }

  }

  //#endregion

  //#region RoundTrip ModifySearch

  inputSearchSectorChangeFocusOutRoundTrip = (event, type) => {
    let objSector = [];
    let sectorValues = {}, sectorValuesReturn = {};
    sectorValues = this.state.originSectorRound;
    sectorValuesReturn = this.state.destinationSectorRound;
    if (type == "source") {
      Object.keys(this.state.AirportJson).forEach(function (key) {
        if (this.state.AirportJson[key].code == event.target.value) {
          //Onward...
          sectorValues.origin = this.state.AirportJson[key].cityCode;
          sectorValues.origincountry = this.state.AirportJson[key].countryCode;
          //Return
          sectorValuesReturn.destination = this.state.AirportJson[key].cityCode;
          sectorValuesReturn.destinationcountry = this.state.AirportJson[key].countryCode;
        }
      });
    }
    else {
      Object.keys(this.state.AirportJson).forEach(function (key) {
        if (this.state.AirportJson[key].code == event.target.value) {
          //Onward...
          sectorValues.destination = this.state.AirportJson[key].cityCode;
          sectorValues.destinationcountry = this.state.AirportJson[key].countryCode;

          //Return
          sectorValuesReturn.origin = this.state.AirportJson[key].cityCode;
          sectorValuesReturn.origincountry = this.state.AirportJson[key].countryCode;
        }
      });
    }
    //Onward & Return...
    this.setState({
      originSectorRound: sectorValues,
      destinationSectorRound: sectorValuesReturn,
      OnewayFullSearchResult: sectorValues,
      OnewayFullSearchResult: sectorValuesReturn
    })

  }

  inputOnewaySearchRequestChangeHandlerRoundTrip = (event) => {
    let testData = {}
    testData = this.state.RequestBody;

    if (event.target.name == "adult" || event.target.name == "child" || event.target.name == "infant") {
      if (event.target.name == "adult") {
        testData.adult = Number(event.target.value)
      }
      else if (event.target.name == "child") {
        testData.child = Number(event.target.value)
      }
      else if (event.target.name == "infant") {
        testData.infant = Number(event.target.value)
      }


      this.setState({
        RequestBody: testData,
        OnewayFullSearchResult: testData
      })

      //setRequestBody({ ...requestBody, [name]: Number(value) });
      return;
    }
    else if (event.target.name == "airlineClass") {
      testData.airlineClass = event.target.value
      this.setState({
        RequestBody: testData,
        OnewayFullSearchResult: testData
      })
    }
  }

    //#region RoundTrip Date Picker 
  setTravelDateRoundTrip = (date, type, from) => {

    let RoundTripFullSearchResult = this.state.RoundTripFullSearchResult;
    let selectedDate = dateFormat(date, "yyyy-mm-dd");
    if (type == "S") {      
      RoundTripFullSearchResult.departureDate = selectedDate;     
      let RoundTripOnwardDate = selectedDate; 
      if(selectedDate > RoundTripFullSearchResult.returnDate) //Departure date GREATER THAN return Date...
      {
          let RoundTripReturnDate = selectedDate;
          RoundTripFullSearchResult.returnDate = selectedDate;                    
          //Set Return Date...      
          this.setState({
            RoundTripReturnDate:RoundTripReturnDate,
            RoundTripFullSearchResult:RoundTripFullSearchResult,        
          })
      }      
      this.setState({
        
        RoundTripFullSearchResult:RoundTripFullSearchResult,
        RoundTripOnwardDate:RoundTripOnwardDate,
      })

      //Oneway...
        let RequestBody = this.state.RequestBody;
        RequestBody.objsectorlist[0].departureDate = selectedDate;
        let OnewayFullSearchResult = this.state.OnewayFullSearchResult;
        OnewayFullSearchResult.departureDate=selectedDate;
        this.setState({         
          RequestBody:RequestBody,
          OnewayFullSearchResult:OnewayFullSearchResult  
        })
    }
    else{
      let RoundTripReturnDate = selectedDate;
      RoundTripFullSearchResult.returnDate = selectedDate;      

      //Set Return Date...      
      this.setState({
        RoundTripReturnDate:RoundTripReturnDate,
        RoundTripFullSearchResult:RoundTripFullSearchResult,  
            
      })
    }    
  

  }

  //#endregion

  // setTravelDateRoundTrip = (date, date1, type) => {





  //   //.OnewayFullSearchResult.departureDate
  //   let dateValue = {}, dateValue1={}, allData = {}, depDate={};
  //   let array = [];
  //   allData = this.state.RequestBody;
  //   dateValue1 = this.state.RequestBody.objsectorlist[0];
  //   dateValue1.departureDate = dateFormat(date, "yyyy-mm-dd");
  //   depDate.departureDate=dateFormat(date, "yyyy-mm-dd");
  //   array.push(dateValue1);
  //   allData.objsectorlist = array
    
  //   this.setState({
  //     RequestBody: allData,
  //     OnewayFullSearchResult: depDate
  //   })

  //   if (type == "S") {

  //     dateValue = this.state.originSectorRound;

            


  //     let dateTosSHow={};
  //     dateTosSHow.departureDate= dateFormat(date, "yyyy-mm-dd");
  //     dateTosSHow.returnDate= dateFormat(date1, "yyyy-mm-dd");

  //     dateValue.departureDate = dateFormat(date, "yyyy-mm-dd");
  //     this.setState({
  //       originSectorRound: dateValue,
  //       RoundTripFullSearchResult: dateTosSHow,
  //       RoundTripOnwardDate: dateFormat(date, "yyyy-mm-dd"),
  //     })
      

  //     let dateTosSHow1={};
  //     dateTosSHow1.returnDate= dateFormat(date1, "yyyy-mm-dd");
  //     dateTosSHow1.departureDate= dateFormat(date, "yyyy-mm-dd");
      
  //     dateValue = this.state.destinationSectorRound;
  //     dateValue.departureDate = dateFormat(date, "yyyy-mm-dd");
  //     this.setState({
  //       destinationSectorRound: dateValue,
  //       RoundTripFullSearchResult: dateTosSHow1,
  //       RoundTripReturnDate:dateFormat(date1, "yyyy-mm-dd"),
  //     })
  //   }
  //   else {      

  //     let dateTosSHow=this.state.RoundTripFullSearchResult;
  //     dateTosSHow.returnDate= dateFormat(date1, "yyyy-mm-dd");
  //     //dateTosSHow.departureDate= dateFormat(date, "yyyy-mm-dd");
      
  //     dateValue = this.state.destinationSectorRound;
  //     dateValue.departureDate = dateFormat(date, "yyyy-mm-dd");
  //     this.setState({
  //       destinationSectorRound: dateValue,
  //       RoundTripFullSearchResult: dateTosSHow,
  //       RoundTripReturnDate:dateFormat(date1, "yyyy-mm-dd"),
  //     })
  //   }



  //   if (this.endDatePickerRef && this.endDatePickerRef.current) {
  //     const selectedDate = new Date(date);
  //     selectedDate.setDate(selectedDate.getDate() + 1);
  //     this.endDatePickerRef.current.setOpen(true);
  //     this.endDatePickerRef.current.setMinDate(selectedDate);
  //   }



  //       //set Round trip Calenders here....
  //       let dateStored=this.state.RoundTripFullSearchResult;
  //       let onwardDate=dateFormat(date, "yyyy-mm-dd");
  //       let returnDate=dateFormat(date1, "yyyy-mm-dd")
  //       if(onwardDate > returnDate)
  //       {


  //         dateStored.departureDate=onwardDate;
  //         dateStored.returnDate=onwardDate;

  //         this.setState({
  //           RoundTripFullSearchResult:dateStored,
  //           RoundTripReturnDate:onwardDate,
  //         })          
  //       }
       

  // }

  ModifyRoundSearchFlights = (type) => {
    
  //Check network connection...
  if(!CheckNetworkStatus())
    return;

  if (this.state.originErrorR == false && this.state.destinationErrorR == false)
  {
    const {
      RoundTripOriginSearchTerm,
      RoundTripDestinationSearchTerm,
    } = this.state;
    if (!RoundTripOriginSearchTerm || !RoundTripDestinationSearchTerm) {
      this.setState({ showValidationMessage: true});
      return;
    }
    else
    {
    this.setState({ showValidationMessage: false});
    let travelTypeToCheck;
    let requestToSend = this.state.RequestBody;

    let data = [];
    data.push(this.state.originSectorRound);
    data.push(this.state.destinationSectorRound);


    let onwardObj={}, returnObj={};

    onwardObj.departureDate=dateFormat(this.state.RoundTripOnwardDate, "yyyy-mm-dd")
    onwardObj.origin=this.state.RoundTripOriginAirport.cityCode
    onwardObj.origincountry=this.state.RoundTripOriginAirport.countryCode
    onwardObj.destination=this.state.RoundTripDestinationAirport.cityCode
    onwardObj.destinationcountry=this.state.RoundTripDestinationAirport.countryCode

    returnObj.departureDate=dateFormat(this.state.RoundTripReturnDate, "yyyy-mm-dd")
    returnObj.origin=this.state.RoundTripDestinationAirport.cityCode
    returnObj.origincountry=this.state.RoundTripDestinationAirport.countryCode
    returnObj.destination=this.state.RoundTripOriginAirport.cityCode
    returnObj.destinationcountry=this.state.RoundTripOriginAirport.countryCode


    requestToSend.objsectorlist = [onwardObj, returnObj]

    //TravelType...

    if (requestToSend.objsectorlist[0].origincountry != "IN" || requestToSend.objsectorlist[0].destinationcountry != "IN") {
      requestToSend.traveltype = "I"
     
      travelTypeToCheck = "I"
    }
    else {
      requestToSend.traveltype = "D"
      travelTypeToCheck = "D"
    }
    requestToSend.objsectorlist[0].Tripmode="R"
    requestToSend.objsectorlist[1].Tripmode="R"
    this.setState({
      RequestBody: requestToSend,
      OnewayFullSearchResult: requestToSend
    });

    //ReadyToSearch...
    // localStorage.setItem("onewaySearchResult", JSON.stringify(requestToSend));

    //Navigation....
    if (type == "O")
      this.props.navigate('/Oneway');
    else if (type == "R") {
      if (travelTypeToCheck == "D")
      {
        // this.props.navigate('/Roundtrip');
        let url="/Roundtrip?IRTSearchRequest=" + encodeURIComponent(JSON.stringify(requestToSend));
        this.props.navigate(url);
        //this.componentDidMount();
      }        
      else
      {
        //this.props.navigate('/InternationalRoundTrip');   //Combained RoundTrip
        let url="/InternationalRoundTrip?CRTSearchRequest=" + encodeURIComponent(JSON.stringify(requestToSend));
        this.props.navigate(url);
      }
        
    }
    else if (type == "S")
      this.props.navigate('/International');

    else if (type == "M")
      this.props.navigate('/Multicity');
  }  


  }
  }
  //#endregion

  getSearchResult() {
    // if (!this.state.isLoaded) {
      let UserDetails= JSON.parse(localStorage.getItem("TM_UDetails"));     
      if(UserDetails != null)
      {
          this.requestBody.userId = UserDetails.userId;
      }

    GetOneWaySearch(this.requestBody).then((response) => {
      // if(!CheckNetworkStatus()){
      //   this.props.navigate('/issue');
      // }


      if(!response.data.status)
      {
        if(response.data.responseMessage=="No Result Found")
        {
          this.setState({
            isResultFound:true
          })
          
        }
      }
      if (response.data.status) {

        this.setState({
          LowerFareResult: response.data.objlowfareList,
          ItinSearchResult: response.data.objItinList,
          ItinSearchResultCopy: response.data.objItinList,
          AvlAirlineResult: response.data.objAvlairlineList,
          //pricingList: response.data.objItinList.pricingList,
          OnewayFullSearchResult: response.data,

          isLoaded: true,
        });

        // let filterPrice={};
        // filterPrice.priceRange=this.state.OnewayFullSearchResult.maximumFare;
        this.setState({
          priceRangeMax: response.data.maximumFare,
          OnewayOriginSearchTerm:response.data.origin,
          OnewayDestinationSearchTerm:response.data.destination,
          RoundTripOriginSearchTerm:response.data.origin,
          RoundTripDestinationSearchTerm:response.data.destination,

        })

        let dataForModifySearch=this.state.RoundTripFullSearchResult;
        dataForModifySearch.departureDate=response.data.objItinList[0].departureDate;
        dataForModifySearch.returnDate=response.data.objItinList[0].departureDate;
        this.setState({ 
          RoundTripFullSearchResult:dataForModifySearch,
          RoundTripOnwardDate:response.data.objItinList[0].departureDate,
          RoundTripReturnDate:response.data.objItinList[0].departureDate,

        })



      }

    })
    .catch((error) => {
      if(error.response &&  error.response.status == 403 && !window.navigator.onLine){
        this.props.navigate('/issue');
      }
      else if(error.response && error.response.data && (error.response.data.responseMessage=="InvalidToken" || error.response.data.responseMessage == "TokenTimeOut"))
      { 

        localStorage.removeItem("TM_UDetails");
            localStorage.removeItem("tokenValue");
            localStorage.removeItem("onewaySearchResult");
            localStorage.removeItem("NonUsertokenValue");
            localStorage.removeItem("DeviceId");
            localStorage.removeItem("RS");
            
            toast.success('Unauthorized Access...', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
              });
        
         this.props.navigate('/');        
      }
      else{

        toast.error(`An internal server error occurred, Redirecting to Home Page`, {
          position: 'bottom-center',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          onClose: () => {
            // Redirect to the previous page
            this.props.navigate('/');   
          },
      });
      }
        // toast.success('Something went wrong...', {
        //   position: 'top-right',
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: 'colored',
        // });

    });
    //}    
  }

  // getAllAirports()
  // {
  //     GetAirportList().then((response)=>{
  //         if(response.data.status)
  //         {
  //             this.setState({
  //                 AirportJson : response.data.objAirportList,
  //             })            
  //         }
  //     })
  // }

  // Method to close the modal
  closeModal = () => {
    const modalElement = document.getElementById('flightdetails');
    console.log(modalElement);
    if (modalElement) {
      const modalInstance = new Modal(modalElement); // Manually initialize the modal
      modalInstance.hide();
    }
  }

  getFlightDetailsResult(id, fare, code) {

    this.setState({
      FlightDetailsResult: [],
      objitinResult: [],
      objseglistResult: [],
      RequestFields:[],
    })
   



    const data = {
      itinId: id,
      fareId: fare,
      providerCode: code,
    };
    // if (!this.state.isLoaded) {
    GetFlightDetails(data).then((response) => {
      if (response.data.status) {
        this.handleFlightDetailsShow(); //open Model
        this.setState({

          //LowerFareResult: response.data.objlowfareList,
          objseglistResult: response.data.objitin.objseglist,
          //AvlAirlineResult: response.data.objAvlairlineList,
          objitinResult: response.data.objitin,
          FlightDetailsResult: response.data,
          RequestFields: data,
        });
      }
    })
    .catch((error) => {
      if(error.response.data.responseMessage=="InvalidToken" || error.response.data.responseMessage == "TokenTimeOut")
      {
        this.closeModal();

        localStorage.removeItem("TM_UDetails");
            localStorage.removeItem("tokenValue");
            localStorage.removeItem("onewaySearchResult");
            localStorage.removeItem("NonUsertokenValue");
            localStorage.removeItem("DeviceId");
            localStorage.removeItem("RS");

            toast.success('Unauthorized Access...', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
        
        this.props.navigate('/');
        
      }     
      else
      {
          toast.success('Something went wrong...', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
      }

    });
    // }

  }

  BookingRequest(id, fare, code,idR, fareR, codeR) {

    const data = {
      itinId: id,
      fareId: fare,
      providerCode: code,
    };
      // GetPricingDetails(data).then((response) => {
      //   if (response.data.status) {
      //     const secretKey = '123'; // Secret key used for encryption
      //     const jsonData = JSON.stringify(response);
      //     const encryptedData = AES.encrypt(jsonData, secretKey).toString();
      //     const bookparam = encodeURIComponent(encryptedData);
      //     this.props.navigate(`/checkout?checkoutRequest=${bookparam}`)
        
      //   }
      //   else
      //   {
      //         const secretKey = '123'; // Secret key used for encryption
      //         const jsonData = JSON.stringify(response);
      //         const encryptedData = AES.encrypt(jsonData, secretKey).toString();
      //         const bookparam = encodeURIComponent(encryptedData);
      //         this.props.navigate(`/checkout?checkoutRequest=${bookparam}`)
  
      //   }
      // })
      // .catch((error) => {      
      //   if(error.response.status == 403){
      //     this.props.navigate('/issue');
      //   }
      //   else if (error.response && error.response.status === 500) {
      //     toast.error(`An internal server error occurred`, {
      //       position: 'bottom-center',
      //       autoClose: 3000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //       theme: 'colored',
      //       onClose: () => {
      //         // Redirect to the previous page
      //         window.history.back();
      //       },
      //   });
      //   }
      //   else if (error.response.data.responseMessage == "InvalidToken") {        
      //     this.props.navigate('/');
      //   }
      // });
    
   
    const secretKey = URL_EncodeKey(); // Secret key used for encryption
    const jsonData = JSON.stringify(data);
    const encryptedData = AES.encrypt(jsonData, secretKey).toString();
    const bookparam = encodeURIComponent(encryptedData);
    this.props.navigate(`/checkout?checkoutRequest=${bookparam}`)
  }

  handleFilter = (item) => {
  const newDate = item.date;
  //Modify the request body
  this.modifiedRequestBody = {
    ...this.state.RequestBody, // Copy the existing requestBody
    objsectorlist: [
      {
        ...this.state.RequestBody.objSectorList[0], // Copy the first element of objsectorlist
        departureDate: newDate, // Update the departureDate
        isRecent:false,
      },
    ],
  };


  const Param = encodeURIComponent(JSON.stringify(this.modifiedRequestBody));
  this.props.navigate(`/Oneway?onewaySearchRequest=${Param}`);
  clearInterval(this.intervalId);
  window.location.reload();
  //this.componentDidMount();
  };

  moveSlider = () => {
    this.setState(prevState => {
      const newValue = prevState.value < prevState.max ? prevState.value + 1 : 0;
      return { value: newValue };
    });
  }

  render() {

    //AUtoComplete...
    const { showValidationMessage,SameAirportsError } = this.state;
    const { selectedIndex,selectedIndex2,selectedIndex3,selectedIndex4 } = this.state;
    const { OnewayOriginSearchTerm, OnewayDestinationSearchTerm, OnewayOriginSearchResults, OnewayDestinationSearchResults, RoundTripOriginSearchTerm, RoundTripDestinationSearchTerm, RoundTripOriginSearchResults, RoundTripDestinationSearchResults,originError,destinationError,originErrorR,destinationErrorR } = this.state;
    const showSearchResultsOnewayOrigin = OnewayOriginSearchResults.length > 0;
    const showSearchResultsOnewayDestination = OnewayDestinationSearchResults.length > 0;

    const showSearchResultsRoundTripyOrigin = RoundTripOriginSearchResults.length > 0;
    const showSearchResultsRoundTripDestination = RoundTripDestinationSearchResults.length > 0;

    const showNoResultsMessageOnewayOrigin = RoundTripOriginSearchResults.length >= 2 && !showSearchResultsOnewayOrigin;
    const showNoResultsMessageOnewayDestination = OnewayDestinationSearchResults.length >= 2 && !showSearchResultsOnewayDestination;

    const showNoResultsMessageRoundTripOrigin = OnewayOriginSearchResults.length >= 2 && !showSearchResultsOnewayOrigin;
    const showNoResultsMessageRoundTripDestination = RoundTripDestinationSearchResults.length >= 2 && !showSearchResultsOnewayDestination;



    return (this.state.isLoaded && !this.state.isResultFound) ? (
      <div>
        {/* {this.state.isFadeOut && <style>
                                        {`.modal-backdrop {
                                          display: none;
                                        }`}
                                  </style>} */}

       {/* Modify search desktop view starts */}
        <div className="inner-search-area desk-modify">
          <div className="inner-search" id="inner-search" alt="">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="d-flex">
                    <div className="flex-row mr-10 mod-search">
                      <p className="small-text text-white">From</p>
                      <input
                        type="text"
                        id="source"
                        className="form-control inner-search-input"
                        name="origin" value={this.state.OnewayFullSearchResult.origin} aria-label="Disabled input example" disabled readOnly
                      />
                    </div>
                    <div className="flex-row mr-10 mod-search">
                      <div className="swap-image-inner text-center pt-3 mr-30">
                        <a href="#" className="inner-swap dvSwapAirline">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M12.2929 4.29289C12.6834 3.90237 13.3166 3.90237 13.7071 4.29289L20.7071 11.2929C21.0976 11.6834 21.0976 12.3166 20.7071 12.7071L13.7071 19.7071C13.3166 20.0976 12.6834 20.0976 12.2929 19.7071C11.9024 19.3166 11.9024 18.6834 12.2929 18.2929L17.5858 13H4C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11H17.5858L12.2929 5.70711C11.9024 5.31658 11.9024 4.68342 12.2929 4.29289Z" fill="#ffffff"></path> </g></svg>
                        </a>
                      </div>
                    </div>
                    <div className="flex-row mod-search">
                      <p className="small-text text-white">To</p>
                      <input
                        type="text"
                        id="destination"
                        className="form-control inner-search-input"

                        name="destination" value={this.state.OnewayFullSearchResult.destination} aria-label="Disabled input example" disabled readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="d-flex">
                    <div className="flex-row mr-10 mod-search">
                      <p className="small-text text-white">Departure Date</p>
                      <input
                        type="text"                        
                        className="form-control inner-search-input"
                        id="input_from"
                        name="fromDate" value={dateFormat(this.state.OnewayFullSearchResult.departureDate, "dd-mm-yyyy")} aria-label="Disabled input example" disabled readOnly
                      />
                    </div>
                    <div className="flex-row">
                      <p className="small-text text-white">Return Date</p>
                      <input
                        type="text"
                        defaultValue=""
                        className="form-control inner-search-input"
                        id="input_to"
                        name="toDate" aria-label="Disabled input example" disabled readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex">
                    <div className="flex-row">
                      <p className="small-text text-white">
                        Traveller & Class
                      </p>
                      <input type="text" className="form-control inner-search-input" 
                      //value={this.state.OnewayFullSearchResult.adult + this.state.OnewayFullSearchResult.child + this.state.OnewayFullSearchResult.infant + "-" + "Travellers" + "," + this.state.OnewayFullSearchResult.airlineClass} 
                      value={
                        (this.state.OnewayFullSearchResult.adult + 
                        this.state.OnewayFullSearchResult.child + 
                        this.state.OnewayFullSearchResult.infant > 1) ? 
                        `${this.state.OnewayFullSearchResult.adult + this.state.OnewayFullSearchResult.child + this.state.OnewayFullSearchResult.infant}-Travellers, ${this.state.OnewayFullSearchResult.airlineClass}` : 
                        `1-Traveller, ${this.state.OnewayFullSearchResult.airlineClass}`
                      }
                      aria-label="Disabled input example" disabled readOnly />
                    </div>
                    <div className="flex-row pt-3">
                      <a ><button className="btn btn-primary inner-search-button" data-bs-toggle="modal" data-bs-target="#modifyModal" >MODIFY</button></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         {/* Modify search desktop view ends */}
          
            {/* Modify search Mobile view starts */}
         <div className="inner-search-area mob-modify">
          <div className="inner-search" id="inner-search" alt="">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                <ul className="mob-mod-list">
                <li>{this.state.OnewayFullSearchResult.origin}</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg></li>
                <li>{this.state.OnewayFullSearchResult.destination}</li>
                <li>|</li>
                <li>{this.formattedDate(this.state.OnewayFullSearchResult.departureDate)}</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
</svg> {this.state.OnewayFullSearchResult.adult + this.state.OnewayFullSearchResult.child + this.state.OnewayFullSearchResult.infant}</li>
                <li> <a className="mod-btn-mob" data-bs-toggle="modal" data-bs-target="#modifyModal" >MODIFY</a></li>
                </ul>
                 
                    
                   
                    
                    
                 
                </div>
               
               
              </div>
            </div>
          </div>
        </div>
          {/* Modify search Mobile view ends */}

        {/* Modify popup */}


        <div className="modal fade" id="modifyModal" tabIndex={-1} aria-labelledby="modifyModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
          <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">Modify Search.</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                    <ModifySearchPopUp parentFunction={this.componentDidMount}/>
              </div>
           </div>
         </div>
        </div>
        <div>
          <div className="calendar-slider-area inner-date-slider">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-10 col-12">
                
                  {this.state.LowerFareResult && this.state.LowerFareResult.length > 0 ? (
                    <Slider slidesToShow={this.state.LowerFareResult.length} {...this.state.settings}>
                      {this.state.LowerFareResult.map((item, index) => (
                        <div key={index}  >                
                          <div  className="date-block active1"  onClick={() => this.handleFilter(item)}>
                            <p className="small-text">{dateFormat(item.date, "dd-mm-yyyy")}</p>
                            {/* <p className="small-text">{item.date}</p> */}
                            <h5 className="inner-price">₹{Math.round(item.amount)}</h5> 
                          </div>
                        </div>
                      ))}

                    </Slider>
                  ) : (
                    <div className="low-fare-no-data text-center">
                      <p className="no-margin"><img src="assets/img/calendar.svg" width={40} /> Sorry, no data found</p>
                    </div>
                  )
                  }
                </div>

             
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3">
                {/* Sidebar desktop starts */}
                <div className="side-bar side-bar-desk">
                  <div className="bg-white" id="sidebar-wrapper">
                    {/*Airline Price area starts*/}
                    <div className="airline-area">
                      <div className="d-flex flex-row reset-area">
                       <h4>Filters</h4>
                       <span className="reset-filter" onClick={this.resetFilter}>Reset filters</span>
                      </div>
                      <div className="side-bar-title">
                        <h4>Airlines</h4>
                      </div>
                      {this.state.AvlAirlineResult && this.state.AvlAirlineResult.length > 0 ? (
                        <form className="airline" id="scrollbar1" alt="">
                          {this.state.AvlAirlineResult.map((item, index) => (
                            <div key={index}>
                            <label style={{ width:'100%', fontSize: '1.1em' }}>
                            <div className="d-flex airline-price mb-3">
                              <div className="flex-row">
                                <img
                                  src={`assets//img/logos/${item.airlineCode}.gif`}
                                  width={30}
                                  alt="."
                                />
                              </div>
                              <div className="flex-row pt-2 pl-5  me-auto">
                                <h6>
                                  {item.airlineName}
                                  <span>({item.count})</span>
                                </h6>
                              </div>
                              <div className="flex-row pt-2 mr-10">
                                <h6>₹ {Math.round(item.minAmount)}</h6>   
                              </div>
                              <div className="air-check-box d-flex align-items-center">
                                <input type="checkbox" checked={this.state.AirlineCheckbox[index]} className="form-control" onChange={(e) => { this.filterCommonSettings(e, item, index) }} />
                              </div>
                            </div>
                            </label>
                            </div>
                          ))}
                        
                        </form>
                      ) : (
                        <div className="p-1"><img src="assets/img/flight2.svg" width={40} />
                          Sorry,no airlines found</div>
                      )}
                    </div>
                    {/*Airline Price area ends*/}
                    {/*Stops area starts*/}
                    <div className="stops-area">
                      <div className="side-bar-title">
                        <h4>Stops</h4>
                      </div>
                      <div id="stop-area-on">
                        <input
                          type="checkbox"
                          id="non-stop"
                          name="stops"
                          defaultValue="0" checked={this.state.StopsCheckbox[0]} onChange={(e) => this.stopsChanged(e, 0)}
                        />
                        <label htmlFor="non-stop">
                          0<br />
                          Non-stop
                        </label>
                        <input
                          type="checkbox"
                          id="one-stop"
                          name="stops"
                          defaultValue="1" checked={this.state.StopsCheckbox[1]} onChange={(e) => this.stopsChanged(e, 1)}
                        />
                        <label htmlFor="one-stop">
                          1<br />
                          stop
                        </label>
                        <input
                          type="checkbox"
                          id="oneplus-stop"
                          name="stops"
                          defaultValue="2" checked={this.state.StopsCheckbox[2]} onChange={(e) => this.stopsChanged(e, 2)}
                        />
                        <label htmlFor="oneplus-stop">
                          1+
                          <br />
                          stop
                        </label>
                      </div>
                    </div>
                    {/*Stops area ends */}
                    {/*Departure time area starts */}
                    <div className="departure-area">
                      <div className="side-bar-title">
                        <h4>Departure Time</h4>
                      </div>
                      <div id="departure-time-on">
                        <input
                          type="checkbox"
                          id="chk1"
                          name="chk"
                          defaultValue="before6"
                          checked={this.state.DepartureTimesCheckbox[0]}
                          onChange={(e) => this.departreTime(e, 0)}
                        />
                        <label htmlFor="chk1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-alt-high"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                          </svg>
                          <br />
                          12AM-
                          <br />
                          6AM
                        </label>
                        <input
                          type="checkbox"
                          id="chk2"
                          name="chk"
                          defaultValue="6to12"
                          checked={this.state.DepartureTimesCheckbox[1]}
                          onChange={(e) => this.departreTime(e, 1)}
                        />
                        <label htmlFor="chk2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-high"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                          </svg>
                          <br />
                          6AM-
                          <br />
                          12PM
                        </label>
                        <input
                          type="checkbox"
                          id="chk3"
                          name="chk"
                          defaultValue="12to6"
                          checked={this.state.DepartureTimesCheckbox[2]}
                          onChange={(e) => this.departreTime(e, 2)}
                        />
                        <label htmlFor="chk3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-high-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                          </svg>
                          <br />
                          12PM-
                          <br />
                          6PM
                        </label>
                        <input
                          type="checkbox"
                          id="chk4"
                          name="chk"
                          defaultValue="after6"
                          checked={this.state.DepartureTimesCheckbox[3]}
                          onChange={(e) => this.departreTime(e, 3)}
                        />
                        <label htmlFor="chk4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-moon"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                          </svg>
                          <br />
                          6PM-
                          <br />
                          12AM
                        </label>
                      </div>
                    </div>
                    {/*Departure time area ends */}
                    {/*Arrival time area starts */}
                    <div className="arrival-area">
                      <div className="side-bar-title">
                        <h4>Arrival Time</h4>
                      </div>
                      <div id="arrival-time-on">
                        <input
                          type="checkbox"
                          id="chk01"
                          name="chk"
                          defaultValue="before6"
                          checked={this.state.ArrivalTimesCheckbox[0]}
                          onChange={(e) => this.arrivalTime(e, 0)}
                        />
                        <label htmlFor="chk01">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-alt-high"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                          </svg>
                          <br />
                          12AM-
                          <br />
                          6AM
                        </label>
                        <input
                          type="checkbox"
                          id="chk02"
                          name="chk"
                          defaultValue="6to12"
                          checked={this.state.ArrivalTimesCheckbox[1]}
                          onChange={(e) => this.arrivalTime(e, 1)}
                        />
                        <label htmlFor="chk02">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-high"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                          </svg>
                          <br />
                          6AM-
                          <br />
                          12PM
                        </label>
                        <input
                          type="checkbox"
                          id="chk03"
                          name="chk"
                          defaultValue="12to6"
                          checked={this.state.ArrivalTimesCheckbox[2]}
                          onChange={(e) => this.arrivalTime(e, 2)}
                        />
                        <label htmlFor="chk03">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-high-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                          </svg>
                          <br />
                          12PM-
                          <br />
                          6PM
                        </label>
                        <input
                          type="checkbox"
                          id="chk04"
                          name="chk"
                          defaultValue="after6"
                          checked={this.state.ArrivalTimesCheckbox[3]}
                          onChange={(e) => this.arrivalTime(e, 3)}
                        />
                        <label htmlFor="chk04">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-moon"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                          </svg>
                          <br />
                          6PM-
                          <br />
                          12AM
                        </label>
                      </div>
                    </div>
                    {/*Arrival time area ends */}
                    {/*refund/non refund selection */}
                    {/* <div className="refund-area">
                      <div className="form-check form-switch">
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckChecked"
                        >
                          Refundable
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                        />
                      </div>
                    </div> */}
                    {/*Price range slider */}
                    <div className="price-slider-area">
                      <div className="side-bar-title">
                        <h4>One Way Price</h4>
                      </div>
                    
                      <ReactSlider
                        min={Math.round(this.state.OnewayFullSearchResult.minimumFare)} 
                        max={Math.round(this.state.OnewayFullSearchResult.maximumFare)} 
                        className="horizontal-slider"
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        defaultValue={[parseInt(Math.round(this.state.OnewayFullSearchResult.minimumFare)), parseInt(Math.round(this.state.OnewayFullSearchResult.maximumFare))]}
                        value={[this.state.priceRangeMin,this.state.priceRangeMax]}
                        ariaLabel={['Lower thumb', 'Upper thumb']}
                        ariaValuetext={state => `Thumb value ${state.valueNow}`}
                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                        pearling
                        onChange={(e) => this.priceRangeChange(e)}
                      />
                    </div>
                  
                  </div>
                </div>
                {/* Sidebar desktop ends */}
              {/* Sidebar mob starts */}
              <div className="side-bar side-bar-mob">
              <div className="accordion accordion-flush" id="accordionFilter">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOne">
      <button
        className="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#flush-collapseOne"
        aria-expanded="false"
        aria-controls="flush-collapseOne"
      >
        Filters
      </button>
    </h2>
    <div
      id="flush-collapseOne"
      className="accordion-collapse collapse"
      aria-labelledby="flush-headingOne"
      data-bs-parent="#accordionFilter"
    >
      <div className="accordion-body">
       
      <div className="bg-white" id="sidebar-wrapper">
                    {/*Airline Price area starts*/}
                    <div className="airline-area">
                      <div className="d-flex flex-row reset-area">
                      
                       <span className="reset-filter" onClick={this.resetFilter}>Reset filters</span>
                      </div>
                      <div className="side-bar-title">
                        <h4>Airlines</h4>
                      </div>
                      {this.state.AvlAirlineResult && this.state.AvlAirlineResult.length > 0 ? (
                        <form className="airline" id="scrollbar1" alt="">
                          {this.state.AvlAirlineResult.map((item, index) => (
                            <div key={index}>
                            <label style={{ width:'100%', fontSize: '1.1em' }}>
                            <div className="d-flex airline-price mb-3">
                              <div className="flex-row">
                                <img
                                  src={`assets//img/logos/${item.airlineCode}.gif`}
                                  width={30}
                                  alt="."
                                />
                              </div>
                              <div className="flex-row pt-2 pl-5  me-auto">
                                <h6>
                                  {item.airlineName}
                                  <span>({item.count})</span>
                                </h6>
                              </div>
                              <div className="flex-row pt-2 mr-10">
                                <h6>₹ {Math.round(item.minAmount)}</h6>   
                              </div>
                              <div className="air-check-box d-flex align-items-center">
                                <input type="checkbox" checked={this.state.AirlineCheckbox[index]} className="form-control" onChange={(e) => { this.filterCommonSettings(e, item, index) }} />
                              </div>
                            </div>
                            </label>
                            </div>
                          ))}
                        
                        </form>
                      ) : (
                        <div className="p-1"><img src="assets/img/flight2.svg" width={40} />
                          Sorry,no airlines found</div>
                      )}
                    </div>
                    {/*Airline Price area ends*/}
                    {/*Stops area starts*/}
                    <div className="stops-area">
                      <div className="side-bar-title">
                        <h4>Stops</h4>
                      </div>
                      <div id="stop-area-on">
                        <input
                          type="checkbox"
                          id="non-stop"
                          name="stops"
                          defaultValue="0" checked={this.state.StopsCheckbox[0]} onChange={(e) => this.stopsChanged(e, 0)}
                        />
                        <label htmlFor="non-stop">
                          0<br />
                          Non-stop
                        </label>
                        <input
                          type="checkbox"
                          id="one-stop"
                          name="stops"
                          defaultValue="1" checked={this.state.StopsCheckbox[1]} onChange={(e) => this.stopsChanged(e, 1)}
                        />
                        <label htmlFor="one-stop">
                          1<br />
                          stop
                        </label>
                        <input
                          type="checkbox"
                          id="oneplus-stop"
                          name="stops"
                          defaultValue="2" checked={this.state.StopsCheckbox[2]} onChange={(e) => this.stopsChanged(e, 2)}
                        />
                        <label htmlFor="oneplus-stop">
                          1+
                          <br />
                          stop
                        </label>
                      </div>
                    </div>
                    {/*Stops area ends */}
                    {/*Departure time area starts */}
                    <div className="departure-area">
                      <div className="side-bar-title">
                        <h4>Departure Time</h4>
                      </div>
                      <div id="departure-time-on">
                        <input
                          type="checkbox"
                          id="chk1"
                          name="chk"
                          defaultValue="before6"
                          checked={this.state.DepartureTimesCheckbox[0]}
                          onChange={(e) => this.departreTime(e, 0)}
                        />
                        <label htmlFor="chk1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-alt-high"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                          </svg>
                          <br />
                          12AM-
                          <br />
                          6AM
                        </label>
                        <input
                          type="checkbox"
                          id="chk2"
                          name="chk"
                          defaultValue="6to12"
                          checked={this.state.DepartureTimesCheckbox[1]}
                          onChange={(e) => this.departreTime(e, 1)}
                        />
                        <label htmlFor="chk2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-high"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                          </svg>
                          <br />
                          6AM-
                          <br />
                          12PM
                        </label>
                        <input
                          type="checkbox"
                          id="chk3"
                          name="chk"
                          defaultValue="12to6"
                          checked={this.state.DepartureTimesCheckbox[2]}
                          onChange={(e) => this.departreTime(e, 2)}
                        />
                        <label htmlFor="chk3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-high-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                          </svg>
                          <br />
                          12PM-
                          <br />
                          6PM
                        </label>
                        <input
                          type="checkbox"
                          id="chk4"
                          name="chk"
                          defaultValue="after6"
                          checked={this.state.DepartureTimesCheckbox[3]}
                          onChange={(e) => this.departreTime(e, 3)}
                        />
                        <label htmlFor="chk4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-moon"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                          </svg>
                          <br />
                          6PM-
                          <br />
                          12AM
                        </label>
                      </div>
                    </div>
                    {/*Departure time area ends */}
                    {/*Arrival time area starts */}
                    <div className="arrival-area">
                      <div className="side-bar-title">
                        <h4>Arrival Time</h4>
                      </div>
                      <div id="arrival-time-on">
                        <input
                          type="checkbox"
                          id="chk01"
                          name="chk"
                          defaultValue="before6"
                          checked={this.state.ArrivalTimesCheckbox[0]}
                          onChange={(e) => this.arrivalTime(e, 0)}
                        />
                        <label htmlFor="chk01">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-alt-high"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 3zm8 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zm-13.5.5a.5.5 0 0 0 0-1h-2a.5.5 0 0 0 0 1h2zm11.157-6.157a.5.5 0 0 1 0 .707l-1.414 1.414a.5.5 0 1 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm-9.9 2.121a.5.5 0 0 0 .707-.707L3.05 5.343a.5.5 0 1 0-.707.707l1.414 1.414zM8 7a4 4 0 0 0-4 4 .5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5 4 4 0 0 0-4-4zm0 1a3 3 0 0 1 2.959 2.5H5.04A3 3 0 0 1 8 8z" />
                          </svg>
                          <br />
                          12AM-
                          <br />
                          6AM
                        </label>
                        <input
                          type="checkbox"
                          id="chk02"
                          name="chk"
                          defaultValue="6to12"
                          checked={this.state.ArrivalTimesCheckbox[1]}
                          onChange={(e) => this.arrivalTime(e, 1)}
                        />
                        <label htmlFor="chk02">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-high"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                          </svg>
                          <br />
                          6AM-
                          <br />
                          12PM
                        </label>
                        <input
                          type="checkbox"
                          id="chk03"
                          name="chk"
                          defaultValue="12to6"
                          checked={this.state.ArrivalTimesCheckbox[2]}
                          onChange={(e) => this.arrivalTime(e, 2)}
                        />
                        <label htmlFor="chk03">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-brightness-high-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                          </svg>
                          <br />
                          12PM-
                          <br />
                          6PM
                        </label>
                        <input
                          type="checkbox"
                          id="chk04"
                          name="chk"
                          defaultValue="after6"
                          checked={this.state.ArrivalTimesCheckbox[3]}
                          onChange={(e) => this.arrivalTime(e, 3)}
                        />
                        <label htmlFor="chk04">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            fill="currentColor"
                            className="bi bi-moon"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278zM4.858 1.311A7.269 7.269 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.316 7.316 0 0 0 5.205-2.162c-.337.042-.68.063-1.029.063-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286z" />
                          </svg>
                          <br />
                          6PM-
                          <br />
                          12AM
                        </label>
                      </div>
                    </div>
                    {/*Arrival time area ends */}
                    {/*refund/non refund selection */}
                    {/* <div className="refund-area">
                      <div className="form-check form-switch">
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckChecked"
                        >
                          Refundable
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                        />
                      </div>
                    </div> */}
                    {/*Price range slider */}
                    <div className="price-slider-area">
                      <div className="side-bar-title">
                        <h4>One Way Price</h4>
                      </div>
                    
                      <ReactSlider
                        min={Math.round(this.state.OnewayFullSearchResult.minimumFare)} 
                        max={Math.round(this.state.OnewayFullSearchResult.maximumFare)} 
                        className="horizontal-slider"
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        defaultValue={[parseInt(Math.round(this.state.OnewayFullSearchResult.minimumFare)), parseInt(Math.round(this.state.OnewayFullSearchResult.maximumFare))]}
                        value={[this.state.priceRangeMin,this.state.priceRangeMax]}
                        ariaLabel={['Lower thumb', 'Upper thumb']}
                        ariaValuetext={state => `Thumb value ${state.valueNow}`}
                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                        pearling
                        onChange={(e) => this.priceRangeChange(e)}
                      />
                    </div>
                  <a className="btn btn-primary book-btn" data-bs-toggle="collapse"
        data-bs-target="#flush-collapseOne"
        aria-expanded="false"
        aria-controls="flush-collapseOne">APPLY FILTERS</a>
                  </div>
      </div>
    </div>
  </div>
 
</div>

                </div>
                {/* Sidebar mob ends */}

              </div>
              {this.state.ItinSearchResult && this.state.ItinSearchResult.length > 0 ? (
                <div className="col-lg-9 col-md-9">
                  <div className="top-sort-area">
                    <div className="d-flex align-items-center">
                      <div className="flex-row">
                        <form id="top-sort-form">
                          <input
                            type="radio"
                            id="cheapest"
                            name="sortOption"
                            defaultValue="cheapest"
                            checked={this.state.isCheapestActive}
                            onClick={(e) => this.sortArray(e)}
                          />
                          <label htmlFor="cheapest">
                            <span className="selection">
                              <img src="assets/img/cheapest.svg" width={25} />
                            </span>{" "}
                            CHEAPEST
                          </label>
                          <input
                            type="radio"
                            id="fastest"
                            name="sortOption"
                            defaultValue="fastest"
                            checked={this.state.isFastestActive}
                            onClick={(e) => this.sortArray(e)}
                          />
                          <label htmlFor="fastest">
                            <span className="selection">
                              <img src="assets/img/fastest.svg" width={25} />
                            </span>{" "}
                            FASTEST
                          </label>
                          <input
                            type="radio"
                            id="earliest"
                            name="sortOption"
                            defaultValue="earliest"
                            checked={this.state.isEarlierActive}
                            onClick={(e) => this.sortArray(e)}
                          />
                          <label htmlFor="earliest">
                            <span className="selection">
                              <img src="assets/img/earliest.svg" width={25} />
                            </span>{" "}
                            EARLIEST
                          </label>
                          {/* <label>{this.state.ItinSearchResult.length}</label> */}
                        </form>
                      </div>
                    </div>
                  </div> 

                  {/*One way result*/}
                  {/* {filteredArray = originalArray.filter(item => filterArray.includes(item))} */}



                  {this.state.ItinSearchResult.map((item, index) => {

                    let arrivalTime = "";


                    if (parseInt(item.arrivalTime.split(":")[0]) < 6) {
                      arrivalTime = "before6";
                    }
                    else if (parseInt(item.arrivalTime.split(":")[0]) >= 6 && parseInt(item.arrivalTime.split(":")[0]) < 12) {
                      arrivalTime = "6to12";
                    }
                    else if (parseInt(item.arrivalTime.split(":")[0]) >= 12 && parseInt(item.arrivalTime.split(":")[0]) < 18) {
                      arrivalTime = "12to6";
                    }
                    else if (parseInt(item.arrivalTime.split(":")[0]) >= 18) {
                      arrivalTime = "after6";
                    }



                    //Arrival Time...
                    let departureTime = "";


                    if (parseInt(item.departureTime.split(":")[0]) < 6) {
                      departureTime = "before6";
                    }
                    else if (parseInt(item.departureTime.split(":")[0]) >= 6 && parseInt(item.departureTime.split(":")[0]) < 12) {
                      departureTime = "6to12";
                    }
                    else if (parseInt(item.departureTime.split(":")[0]) >= 12 && parseInt(item.departureTime.split(":")[0]) < 18) {
                      departureTime = "12to6";
                    }
                    else if (parseInt(item.departureTime.split(":")[0]) >= 18) {
                      departureTime = "after6";
                    }

                    //+1 Stops...
                    let itemStop=0;
                    if(parseInt(item.noofStop)>1)
                    {
                      itemStop=2
                    }

                    
                    return <div key={index}>



                      {/* Stops >0 */}
                      {(this.state.Airlines.length == 0 || (this.state.Airlines.length > 0 && this.state.Airlines.includes(item.airlineName))) && this.state.priceRangeMin <= item.netAmount && this.state.priceRangeMax >= item.netAmount && (this.state.Stops.length == 0 || (this.state.Stops.length > 0 && (this.state.Stops.includes(item.noofStop.toString()) || this.state.Stops.includes(itemStop)))) && (this.state.DepartureTimes.length == 0 || (this.state.DepartureTimes.length > 0 && this.state.DepartureTimes.includes(departureTime))) && (this.state.ArrivalTimes.length == 0 || (this.state.ArrivalTimes.length > 0 && this.state.ArrivalTimes.includes(arrivalTime))) &&
                       
                        <div className="deal-wrapper" key={index}>

                          {/*<div className="deal-tag">
                                        <p><span className="deal-symbol">DEAL</span> Up to 25 % OFF on using ICICI Bank Debit Card. Use promo code -
                                            YRICICIDC</p>
                                    </div>*/}


                                 {/* one way result Web view starts*/}
                          <div className="flight-route-display oneway-desk">
                            <div className="row pt-3">
                              {/* <small className="vfm">Value for money</small> */}
                              <div className="airline-company col-lg-2 col-md-2 text-center">
                                <img
                                  src={`assets//img/logos/${item.airlineCode}.gif`}
                                  alt="."
                                />
                                <p className="small-text">
                                  {item.airlineName}
                                  <br />
                                  {item.flightDetails}
                                </p>
                              </div>
                              <div className="route-details-area col-lg-2 col-md-2 text-right">
                                <div className="row">
                                  <p className="small-text">{item.source}</p>
                                  <h6 className="time-text">{item.departureTime}</h6>
                                  <p className="time-text">{item.departureDate}</p>
                                  <p className="small-text text-grey">
                                    {/* {dateFormat(item.departureDate, "dd-mm-yyyy")} */}
                              
                                    {item.sourceAirport}
                                  </p>
                                  {item.depTerminal && item.depTerminal.length > 0 && (
                                  <p className="small-text text-grey"> Terminal:{item.depTerminal}</p>
                                  )}
                                </div>
                              </div>
                              <div className="travel-time col-lg-2 col-md-2 text-center">
                                <p className="small-text text-grey">
                                  {item.duration}
                                </p>
                                <span>
                                  <img src="assets/img/arrow.png" alt="" />
                                </span>
                                <p className="small-text text-grey">
                                  {item.noofStop === 0 ? "Non-Stop" : item.noofStop + " Stop"}
                                </p>
                              </div>
                              <div className="route-details-area col-lg-2 col-md-2 text-left">
                                <div className="row">
                                  <p className="small-text">{item.destination}</p>
                                  <h6 className="time-text">{item.arrivalTime}</h6>
                                  <p className="time-text">{item.arrivalDate}</p>
                                  <p className="small-text text-grey">
                                    {/* {dateFormat(item.arrivalDate, "dd-mm-yyyy")} */}
                           
                                    {item.destinationAirport}
                                  </p>
                                  {item.arrTerminal && item.arrTerminal.length > 0 && (
                                  <p className="small-text text-grey">Terminal:{item.arrTerminal}</p>
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-3 airline-price-area me-auto pt-2">
                                <div className="d-flex align-items-center justify-content-center">
                                  <div className="flex-row">
                                    <span className="mr-10">
                                      {/* {this.state.selectedAmount !== 0 ? (
                                <strike>{this.state.selectedAmount}</strike>
                                ) : ( */}
                                      {/*{item.discount !== 0 ? (
                                        <strike>₹{Math.round(item.amount)}</strike>
                                      ) : (
                                        <div></div>
                                      )
                                      }*/}
                                    </span>
                                  </div>
                                  <div className="flex-row">
                                  {item.noofSeat !== 0 && (
                                   <p className="no-margin small-text"><img src="assets/img/seat.png" width={13} className="mr-5" />{item.noofSeat} Seats</p>
                                  )}

                                    {/* {this.state.selectedNetAmount ? (
                              <h4 className="price-text">₹{this.state.selectedNetAmount}</h4>
                               ) : ( */}
                                    <h4 className="price-text">₹{Math.round(item.netAmount)}</h4>
                                    {/* )} */}

                                    {item.pricingList && (
                                      <div
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Click here for more fares"
                                      >
                                        {item.pricingList.length > 1 ? (
                                          <a
                                            href=""
                                            data-bs-toggle="collapse"
                                            data-bs-target={'#panel-collapse' + `${index}`}
                                            aria-expanded="true"
                                            aria-controls="panel-collapse"
                                            className="more-fare-btn"
                                          >
                                            More fares
                                          </a>
                                        ) : <div></div>}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* {this.state.selectedFareId ? (
                                <div className="col-lg-2 col-md-3 airline-price-area me-auto">
                                  <a
                                    type="button"
                                    className="btn btn-primary book-btn"
                                    onClick={() =>
                                      this.BookingRequest(item.itinId, this.state.selectedFareId, item.providerCode)
                                    }
                                  >
                                    BOOK
                                  </a>
                                </div>
                              ) : ( */}
                                <div className="col-lg-2 col-md-3 airline-price-area me-auto pt-4">
                                  <a type="button" className="btn btn-primary book-btn" onClick={() => this.BookingRequest(item.itinId, item.fareId, item.providerCode)}>
                                    BOOK
                                  </a>
                                  {/* <a
  type="button"
  className="btn btn-primary book-btn"
  onClick={() =>
    this.props.navigate(
      `/checkout?itinId=${item.itinId}&fareId=${item.fareId}&providerCode=${item.providerCode}`
    )
  }
>
  BOOK
</a> */}
                                </div>
                              {/* )} */}

                            </div>
                            <div
                              id={'panel-collapse' + `${index}`}
                              className="accordion-collapse collapse"
                              aria-labelledby="panel-heading"
                            >
                             
                              <div className="accordion-body relative-position pt-3">
                              <button
                                            href=""
                                            data-bs-toggle="collapse"
                                            data-bs-target={'#panel-collapse' + `${index}`}
                                            aria-expanded="true"
                                            aria-controls="panel-collapse"
                                            className="btn btn-close collapse-close-more"
                                          >
                                           
                                          </button>
                                <div className="row">
                                  {item.pricingList.map((item1, index1) => (
                                    <div className="col-lg-3 col-md-3" key={index1} >
                                      <div className="card">
                                        <label
                                          className="fare-selection"
                                        //for={`single1`+`${index1}`}
                                        //onChange={()=>{let data=this.state.ItinSearchResult; data[index].netAmount=item1.netAmount; data[index].amount=item1.netAmount+item1.discount; data[index].fareId=item1.fareId; this.setState({ItinSearchResult:data})} }


                                        >
                                          <input
                                            name={`plan` + `${index}`}
                                            className="single-fare"
                                            id={`single1` + `${index1}`}
                                            type="radio"
                                            defaultChecked={index1==0}
                                            // checked={0 == index1}
                                            //this.setState({itinSearchResult})
                                            //onChange={() => this.setState({ selectedFareId: item1.fareId,selectedNetAmount: item1.netAmount,selectedAmount: item1.netAmount+item1.discount })}

                                            onChange={() => { let data = this.state.ItinSearchResult; data[index].freeBaggage = item1.checkinBaggage;  data[index].netAmount = item1.netAmount; data[index].amount = item1.netAmount + item1.discount; data[index].fareId = item1.fareId; if(this.state.priceRangeMax < data[index].netAmount){let max=this.state.OnewayFullSearchResult; max.maximumFare=data[index].netAmount;this.setState({priceRangeMax:data[index].netAmount, OnewayFullSearchResult:max})}; this.setState({ ItinSearchResult: data }) }}
                                          />
                                          <div className="card-header text-center">
                                     

                                            <div style={{width: 200}}>{item1.fareName}</div>
                                          </div>
                                          <div className="card-body">
                                            <h4 className="no-margin text-center">
                                              {Math.round(item1.netAmount)}
                                            </h4>
                                            <table className="table">
                                              <tbody>
                                                <tr>
                                                  <td className="small-text">
                                                  <svg className="more-fare-bag" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="briefcase" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z"></path></svg>
                                                    Hand Bag
                                                  </td>
                                                  <td className="small-text">
                                                    {item1.cabinBaggage}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="small-text">
                                                  <svg className="more-fare-bag" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cart-flatbed-suitcase" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M0 32C0 14.3 14.3 0 32 0H48c44.2 0 80 35.8 80 80V368c0 8.8 7.2 16 16 16H608c17.7 0 32 14.3 32 32s-14.3 32-32 32H541.3c1.8 5 2.7 10.4 2.7 16c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-5.6 1-11 2.7-16H253.3c1.8 5 2.7 10.4 2.7 16c0 26.5-21.5 48-48 48s-48-21.5-48-48c0-5.6 1-11 2.7-16H144c-44.2 0-80-35.8-80-80V80c0-8.8-7.2-16-16-16H32C14.3 64 0 49.7 0 32zM432 96V56c0-4.4-3.6-8-8-8H344c-4.4 0-8 3.6-8 8V96h96zM288 96V56c0-30.9 25.1-56 56-56h80c30.9 0 56 25.1 56 56V96 320H288V96zM512 320V96h16c26.5 0 48 21.5 48 48V272c0 26.5-21.5 48-48 48H512zM240 96h16V320H240c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z"></path></svg>
                                                    Check-in
                                                  </td>
                                                  <td className="small-text">
                                                    {item1.checkinBaggage}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                 
                                </div>

                              </div>

                            </div>


                            <div className="flight-details-area d-flex flex-row-reverse">
                              <div className="flex-row">
                              {item.freeBaggage !== "" && (
                                <p className="bag-p">
                                  {" "}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-briefcase"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                                  </svg>{" "}
                                  {item.freeBaggage}
                                </p>
                              )}
                                <p>
                                  <a
                                   
                                   
                                    onClick={() => this.getFlightDetailsResult(item.itinId, item.fareId, item.providerCode)}
                                  >
                                    Flight Details{" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={16}
                                      height={16}
                                      fill="currentColor"
                                      className="bi bi-caret-down-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                           {/* one way result Web view ends*/}

                    {/* one way result Mobile view starts*/}                           
                   {/* { this.isMobile &&  */}
                   <div className="flight-route-display oneway-mob">
                      <ul className="d-flex flex-row">
                        <li> <img
                                  src={`assets//img/logos/${item.airlineCode}.gif`}
                                  alt="." className="air-logo-mob"
                                /></li>
                                <li>{item.airlineName}</li>
                                <li> {item.flightDetails}</li>
                               
                      </ul>
                      
                            <div className="row">
                              {/* <small className="vfm">Value for money</small> */}
                              <div className="route-details-area col-sm-4 col-4">
<div className="row">
  <p className="small-text">{item.source}</p>
  <h6 className="time-text">{item.departureTime}</h6>
  <p className="time-text">{item.departureDate}</p>
  <p className="small-text text-grey">
    {/* {dateFormat(item.departureDate, "dd-mm-yyyy")} */}

    {this.state.OnewayFullSearchResult.origin}
  </p>
  
</div>
</div>
                             
<div className="travel-time col-sm-4 col-4">
<p className="small-text text-grey">
  {item.duration}
</p>
<span>
  <img src="assets/img/arrow.png" alt="" width={70} />
</span>
<p className="small-text text-grey">
  {item.noofStop === 0 ? "Non-Stop" : item.noofStop + " Stop"}
</p>
</div>  

<div className="route-details-area col-sm-4 col-4 text-left">
<div className="row">
  <p className="small-text">{item.destination}</p>
  <h6 className="time-text">{item.arrivalTime}</h6>
  <p className="time-text">{item.arrivalDate}</p>
  <p className="small-text text-grey">
    {/* {dateFormat(item.arrivalDate, "dd-mm-yyyy")} */}

    {this.state.OnewayFullSearchResult.destination}
  </p>
  
</div>
</div>

<div className="col-lg-2 col-md-3 airline-price-area me-auto pt-2">
                                <div className="d-flex align-items-center justify-content-center">
                                  <div className="flex-row">
                                    <span className="mr-10 small-text">
                                      {/* {this.state.selectedAmount !== 0 ? (
                                <strike>{this.state.selectedAmount}</strike>
                                ) : ( */}
                                      {/* {item.discount !== 0 ? (
                                        <strike>{item.amount}</strike>
                                      ) : (
                                        <div></div>
                                      )
                                      } */}
                                    </span>
                                  </div>
                                  <div className="flex-row">
                                    <p className="no-margin small-text"></p>
                                    {/* {this.state.selectedNetAmount ? (
                              <h4 className="price-text">₹{this.state.selectedNetAmount}</h4>
                               ) : ( */}
                                    <h4 className="price-text">₹{Math.round(item.netAmount)}</h4>
                                    {/* )} */}

                                    {item.pricingList && (
                                      <div
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Click here for more fares"
                                      >
                                        {item.pricingList.length > 1 ? (
                                          <a
                                            href=""
                                            data-bs-toggle="collapse"
                                            data-bs-target={'#panel-collapse' + `${index}`}
                                            aria-expanded="true"
                                            aria-controls="panel-collapse"
                                            className="more-fare-btn"
                                          >
                                            More fares
                                          </a>
                                        ) : <div></div>}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                             
                              
                            
                                <div className="col-lg-2 col-md-3 airline-price-area">
                                  <a type="button" className="btn btn-primary book-btn" onClick={() => this.BookingRequest(item.itinId, item.fareId, item.providerCode)}>
                                    BOOK
                                  </a>
                               
                                </div>
                              {/* )} */}

                            </div>
                            <div
                              id={'panel-collapse' + `${index}`}
                              className="accordion-collapse collapse"
                              aria-labelledby="panel-heading"
                            >
                             
                              <div className="accordion-body relative-position pt-3">
                              <button
                                            href=""
                                            data-bs-toggle="collapse"
                                            data-bs-target={'#panel-collapse' + `${index}`}
                                            aria-expanded="true"
                                            aria-controls="panel-collapse"
                                            className="btn btn-close collapse-close-more"
                                          >
                                           
                                          </button>
                                <div className="row">
                                  {item.pricingList.map((item1, index1) => (
                                    <div className="col-lg-3 col-md-3" key={index1} >
                                      <div className="card">
                                        <label
                                          className="fare-selection"
                                        //for={`single1`+`${index1}`}
                                        //onChange={()=>{let data=this.state.ItinSearchResult; data[index].netAmount=item1.netAmount; data[index].amount=item1.netAmount+item1.discount; data[index].fareId=item1.fareId; this.setState({ItinSearchResult:data})} }


                                        >
                                          <input
                                            name={`plan` + `${index}`}
                                            className="single-fare"
                                            id={`single1` + `${index1}`}
                                            type="radio"
                                            defaultChecked={index1==0}
                                            // checked={0 == index1}
                                            //this.setState({itinSearchResult})
                                            //onChange={() => this.setState({ selectedFareId: item1.fareId,selectedNetAmount: item1.netAmount,selectedAmount: item1.netAmount+item1.discount })}

                                            onChange={() => { let data = this.state.ItinSearchResult;  data[index].netAmount = item1.netAmount; data[index].amount = item1.netAmount + item1.discount; data[index].fareId = item1.fareId; if(this.state.priceRangeMax < data[index].netAmount){let max=this.state.OnewayFullSearchResult; max.maximumFare=data[index].netAmount;this.setState({priceRangeMax:data[index].netAmount, OnewayFullSearchResult:max})}; this.setState({ ItinSearchResult: data }) }}
                                          />
                                          <div className="card-header text-center">
                                     

                                            <div style={{width: 200}}>{item1.fareName}</div>
                                          </div>
                                          <div className="card-body">
                                            <h4 className="no-margin text-center">
                                              {item1.netAmount}
                                            </h4>
                                            <table className="table">
                                              <tbody>
                                                <tr>
                                                  <td className="small-text">
                                                    {/* <img
                                                      src="assets/img/hand.png"
                                                      width={30}
                                                    /> */}
                                                    Hand Bag
                                                  </td>
                                                  <td className="small-text">
                                                    {item1.cabinBaggage}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="small-text">
                                                    {/* <img
                                                      src="assets/img/check-in.png"
                                                      width={30}
                                                    /> */}
                                                    Check-in Bag
                                                  </td>
                                                  <td className="small-text">
                                                    {item1.checkinBaggage}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                 
                                </div>

                              </div>

                            </div>


                            <div className="flight-details-area d-flex flex-row-reverse">
                              <div className="flex-row">
                              <p><img src="assets/img/seat.png" width={13} className="mr-5" />{item.noofSeat} Seats</p>
                              {item.freeBaggage !== "" && (
                                <p>
                                  {" "}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-briefcase"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                                  </svg>{" "}
                                  {item.freeBaggage}
                                </p>
                              )}
                                <p>
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#flightdetails"
                                    onClick={() => this.getFlightDetailsResult(item.itinId, item.fareId, item.providerCode)}
                                  >
                                    Flight Details{" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={16}
                                      height={16}
                                      fill="currentColor"
                                      className="bi bi-caret-down-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                    </svg>
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>   
                          {/* } */}
                        



                    {/* one way result Mobile view ends*/}




                        </div>




                      }





                      {/* No AIrline Selected...
                      
                         //////////////////////////////////////////////////////////////////////////////////////////////////// */}





                    </div>
                  }


                  )}
                  {/*One way result ends*/}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>

      

        {/* Flight details popup */}
        <Modal
          show={this.state.show}
          onHide={this.handleFlightDetailsClose}
          
          keyboard={false} id="flightdetails"
        >
          <Modal.Header closeButton>
            <Modal.Title className="modal-title" id="flightdetailsLabel">FLIGHT DETAILS</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.state.objseglistResult.length>0 &&
          <div className="modal-body">



          {/* {this.state.objseglistResult && this.state.objseglistResult.length > 0 ? (  */}
          <div className="tab-content" id="ex1-content">
            <div className="row">
              <div className="col-lg-8 col-md-8">
                <div className="popup-route-details">
                  <h4>{this.state.objitinResult.originCity} - {this.state.objitinResult.destinationCity} <span>{this.state.objitinResult.departureDate}</span> <span>{this.state.objitinResult.adultCheckinBaggage}</span><span>{this.state.objitinResult.adultCabinBaggage}</span></h4>
                </div>
                {this.state.objseglistResult.map((item, index) => (

                  <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1" key={index} >
                    <h6><img src={`assets//img/logos/${item.airlineCode}.gif`} className="tab-air" width={30} alt="" />{item.airlineName} {item.airlineCode} {item.flightNumber} 
                      <span><svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-dot" viewBox="0 0 16 16">
                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                      </svg></span>{item.airlineFlightClass}</h6>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-4 dep">
                        <h4>{item.departureAirportCode} {item.departureTime}</h4>
                        <p className="small-text">{item.departureDate}</p>
                        <p className="small-text">{item.departureAirport}</p>
                        {item.depTerminal && item.depTerminal.length > 0 && (
                        <p className="small-text">Terminal:{item.depTerminal}</p>
                        )}
                        <p className="small-text">{item.departureCity}</p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-4 text-center align-content-center align-items-center d-flex justify-content-center flex-column">
                        <div className="flex-column"><svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-clock text-grey" viewBox="0 0 16 16">
                          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                        </svg></div>
                        <div className="flex-column">
                          <p className="small-text text-grey">{item.travelDuration}</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-4 dep">
                        <h4>{item.arrivalAirportCode} {item.arrivalTime}</h4>
                        <p className="small-text">{item.arrivalDate}</p>
                        <p className="small-text">{item.arrivalAirport}</p>
                        {item.arrTerminal && item.arrTerminal.length > 0 && (
                        <p className="small-text">Terminal:{item.arrTerminal}</p>
                        )}
                        <p className="small-text">{item.arrivalCity}</p>
                      </div>

                    </div>
                    {index !== this.state.objseglistResult.length -1 ? (
                      <div className="layover">
                        Layover of {item.layoverTime}
                      </div>
                    ) : <div></div>}


                  </div>

                ))}
              </div>
              <div className="col-lg-4 col-md-4 d-flex flex-column summary-area">
                <div className="fare-summery-area">
     
<nav>
<div className="nav nav-tabs" id="nav-tab" role="tablist">
<button className="nav-link active" id="nav-fare-tab" data-bs-toggle="tab" data-bs-target="#nav-fare" type="button" role="tab" aria-controls="nav-fare" aria-selected="true">Fare Summary</button>
<button className="nav-link" id="nav-bag-tab" data-bs-toggle="tab" data-bs-target="#nav-bag" type="button" role="tab" aria-controls="nav-bag" aria-selected="false">Baggage</button>

</div>
</nav>
<div className="tab-content" id="nav-tabContent">
<div className="tab-pane fade show active" id="nav-fare" role="tabpanel" aria-labelledby="nav-fare-tab">
<table className="f-smry-table border-btm">
                    <tr>
                      <th>Type</th>
                      <th>Base fare</th>
                      <th>Fees & Tax</th>
                    </tr>
                    {this.state.FlightDetailsResult.adult > 0 && (
                    <tr>
                      <td>Adult x {this.state.FlightDetailsResult.adult}</td>
                      <td>{Math.round(this.state.FlightDetailsResult.adtBasic)}</td>    
                      <td>{Math.round(this.state.FlightDetailsResult.adtTax)}</td>    
                    </tr>
                    )}
                    {this.state.FlightDetailsResult.child > 0 && (
                    <tr>
                      <td>Child x {this.state.FlightDetailsResult.child}</td>
                      <td>{Math.round(this.state.FlightDetailsResult.chdBasic)}</td>    
                      <td>{Math.round(this.state.FlightDetailsResult.chdTax)}</td>    
                    </tr>
                    )}
                    {this.state.FlightDetailsResult.infant > 0 && (
                    <tr>
                      <td>Infant x {this.state.FlightDetailsResult.infant}</td>
                      <td>{Math.round(this.state.FlightDetailsResult.infBasic)}</td>    
                      <td>{Math.round(this.state.FlightDetailsResult.infTax)}</td>    
                    </tr>
                    )}
                     <tr>
                      <td>Convenience Fee</td>
                      <td></td>    
                      <td>{Math.round(this.state.FlightDetailsResult.convenienceFee)}</td>    
                    </tr>
                  </table>
                  <table className="f-smry-table">
                    <tr>
                      <td><h4>Total :</h4></td>

                      <td align="right"><h4>{Math.round(this.state.FlightDetailsResult.totalFare)}</h4></td>    
                    </tr>
                  </table>
                  <p className="no-margin text-white small-text">*All fares & seats are subject to availability at the time of booking</p>
</div>
<div className="tab-pane fade" id="nav-bag" role="tabpanel" aria-labelledby="nav-bag-tab">
<table className="f-smry-table">
<tr>
  <th>Type</th>
  <th>Check-in</th>
  <th>Cabin</th>
  </tr>
  {this.state.FlightDetailsResult.adult > 0 && (
  <tr>
    <td>Adult</td>
    <td>{this.state.objitinResult.adultCheckinBaggage}</td>
    <td>{this.state.objitinResult.adultCabinBaggage}</td>
    </tr>
     )}
     {this.state.FlightDetailsResult.child > 0 && (
    <tr>
    <td>Child</td>
    <td>{this.state.objitinResult.childCheckinBaggage}</td>
    <td>{this.state.objitinResult.childCabinBaggage}</td>
    </tr>
       )}
        {this.state.FlightDetailsResult.infant > 0 && (
     <tr>
    <td>Infant</td>
    <td>{this.state.objitinResult.infantCheckinBaggage}</td>
    <td>{this.state.objitinResult.infantCabinBaggage}</td>
    </tr>
     )}
    </table>
    <p className="text-white no-margin small-text">*The information is just for reference. Please check with airline before check-in</p>
</div>

</div>


                  <div className="popup-book-btn-area">
                   
                    <button type="button" className="btn btn-red button" onClick={() => this.BookingRequest(this.state.RequestFields.itinId, this.state.RequestFields.fareId, this.state.RequestFields.providerCode)} data-bs-dismiss="modal" aria-label="Close">BOOK</button>
                  </div>

                </div>



              </div>
            </div>
          </div>

          <div>
            {/* Flight change at Dubai, layover of {item.layoverTime} */}
          </div>

        </div>
  }
          </Modal.Body>
        
        </Modal>

       


      </div>
    ) : (!this.state.isLoaded && this.state.isResultFound) ? (
      <div>
         <div className="bg-white p-3">
       <div className="pt-6 align-items-center justify-content-center">
  <div className="text-center pt-5">
    <img src="assets/img/no-flight.png" className="no-flight" width={100} />
    <h4>Oops! No flights found</h4>
    <p>Sorry, There were no flights found for this route and date combination.<br/>We suggest you modify your search and try again.</p>
    <Link to="/"><button className="btn btn-primary mb-5">Back to search</button></Link>
  </div>
</div>
</div>

      </div>
    ) : (
      <div>
         <div className="flight-loader-body">
        <div className="flight-component" style={{ display: 'flex', alignItems: 'center' }}>
        
        <input 
          type="range" 
          className="flight" 
          value={this.state.value} 
          min="0" 
          max={this.state.max} 
          readOnly 
          style={{ width: '200px', margin: '0 10px' }} 
        />
      
      </div>
</div>
<div className="container">
<div className="row">
<div className="col-lg-3 col-md-3">
                {/* Sidebar desktop starts */}
                <div className="side-bar side-bar-desk">
                  <div className="bg-white" id="sidebar-wrapper">
                    {/*Airline Price area starts*/}
                    <div className="airline-area">
                      <div className="d-flex flex-row reset-area">
                       <h4>Filters</h4>
                       <span className="reset-filter" onClick={this.resetFilter}>Reset filters</span>
                      </div>
                      <div className="side-bar-title">
                        <h4>Airlines</h4>
                        <div className="deal-wrapper">
  <div className="mb-3 flight-route-display1">
  <div className="skeleton">
  <div className="skeleton__section skeleton__section--table">
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
  </div>
</div> 
  </div>
    </div>
                      </div>
                     
                    </div>
                    {/*Airline Price area ends*/}
                    {/*Stops area starts*/}
                    <div className="stops-area">
                      <div className="side-bar-title">
                        <h4>Stops</h4>
                      </div>
                      <div className="deal-wrapper">
  <div className="mb-3 flight-route-display1">
  <div className="skeleton">
  <div className="skeleton__section skeleton__section--table">
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
  </div>
</div> 
  </div>
    </div>
                    </div>
                    {/*Stops area ends */}
                    {/*Departure time area starts */}
                    <div className="departure-area">
                      <div className="side-bar-title">
                        <h4>Departure Time</h4>
                      </div>
                      <div className="deal-wrapper">
  <div className="mb-3 flight-route-display1">
  <div className="skeleton">
  <div className="skeleton__section skeleton__section--table">
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
  </div>
</div> 
  </div>
    </div>
                    </div>
                    {/*Departure time area ends */}
                    {/*Arrival time area starts */}
                    <div className="arrival-area">
                      <div className="side-bar-title">
                        <h4>Arrival Time</h4>
                      </div>
                      <div className="deal-wrapper">
  <div className="mb-3 flight-route-display1">
  <div className="skeleton">
  <div className="skeleton__section skeleton__section--table">
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
  </div>
</div> 
  </div>
    </div>
                    </div>
                    {/*Arrival time area ends */}
                  
                    {/*Price range slider */}
                    <div className="price-slider-area">
                      <div className="side-bar-title">
                        <h4>One Way Price</h4>
                      </div>
                    
                      <div className="deal-wrapper">
  <div className="mb-3 flight-route-display1">
  <div className="skeleton">
  <div className="skeleton__section skeleton__section--table">
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
  </div>
</div> 
  </div>
    </div>
                    </div>
                  
                  </div>
                </div>
                {/* Sidebar desktop ends */}
           

              </div>
  <div className="col-lg-9 col-md-9">
    <div className="deal-wrapper">
  <div className="mb-3 flight-route-display1">
  <div className="skeleton">
  <div className="skeleton__section skeleton__section--table">
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
  </div>
</div> 
  </div>
    </div>
    <div className="deal-wrapper">
    <div className="deal-wrapper">
    <div className="deal-wrapper">
    <div className="deal-wrapper">
    <div className="deal-wrapper">
    <div className="deal-wrapper">
    <div className="deal-wrapper">
  <div className="mb-3 flight-route-display1">
  <div className="skeleton">
  <div className="skeleton__section skeleton__section--table">
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
  </div>
</div> 
  </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div className="deal-wrapper">
  <div className="mb-3 flight-route-display1">
  <div className="skeleton">
  <div className="skeleton__section skeleton__section--table">
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
  </div>
</div> 
  </div>
    </div>
 <div className="deal-wrapper">
  <div className="mb-3 flight-route-display1">
  <div className="skeleton">
  <div className="skeleton__section skeleton__section--table">
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
    <div className="skeleton__tr">
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
      <div className="skeleton__td" />
    </div>
  </div>
</div> 
  </div>
    </div>
  </div>
</div>
</div>
  </div>

     
    )
  }
}



function WithNavigate(props) {
  let navigate = useNavigate();
  return <SearchResultOnewayTrip {...props} navigate={navigate} />
}

export default WithNavigate

