import React, { Component } from 'react'
import { useNavigate, Link, json } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Modal, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { GetForexPageload, SubmitForexEnquiry } from "../../Services/ForexServices";
import { ToastContainer, toast } from 'react-toastify';
import { validate } from 'uuid';

class Forex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CityList: [],
      CurrencyList: [],
      buyPrice: 0,
      sellPrice: 0,
      requiredQuantity: 0,
      totalBuyINRAmount: 0,
      totalSellINRAmount: 0,
      selectedCurrencyId: 0,
      selectedOrderType: "",
      selectedCityId: 0,
      activeKey: 'buy',
      showCardModal: false,
      showEnqdModal: false,

      customerName: "",
      customerMobile: "",
      customerEmail: "",
      isSubmitted: false,
      isCustomerEmail: false,
      isCustomerMobile: false,
      isFormValid: false,
      isPriceFormSubmitted: false,
      IsValidCity : false,
      IsValidCurrency : false,
      IsValidQuantity : false,

    };
  }

  componentDidMount() {
    this.getForexDetails();
  }

  getForexDetails() {
    GetForexPageload().then((response) => {
      this.setState({
        CityList: response.data.cityList,
        CurrencyList: response.data.currencyList,
      });
    });
  }

  setCurrency(CurrencyId) {
    const selectedCurrency = this.state.CurrencyList.find(item => item.id == CurrencyId);
    const { requiredQuantity } = this.state;
    this.setState({
      buyPrice: (CurrencyId == 0) ? 0 : selectedCurrency.buyPrice,
      sellPrice: (CurrencyId == 0) ? 0 : selectedCurrency.sellPrice,
      selectedCurrencyId: (CurrencyId == 0) ? 0 : selectedCurrency.id,
      totalBuyINRAmount: (requiredQuantity != 0 && CurrencyId != 0) ? (requiredQuantity * selectedCurrency.buyPrice) : 0,
      totalSellINRAmount: (requiredQuantity != 0 && CurrencyId != 0) ? (requiredQuantity * selectedCurrency.sellPrice) : 0,
      IsValidCurrency : (CurrencyId == 0) ? false :true,
    });
  }

  setCity(CityId) {
    this.setState({
      selectedCityId: CityId,
      IsValidCity : (CityId == 0) ? false :true,
    });
  }


  handleAmountChange = (event, type) => {
    let buyTotal = this.state.buyPrice * Number(event.target.value);
    let sellTotal = this.state.sellPrice * Number(event.target.value);
    this.setState({
      requiredQuantity: Number(event.target.value),
      totalBuyINRAmount: buyTotal,
      totalSellINRAmount: sellTotal,
      IsValidQuantity : (Number(event.target.value) == 0) ? false :true,
    });
  }

  handleSelect = (key) => {
    this.setState({ activeKey: key });
  };
  handleCardShow = () => {
    this.setState({ showCardModal: true });
  };

  handleCardClose = () => {
    this.setState({ showCardModal: false });
  };

  handleEnquiryFieldsChange = (event) => {
    const { name, value } = event.target;
    this.validateField(name, value);
    this.setState({
      [name]: value,
    });
  }


  SelectOrderType = (type) => {

    this.setState({
      isPriceFormSubmitted : true,
    });    

    const { IsValidCity, IsValidCurrency, IsValidQuantity } = this.state;

    if (IsValidCity && IsValidCurrency && IsValidQuantity) {
      this.setState({
        selectedOrderType: type,
        showEnqdModal: true
      });
    }
  };

  handleEnqClose = () => {
    this.setState({ showEnqdModal: false });
  };

  submitEnquiry = () => {
    this.setState({
      isSubmitted: true,
    });
    const { customerName, customerMobile, customerEmail } = this.state;

    if (customerName == '' || this.validateField("customerEmail", customerEmail) != '' || this.validateField("customerMobile", customerMobile) != '')
      return;


    let data = {};
    data.customerName = this.state.customerName;
    data.customerMobile = this.state.customerMobile;
    data.customerEmail = this.state.customerEmail;
    data.Type = this.state.selectedOrderType;
    data.SelectedCityId = this.state.selectedCityId;
    data.RequiredCurrencyId = this.state.selectedCurrencyId;
    data.RequiredQuantity = this.state.requiredQuantity;
    data.TransactionAmount = this.state.selectedOrderType == "Buy" ? this.state.totalBuyINRAmount : this.state.totalSellINRAmount;

    SubmitForexEnquiry(data).then((response) => {
      if (response.data.status) {
        this.setState({
          isSubmitted: false,
          showEnqdModal: false,
          buyPrice: 0,
          sellPrice: 0,
          requiredQuantity: 0,
          totalBuyINRAmount: 0,
          totalSellINRAmount: 0,
          selectedCurrencyId: 0,

          customerName: "",
          customerMobile: "",
          customerEmail: "",
        });
        toast.success('Enquiry Submitted...', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    });
  }

  validateField(name, value) {
    let errors = "";
    console.log(name);
    console.log(value);
    switch (name) {
      case 'customerEmail':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value || value.trim() === '' || !emailRegex.test(value)) {
          this.setState({ isCustomerEmail: false });
          console.log("error");
          errors = 'errors';
        } else {
          this.setState({ isCustomerEmail: true });
          errors = '';
        }
        break;

      case 'customerMobile':
        const mobileRegex = /^[0-9]{10}$/;
        if (!value || value.trim() === '' || !mobileRegex.test(value)) {
          this.setState({ isCustomerMobile: false });
          console.log("error");
          errors = 'errors';
        } else {
          this.setState({ isCustomerMobile: true });
          errors = '';
        }
        break;

      default:
        break;
    }

    return errors;
  }

  render() {
    const { CityList, CurrencyList, buyPrice, totalBuyINRAmount, totalSellINRAmount, customerName, customerMobile, customerEmail, isFormValid,  IsValidCity, IsValidCurrency, IsValidQuantity,
      isPriceFormSubmitted, selectedCityId, selectedCurrencyId, sellPrice, requiredQuantity, selectedOrderType, isSubmitted, isCustomerEmail, isCustomerMobile } = this.state;

    return (
      <div>
        <section className="page-header-forex" style={{ backgroundImage: 'url("assets/img/forex-banner.jpg")' }}>
          <div className="forex-enquiry position-relative">
            <Container>
              <Row className="align-items-center justify-content-center">
                <Col lg={7} md={7}>
                  <div className="forex-tab">
                    <div className="parent_div"></div>
                    <div className="inner_form">
                      <Tabs
                        activeKey={this.state.activeKey}
                        onSelect={this.handleSelect}
                        transition={false}
                        id="forex-enq-tab"
                        className="mb-3"
                      >
                        <Tab eventKey="buy" title="Buy Forex">
                          <div className="forex-form">
                            <div className="position-relative forex-input">
                              <select className="form-control" value={selectedCityId} onChange={(e) => this.setCity(e.target.value)}>
                                <option value={0}>Select City</option>
                                {CityList.length > 0 && CityList.map((item, index) => (
                                  <option key={index} value={item.id}>{item.city}</option>
                                ))}
                              </select>
                              <FontAwesomeIcon icon={faLocationDot} className="forex-loc" />
                              { isPriceFormSubmitted && !IsValidCity &&<span className="forex_val">Location required</span>}
                            </div>

                            <Row>

                              <Col lg={12} md={12} className="forex-input2">
                              <div className="position-relative">
                                <label className="forex-label">Currency You want</label>
                                <select className="form-control" value={selectedCurrencyId} onChange={(e) => this.setCurrency(e.target.value)}>
                                  <option value={0}>Select Currency</option>
                                  {CurrencyList.length > 0 && CurrencyList.map((item, index) => (
                                    <option key={index} value={item.id}>{item.currencyName}</option>
                                  ))}
                                </select>
                                { isPriceFormSubmitted &&  !IsValidCurrency && <span className="forex_val">Currency required</span>}
                                </div>
                              </Col>


                            </Row>
                            <div className="position-relative pt-3 forex-input">
                              <input type="number" className="form-control" placeholder="Forex Amount" onFocus={(e) => e.target.select()}
                                    value={requiredQuantity}  onChange={(e) => { this.handleAmountChange(e, "Buy") }} />
                              {buyPrice != 0 && <span className="currency_rate">
                                <FontAwesomeIcon icon={faChartSimple} /> Rate = INR {buyPrice}
                              </span>}
                              <FontAwesomeIcon icon={faMoneyBillWave} className="forex-amount" />
                              { isPriceFormSubmitted &&  !IsValidQuantity && <span className="forex_val">Quantity required</span>}
                            </div>

                            {/* <div className="position-relative pt-3 forex-input">
                              <FontAwesomeIcon icon={faMoneyBillWave} className="forex-amount" />
                              <input type="text" className="form-control" placeholder="INR Amount" value={totalBuyINRAmount} />

                            </div> */}
                            <div className="total-amount-block pt-2">
                              <h6>Total Amount</h6>
                              <div className="d-flex flex-row align-items-center">
                                <div className="total-amount-block mr-10">
                                  <FontAwesomeIcon icon={faWallet} className="t-cart" />
                                </div>
                                <div>
                                  <h4> {totalBuyINRAmount.toFixed(2)}</h4>
                                </div>

                              </div>
                            </div>
                            <div className="text-center forex-btn">
                              <button type="button" className="btn btn-doc" onClick={() => { this.SelectOrderType("Buy") }}>Buy Forex</button>
                            </div>
                          </div>
                        </Tab>




                        <Tab eventKey="sell" title="Sell Forex">
                          <div className="forex-form">
                            <div className="position-relative forex-input">
                              <select className="form-control" value={selectedCityId} onChange={(e) => this.setCity(e.target.value)}>
                                <option>Select City</option>
                                {CityList.length > 0 && CityList.map((item, index) => (
                                  <option key={index} value={item.id}>{item.city}</option>
                                ))}
                              </select>
                              <FontAwesomeIcon icon={faLocationDot} className="forex-loc" />
                              { isPriceFormSubmitted && !IsValidCity &&<span className="forex_val">Location required</span>}
                            </div>


                            <div className="forex-input2">
                            <div className="position-relative">
                              <label className="forex-label">Choose Currency</label>
                              <select className="form-control" value={selectedCurrencyId} onChange={(e) => this.setCurrency(e.target.value)}>
                                <option value={0}>Select Currency</option>
                                {CurrencyList.length > 0 && CurrencyList.map((item, index) => (
                                  <option key={index} value={item.id}>{item.currencyName}</option>
                                ))}
                              </select>
                              { isPriceFormSubmitted &&  !IsValidCurrency && <span className="forex_val">Currency required</span>}
                              </div>
                            </div>




                            <div className="position-relative pt-3 forex-input">
                              <input type="number" className="form-control" placeholder="Enter Quantity" onFocus={(e) => e.target.select()}
                                    value={requiredQuantity} onChange={(e) => { this.handleAmountChange(e, "Sell") }} />
                              {sellPrice != 0 &&<span className="currency_rate">
                                <FontAwesomeIcon icon={faChartSimple} /> Rate = INR {sellPrice}
                              </span>}
                              <FontAwesomeIcon icon={faMoneyBillWave} className="forex-amount" />
                              { isPriceFormSubmitted &&  !IsValidQuantity && <span className="forex_val">Quantity required</span>}
                            </div>


                            <div className="total-amount-block pt-2">
                              <h6>Total Amount(INR)</h6>
                              <div className="d-flex flex-row align-items-center">
                                <div className="total-amount-block mr-10">
                                  <FontAwesomeIcon icon={faWallet} className="t-cart" />
                                </div>
                                <div>
                                  <h4>{totalSellINRAmount.toFixed(2)}</h4>
                                </div>

                              </div>
                            </div>
                            <div className="text-center forex-btn">
                              <button type="button" className="btn btn-doc" onClick={() => { this.SelectOrderType("Sell") }}>Sell Forex</button>
                            </div>
                          </div>
                        </Tab>

                      </Tabs>

                      <Modal
                        show={this.state.showEnqdModal}
                        onHide={this.handleEnqClose}

                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="full-screen-modal">
                            {selectedOrderType} Forex
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="enquiry-form">
                            <div className="pt-2 pb-2">
                              <input type="text" className="form-control" placeholder="Full Name" name='customerName' value={customerName} onChange={this.handleEnquiryFieldsChange} />
                              {isSubmitted && customerName == "" && <span className="error">Enter a your Name</span>}
                            </div>
                            <div className="pt-2 pb-2">
                              <input type="text" className="form-control" placeholder="Mobile No." name='customerMobile' value={customerMobile} onChange={this.handleEnquiryFieldsChange} />
                              {isSubmitted && !isCustomerMobile && <span className="error">Enter a valid Mobile number</span>}
                            </div>
                            <div className="pt-2 pb-2">
                              <input type="text" className="form-control" placeholder="Email" name='customerEmail' value={customerEmail} onChange={this.handleEnquiryFieldsChange} />
                              {isSubmitted && !isCustomerEmail && <span className="error">Enter a valid EmailId</span>}
                            </div>
                            <div className="pt-2 pb-2">
                              <button className="btn btn-doc w-100" onClick={this.submitEnquiry}>Submit</button>
                              {isSubmitted && (customerName == "" || !isCustomerMobile || !isCustomerEmail) && <span className="error">Fill All Fields</span>}
                            </div>
                          </div>
                        </Modal.Body>

                      </Modal>
                    </div>

                  </div>

                </Col>

                <Col lg={5} md={5}>
                  <OwlCarousel
                    items={1}
                    className="owl-carousel"
                    loop
                    autoplay
                    margin={8}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      600: {
                        items: 1,
                      },
                      1000: {
                        items: 1,
                      },
                    }}
                  >
                    <div className="item forex-area">

                      <div className="text-center">
                        <h4 className="text-white">Buy & Sell Foreign Currency Notes at Best Rates with Alhind</h4>
                        <p>Guaranteed Best Rates in Market</p>

                      </div>

                    </div>
                    <div className="item forex-area text-center">

                      <h4 className="text-white">Genuine currency notes from verified RBI-licensed companies</h4>
                      <p>Guaranteed Best Rates in Market</p>
                    </div>
                    <div className="item forex-area text-center">

                      <h4 className="text-white">Best rates compared across hundreds of money changers</h4>
                      <p>Guaranteed Best Rates in Market</p>
                    </div>







                  </OwlCarousel>
                </Col>


              </Row>
            </Container>
          </div>
        </section>

        <section className="rate-card pt-5 pb-5">
          <Container>

            {CurrencyList.length > 0 && <OwlCarousel
              items={5}
              className="owl-carousel"

              autoplay
              margin={10}
              responsive={{
                0: {
                  items: 2,
                },
                600: {
                  items: 2,
                },
                1000: {
                  items: 5,
                },
              }}
            >
              {CurrencyList.map((item, index) => (
                <div
                  className={(index % 3 === 0) ? "card-basic" : (index % 3 === 1) ? "card-standard" : "card-premium"}
                  key={index}
                >
                  <div
                    className={"card-header " + ((index % 3 === 0) ? "header-basic" : (index % 3 === 1) ? "header-standard" : "header-premium")}
                  >
                    <h4>{item.currencyCode}<br />{item.currencyName}</h4>
                  </div>
                  <div className="card-body">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td className="buy-txt">Buy</td>
                          <td className="buy-txt">{item.buyPrice}</td>
                        </tr>
                        <tr>
                          <td className="buy-txt">Sell</td>
                          <td className="buy-txt">{item.sellPrice}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
              {/* <div className="card-standard">
                <div className="card-header header-standard">
                  <h4>AED<br />UAE Dirham</h4>
                </div>
                <div className="card-body">
                  <table className="table">
                    <tr>
                      <td className="buy-txt">Buy</td>
                      <td className="buy-txt">23.46</td>
                    </tr>
                    <tr>
                      <td className="buy-txt">Sell</td>
                      <td className="buy-txt">23.46</td>
                    </tr>

                  </table>

                </div>
              </div> */}
              {/* <div className="card-premium">
                <div className="card-header header-premium">
                  <h4>GBP<br />Sterling Pound</h4>
                </div>
                <div className="card-body">
                  <table className="table">
                    <tr>
                      <td className="buy-txt">Buy</td>
                      <td className="buy-txt">23.46</td>
                    </tr>
                    <tr>
                      <td className="buy-txt">Sell</td>
                      <td className="buy-txt">23.46</td>
                    </tr>

                  </table>
                </div>
              </div> */}



            </OwlCarousel>}

            <div className="text-center pt-2">
              <button className="btn btn-doc" onClick={this.handleCardShow}> <FontAwesomeIcon icon={faChartBar} /> View Full Rate Card</button>
            </div>

            <Modal
              show={this.state.showCardModal}
              onHide={this.handleCardClose}
              dialogClassName="modal-100w"
              aria-labelledby="full-screen-modal"
              fullscreen={true}
            >
              <Modal.Header closeButton>
                <Modal.Title id="full-screen-modal">
                  Forex Rate Card
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Currency</th>
                      <th>Buy Rate</th>
                      <th>Sell Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {CurrencyList.map((item, index) => (
                      <tr>
                        <td>{item.currencyName}</td>
                        <td>{item.buyPrice}</td>
                        <td>{item.sellPrice}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={this.handleCardClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>














            <main className="d-flex flex-row">

            </main>

          </Container>
        </section>

        <section className="forex-about pt-5 pb-5">
          <div className="container">
            <div className="section-title text-center">
              <h2>About alhind Forex</h2>
            </div>
            <p className="text-center">We are approved by the Reserve Bank of India to operate as a Full Fledged Money Changer.</p>
            <div className="success-story bg-white">
              <Row>
                <Col lg={3} md={3} className="text-center">
                  <div className="forex-about-icon">
                    <img src="assets/img/office.png" width={80} />
                  </div>
                  <h3>4500+</h3>
                  <p>Branches all over india</p>
                </Col>

                <Col lg={3} md={3} className="text-center">
                  <div className="forex-about-icon">
                    <img src="assets/img/customers.png" width={80} />
                  </div>
                  <h3>2500000+</h3>
                  <p>Satisfied Customers</p>
                </Col>
                <Col lg={3} md={3} className="text-center">
                  <div className="forex-about-icon">
                    <img src="assets/img/legacy.png" width={80} />
                  </div>
                  <h3>30+</h3>
                  <p>Years of Legacy</p>
                </Col>
                <Col lg={3} md={3} className="text-center">
                  <div className="forex-about-icon">
                    <img src="assets/img/money.png" width={80} />
                  </div>
                  <h3>100%</h3>
                  <p>Your Money is Safe</p>
                </Col>
              </Row>
            </div>
          </div>
        </section>







        {/* Coming soon section starts */}
        {/* <div className="container">
        <h2 class="mb-2 mx-2 cs-title mt-5 text-center">Launching soon!</h2>
        <p className="cs-text text-center">We are currently developing the system for a better experience.<br/>Apologize for the inconvenience and thank you.</p>
        <div className="row align-items-center justify-content-center">
        <div className="col-lg-4 col-md-4">
       
        <img src="assets/img/forex-bg.webp" className="img-fluid" />
        </div>
        <div className="col-lg-6 col-md-6">
        <div className="card cs-card">
          <div className="card-title">
          <h4>For Forex Enquiries Please contact us </h4>
          </div>
          <div className="card-body">
         
          <h4><img src="assets/img/support.svg" width={30}/> 0495 2389900</h4>
        <h4><img src="assets/img/email.svg" width={30}/> b2c@alhindonline.com</h4>
          </div>
        </div>
        </div>
        </div>

        </div> */}

        {/* Coming soon ends */}



      </div>
    )
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <Forex {...props} navigate={navigate} />;
}

export default WithNavigate;
