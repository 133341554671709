import AxiosConfig from '../Configuration/AxiosConfig'
import HeaderConfig from '../Configuration/HeaderConfig';
import { CheckInternetConnection, EmptyResponse } from '../Services/UtilitiesServices';

  export function TopVisaList(data)
  { 
      return AxiosConfig.post("Visa/GetTopVisaList",data, HeaderConfig());
  }

  export function getVisaDetails(data)
  { 
      return AxiosConfig.post("Visa/getVisaDetails", data, HeaderConfig());
  }

  export function PostEnquiry(data)
  { 
      return AxiosConfig.post("Visa/PostEnquiry",data, HeaderConfig());
  }